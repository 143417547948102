<template>
  <b-row>
    <div class="toggle-container">
      <toggle-button
        class="autoFillBtn"
        v-model="form.autoCompleted"
        :sync="true"
        :height="32"
        :disabled="readonly"
        :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
        @change="autoFill"
      />
      <span class="autoFillBtnLabel">Marcar campos em branco como “sem alterações”</span>
    </div>

    <b-col cols="12">
      <div class="form-group">
        <label>Vítreo</label>
        <b-row>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> D</p>
              <input
                autocomplete="off"
                v-model="form.fields.vitreoDireito"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
            <button
              class="blue-underline"
              @click="
                () => copyEyeValues('vitreoDireito', 'vitreoEsquerdo')
              "
              v-if="!readonly"
              @focus="setFormActive(form.id)"
            >
              Copiar valores O.D para O.E
            </button>
          </b-col>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> E</p>
              <input
                autocomplete="off"
                v-model="form.fields.vitreoEsquerdo"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>

    <b-col cols="12">
      <div class="form-group">
        <label>Disco óptico</label>
        <b-row>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> D</p>
              <input
                autocomplete="off"
                v-model="form.fields.discoOpticoDireito"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
            <button
              class="blue-underline"
              @click="
                () => copyEyeValues('discoOpticoDireito', 'discoOpticoEsquerdo')
              "
              v-if="!readonly"
              @focus="setFormActive(form.id)"
            >
              Copiar valores O.D para O.E
            </button>
          </b-col>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> E</p>
              <input
                autocomplete="off"
                v-model="form.fields.discoOpticoEsquerdo"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>

    <b-col cols="12">
      <div class="form-group">
        <label>Escavação</label>
        <b-row>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> D</p>
              <input
                autocomplete="off"
                v-model="form.fields.escavacaoDireito"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
            <button
              class="blue-underline"
              @click="
                () => copyEyeValues('escavacaoDireito', 'escavacaoEsquerdo')
              "
              v-if="!readonly"
              @focus="setFormActive(form.id)"
            >
              Copiar valores O.D para O.E
            </button>
          </b-col>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> E</p>
              <input
                autocomplete="off"
                v-model="form.fields.escavacaoEsquerdo"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>

    <b-col cols="12">
      <div class="form-group">
        <label>Mácula</label>
        <b-row>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> D</p>
              <input
                autocomplete="off"
                v-model="form.fields.maculaDireito"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
            <button
              class="blue-underline"
              @click="() => copyEyeValues('maculaDireito', 'maculaEsquerdo')"
              v-if="!readonly"
              @focus="setFormActive(form.id)"
            >
              Copiar valores O.D para O.E
            </button>
          </b-col>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> E</p>
              <input
                autocomplete="off"
                v-model="form.fields.maculaEsquerdo"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>

    <b-col cols="12">
      <div class="form-group">
        <label>Vasos</label>
        <b-row>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> D</p>
              <input
                autocomplete="off"
                v-model="form.fields.vasosDireito"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
            <button
              class="blue-underline"
              @click="() => copyEyeValues('vasosDireito', 'vasosEsquerdo')"
              v-if="!readonly"
              @focus="setFormActive(form.id)"
            >
              Copiar valores O.D para O.E
            </button>
          </b-col>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> E</p>
              <input
                autocomplete="off"
                v-model="form.fields.vasosEsquerdo"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>

    <b-col cols="12">
      <div class="form-group">
        <label>Retina</label>
        <b-row>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> D</p>
              <input
                autocomplete="off"
                v-model="form.fields.retinaDireito"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
            <button
              class="blue-underline"
              @click="() => copyEyeValues('retinaDireito', 'retinaEsquerdo')"
              v-if="!readonly"
              @focus="setFormActive(form.id)"
            >
              Copiar valores O.D para O.E
            </button>
          </b-col>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> E</p>
              <input
                autocomplete="off"
                v-model="form.fields.retinaEsquerdo"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>

    <div class="add-file">
      <label for="imageSelector-mapeamentoDeRetina" class="file-input"
        ><Paperclip /> anexar arquivo</label
      >
      <input
        type="file"
        id="imageSelector-mapeamentoDeRetina"
        ref="image"
        style="display: none"
        accept=".jpg, .png, .pdf"
        :disabled="readonly"
        @change="onFileChange($event, 'Mapeamento de Retina')"
      />
      <div class="file-list">
        <p
          v-if="
            filesToUpload != null &&
            filesToUpload.find(
              element => element.group === 'Mapeamento de Retina'
            )
          "
        >
          Anexos:
        </p>
        <ul v-for="(file, index) of filesToUpload" :key="index">
          <li v-if="file.group == 'Mapeamento de Retina'">
            <Pdf v-if="file.type == 'application/pdf'" class="icon" />
            <Files v-else class="icon" />
            <div class="attachment_file_name">{{ file.name }}</div>
            <CloseUploadedFile
              class="icon-close"
              @click="filesToUpload.splice(index, 1)"
            />
          </li>
        </ul>
      </div>
    </div>
  </b-row>
</template>

<script>
import Paperclip from '@/assets/icons/paperclip2.svg'
import Pdf from '@/assets/icons/pdf.svg'
import Files from '@/assets/icons/files.svg'
import CloseUploadedFile from '@/assets/icons/closeUploadedFile.svg'
import EyeFill from '@/assets/icons/eye-fill.svg'

export default {
  name: 'RetinaMapping',
  props: {
    initialForm: Object,
    readonly: Boolean,
    onBlurForm: Function,
    setFormActive: Function,
    onFileChange: Function,
    filesToUpload: Array,
    attendanceReview: Object
  },
  components: {
    Paperclip,
    Pdf,
    Files,
    CloseUploadedFile,
    EyeFill
  },
  data() {
    return {
      form: {
        fields: {},
        ...this.initialForm
      }
    }
  },
  methods: {
    autoFill() {
      const newValue = this.form.autoCompleted ? 'sem alterações' : null;
      for (const item in this.form.fields) {
        const currentValue = this.form.fields[item]
        if(currentValue === null || currentValue === 'sem alterações'
          || currentValue.trim() === '') {
          this.form.fields[item] = newValue
        }
      }

      this.$emit('saveForm', this.form)
    },
    copyEyeValues(rightEyeFieldName, leftEyeFieldName) {
      this.form.fields[leftEyeFieldName] = this.form.fields[rightEyeFieldName];
      this.onBlurForm(this.form);
    },
  onChange() {
      this.attendanceReview.mapeamentoRetina = false
      if(this.form !== null){
        Object.keys(this.form.fields).forEach(key => {
          if(this.form.fields[key] !== null && this.form.fields[key] !== '')
            this.attendanceReview.mapeamentoRetina = true
       })
      }
    },
  },
  mounted() {
    if (!this.readonly) {
      this.onChange()
    }
  },
  watch: {
    form: {
      handler(){
        this.onChange()
      },
      deep: true
    },
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.fields = newValue.fields
        }
      },
      deep: true
    }
  },
}
</script>

<style lang="scss" scoped>
.toggle-container {
  margin-bottom: 24px;
}
</style>
