<template>
  <b-row>
    <b-col>
      <div class="form-group mb-0">
        <input
          autocomplete="off"
          v-model="form.fields.retorno"
          :readonly="readonly"
          @focus="setFormActive(form.id)"
          @blur="!readonly && onBlurForm(form)"
          type="text"
          class="form-control"
          placeholder="Digite a data de retorno"
        />
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'Return',
  props: {
    initialForm: Object,
    readonly: Boolean,
    setFormActive: Function,
    onBlurForm: Function,
    attendanceReview: Object
  },
  data() {
    return {
      form: {
        fields: {},
        ...this.initialForm
      }
    }
  },
  mounted() {
    if (!this.readonly) {
      this.changeValue()
    }
  },
  methods: {
    changeValue() {
      if (typeof this.form.fields.retorno !== 'undefined') {
        this.attendanceReview.retorno =
          this.form.fields.retorno !== null && this.form.fields.retorno !== ''
      }
    }
  },
  watch: {
    'initialForm.fields.retorno': function() {
      this.changeValue()
    },
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.fields = newValue.fields
        }
      },
      deep: true
    }
  }
}
</script>
