<template>
    <div
      class="input-options"
      v-click-outside="closeOptions"
      :class="{
        'right-margin-1': activeInput === 'eixoDireito' || activeInput === 'eixoEsquerdo',
        'right-margin-2': activeInput === 'DPDireito' || activeInput === 'DPEsquerdo',
        'right-margin-3': activeInput === 'AdicaoDireito' || activeInput === 'AdicaoEsquerdo',
      }"
    >
      <table >
        <tr v-for="(row, rowIndex) in options" :key="rowIndex">
            <td v-for="(option, colIndex) in row" :key="colIndex" @click="setValue(option)"
            :class="{'bordered': (rowIndex != 0 || colIndex !=0) && (rowIndex != 0 || colIndex != option.length-1) && (rowIndex != options.length-1 || colIndex != 0) && (rowIndex != options.length-1 || colIndex != option.length-1)}">
                {{ option }}
            </td>
        </tr>
      </table>
    </div>
</template>

<script>
export default {
    props: {
        form: {
          fields: {}
        },
        options: Array,
        activeInput: String,
        setActiveInput: Function,
        inputName: String,
    },

    methods: {
      closeOptions(){
        this.setActiveInput(null)
      },

      setValue(value) {
        if(this.inputName){
          this.form.fields[this.inputName] = value
        }
        else if(this.form.name === 'AVCC - Acuidade visual com correção' || this.form.name === 'AVSC - Acuidade visual sem correção'
         || this.form.name.includes('AVPP') ) {
          this.form.fields[`olho${this.activeInput.includes('Direito') ? 'Direito' : 'Esquerdo'}`] = value;
        } else if(this.form.name === 'Refração dinâmica'){
          if(this.activeInput === 'esfericoDireito')
              this.form.fields.olhoDireitoEsfericoDin = value
          else if(this.activeInput === 'cilindricoDireito')
              this.form.fields.olhoDireitoCilindricoDin = value
          else if(this.activeInput === 'eixoDireito')
              this.form.fields.olhoDireitoEixoDin = value
          else if(this.activeInput === 'DPDireito')
              this.form.fields.olhoDireitoDPDin = value
          else if(this.activeInput === 'AVDireito')
              this.form.fields.olhoDireitoAVDin = value
          else if(this.activeInput === 'AdicaoDireito')
              this.form.fields.olhoDireitoAdicaoDin = value

          else if(this.activeInput === 'esfericoEsquerdo')
              this.form.fields.olhoEsquerdoEsfericoDin = value
          else if(this.activeInput === 'cilindricoEsquerdo')
              this.form.fields.olhoEsquerdoCilindricoDin = value
          else if(this.activeInput === 'eixoEsquerdo')
              this.form.fields.olhoEsquerdoEixoDin = value
          else if(this.activeInput === 'DPEsquerdo')
              this.form.fields.olhoEsquerdoDPDin = value
          else if(this.activeInput === 'AVEsquerdo')
              this.form.fields.olhoEsquerdoAVDin = value
          else if(this.activeInput === 'AdicaoEsquerdo')
              this.form.fields.olhoEsquerdoAdicaoDin = value

        } else if(this.form.name === 'Campo visual'){
          if(this.activeInput === 'mdDireito')
              this.form.fields.olhoDireitoMd = value
          else if(this.activeInput === 'psdDireito')
              this.form.fields.olhoDireitoPsd = value

          else if(this.activeInput === 'mdEsquerdo')
              this.form.fields.olhoEsquerdoMd = value
          else if(this.activeInput === 'psdEsquerdo')
              this.form.fields.olhoEsquerdoPsd = value 

        } else if(this.form.name === 'Refração estática'){
          if(this.activeInput === 'esfericoDireito')
              this.form.fields.olhoDireitoEsfericoEst = value
          else if(this.activeInput === 'cilindricoDireito')
              this.form.fields.olhoDireitoCilindricoEst = value
          else if(this.activeInput === 'eixoDireito')
              this.form.fields.olhoDireitoEixoEst = value
          else if(this.activeInput === 'DPDireito')
              this.form.fields.olhoDireitoDPEst = value
          else if(this.activeInput === 'AVDireito')
              this.form.fields.olhoDireitoAVEst = value
          else if(this.activeInput === 'AdicaoDireito')
              this.form.fields.olhoDireitoAdicaoEst = value

          else if(this.activeInput === 'esfericoEsquerdo')
              this.form.fields.olhoEsquerdoEsfericoEst = value
          else if(this.activeInput === 'cilindricoEsquerdo')
              this.form.fields.olhoEsquerdoCilindricoEst = value
          else if(this.activeInput === 'eixoEsquerdo')
              this.form.fields.olhoEsquerdoEixoEst = value
          else if(this.activeInput === 'DPEsquerdo')
              this.form.fields.olhoEsquerdoDPEst = value
          else if(this.activeInput === 'AVEsquerdo')
              this.form.fields.olhoEsquerdoAVEst = value
          else if(this.activeInput === 'AdicaoEsquerdo')
              this.form.fields.olhoEsquerdoAdicaoEst = value

        } else {
            if(this.activeInput === 'esfericoDireito')
                this.form.fields.olhoDireitoEsferico = value
            else if(this.activeInput === 'cilindricoDireito')
                this.form.fields.olhoDireitoCilindrico = value
            else if(this.activeInput === 'eixoDireito')
                this.form.fields.olhoDireitoEixo = value
            else if(this.activeInput === 'DPDireito')
                this.form.fields.olhoDireitoDP = value
            else if(this.activeInput === 'AdicaoDireito')
                this.form.fields.olhoDireitoAdicao = value

            else if(this.activeInput === 'esfericoEsquerdo')
                this.form.fields.olhoEsquerdoEsferico = value
            else if(this.activeInput === 'cilindricoEsquerdo')
                this.form.fields.olhoEsquerdoCilindrico = value
            else if(this.activeInput === 'eixoEsquerdo')
                this.form.fields.olhoEsquerdoEixo = value
            else if(this.activeInput === 'DPEsquerdo')
                this.form.fields.olhoEsquerdoDP = value
            else if(this.activeInput === 'AdicaoEsquerdo')
                this.form.fields.olhoEsquerdoAdicao = value
        }

        setTimeout(() => {
          this.setActiveInput(null)
        }, 200)
      },
    }

}
</script>

<style lang="scss" scoped>
.input-options {
    background-color: #F8F8FD;
    width: 500px;
    position: absolute !important;
    z-index: 150;
    border: 1.5px solid var(--blue-500) !important;
    border-radius: 8px !important;

    table {
        width: 100%;
        height: 100%;
        border-radius: 8px !important;
        td {
            text-align: center;
            padding: 5px;
            cursor: pointer;
            transition: .5s;

            &:hover {
                background-color: var(--blue-100);
                transition: .5s;
            }
        }

        .bordered {
            border: 1.5px solid var(--blue-100);
        }
    }


}
.right-margin-1 {
  margin-left: -8vw;
}

.right-margin-2 {
  margin-left: -15vw;
}

.right-margin-3 {
  margin-left: -25vw;
}

@media(max-width: 1400px){
  .right-margin-1 {
    margin-left: -13vw;
  }

  .right-margin-2 {
    margin-left: -17vw;
  }

  .right-margin-3 {
    margin-left: -27vw;
  }
}

@media(max-width: 1300px){
  .right-margin-1 {
    margin-left: -16vw;
  }

  .right-margin-2 {
    margin-left: -25vw;
  }

  .right-margin-3 {
    margin-left: -30vw;
  }
}
</style>
