<template>
  <b-row>
    <b-col cols="4">
      <div class="form-group mb-0">
        <label for="avsc-direito">Olho direito</label>
        <div class="input-eye">
          <p class="tag-eye"><EyeFill /> D</p>
          <input
            autocomplete="off"
            id="avsc-direito"
            v-model="form.fields.olhoDireito"
            :readonly="readonly" :disabled="readonly"
            @focus="setFormActive(form.id)"
            @blur="onBlurForm(form)"
            @click="setActiveForm('avscDireito')"
            type="text"
            class="form-control"
            placeholder="Resultado"
          />
        </div>
        <Options :form="form" :options="options" activeInput="avscDireito" :setActiveInput="this.setActiveForm" v-if="activeForm==='avscDireito' && !readonly"/>
      </div>
      <button
        class="blue-underline"
        @click="copyEyeValues"
        v-if="!readonly"
        @focus="setFormActive(form.id)"
      >
        Copiar valores O.D para O.E
      </button>
    </b-col>
    <b-col cols="4">
      <div class="form-group mb-0">
        <label for="avsc-esquerdo">Olho esquerdo</label>
        <div class="input-eye">
          <p class="tag-eye"><EyeFill /> E</p>
          <input
            autocomplete="off"
            id="avsc-esquerdo"
            v-model="form.fields.olhoEsquerdo"
            :readonly="readonly" :disabled="readonly"
            @focus="setFormActive(form.id)"
            @blur="onBlurForm(form)"
            @click="setActiveForm('avscEsquerdo')"
            type="text"
            class="form-control"
            placeholder="Resultado"
          />
        </div>
        <Options :form="form" :options="options" activeInput="avscEsquerdo" :setActiveInput="this.setActiveForm" v-if="activeForm==='avscEsquerdo' && !readonly"/>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import EyeFill from '@/assets/icons/eye-fill.svg'
import MultipleEyesOptions from './MultipleEyesOptions.vue'

export default {
  name: 'Avsc',
  props: {
    initialForm: Object,
    readonly: Boolean,
    setFormActive: Function,
    onBlurForm: Function,
  },
  components: {
    EyeFill,
    'Options': MultipleEyesOptions
  },
  data() {
    return {
      form: {
        fields: {},
        ...this.initialForm,
      },
      activeForm: String,
      options: [['20/10', '20/15', '20/20', '20/25', '20/30'], ['20/40', '20/50', '20/70', '20/100', '20/200'],['20/400', 'CD 3m', 'CD 2m', 'CD 1m', 'CD 0,5m'], ['MM', 'PL', 'SPL']]
    }
  },
  methods: {
    setActiveForm(FormName) {
      this.activeForm = FormName
    },
    copyEyeValues() {
      this.form.fields.olhoEsquerdo = this.form.fields.olhoDireito;
      this.onBlurForm(this.form);
    }
  },
  watch: {
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.fields = newValue.fields
        }
      },
      deep: true
    }
  }
}
</script>
