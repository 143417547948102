<template>
  <b-row>
    <b-col>
      <div class="form-group mb-0">
        <input
          autocomplete="off"
          v-model="form.fields.maisInformacoes"
          :readonly="readonly"
          @focus="setFormActive(form.id)"
          @blur="!readonly && onBlurForm(form)"
          type="text"
          class="form-control"
          placeholder="Adicione mais informações relevantes"
        />
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'MoreInformation',
  props: {
    initialForm: Object,
    readonly: Boolean,
    setFormActive: Function,
    onBlurForm: Function,
  },
  data() {
    return {
      form: {
        fields: {},
        ...this.initialForm
      }
    }
  },
  watch: {
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.fields = newValue.fields
        }
      },
      deep: true
    }
  }
}
</script>
