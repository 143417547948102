<template>
  <b-row>
    <b-col class="exam-request">
      <validation-observer
        :ref="this.initialForm.id + '-form'"
        v-slot="{ invalid }"
        v-if="!readonly"
      >
        <div class="form-group">
          <validation-provider
            name="cirurgia"
            rules="required"
            v-slot="{ touched, errors }"
          >
            <multiselect
              v-if="!form.fields.custom"
              v-model="form.fields.cirurgia"
              id="cirurgia"
              :options="cirurgiasOftalmologicas"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :allowEmpty="false"
              :showNoResults="true"
              @focus.native="setFormActive(form.id)"
              placeholder="Nome da cirurgia"
              :class="{ error: touched && errors.length > 0 && 1 === 2 }"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noResult" slot-scope="props">
                <li @click="setCustom(true, props.search)">
                  <div class="multiselect__option custom-item">
                    <Plus class="icon" />
                    Adicionar cirurgia "{{ props.search }}"
                  </div>
                </li>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
            </multiselect>

            <div class="custom-input" v-else>
              <b-button variant="link" @click="setCustom(false)">
                Selecionar um item da lista
              </b-button>
              <input
                autocomplete="off"
                v-model="form.fields.cirurgia"
                :readonly="readonly"
                type="text"
                class="form-control"
                placeholder=""
                :class="{ error: touched && errors.length > 0 && 1 === 2 }"
              />
            </div>
          </validation-provider>
        </div>

        <div class="laterality-container">
          <p>Lateralidade</p>
          <div class="laterality-content">
            <div class="laterality-input">
              <div class="laterality-option">
                <input
                  @focus="setFormActive(form.id)"
                  type="radio"
                  name="surgery-data-laterality"
                  value="Olho esquerdo"
                  v-model="form.fields.surgeryDataLaterality"
                />Olho esquerdo
              </div>
              <div class="laterality-option">
                <input
                  @focus="setFormActive(form.id)"
                  type="radio"
                  name="surgery-data-laterality"
                  value="Olho direito"
                  v-model="form.fields.surgeryDataLaterality"
                />Olho direito
              </div>
              <div class="laterality-option">
                <input
                  @focus="setFormActive(form.id)"
                  type="radio"
                  name="surgery-data-laterality"
                  value="Ambos os olhos"
                  v-model="form.fields.surgeryDataLaterality"
                />Ambos os olhos
              </div>
            </div>

            <b-button
              variant="primary"
              block
              class="h-38 add-button"
              :disabled="invalid"
              @click="addItem"
            >
              Adicionar
            </b-button>
          </div>
        </div>
      </validation-observer>

      <b-row>
        <table class="table-eyecare with-laterality">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Lateralidade</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, index) of form.value" :key="index">
              <td>{{ value.cirurgia }}</td>
              <td>{{ value.surgeryDataLaterality }}</td>
              <td class="text-right">
                <div class="more-with-menu">
                  <MoreVertical class="more-icon" />
                  <div class="menu">
                    <b-button
                      variant="link"
                      @click="editItem(value, index)"
                      :disabled="readonly"
                    >
                      Editar
                    </b-button>
                    <b-button
                      variant="link remove mb-0"
                      :disabled="readonly"
                      @click="removeItem(index)"
                    >
                      Remover
                    </b-button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import Plus from '@/assets/icons/plus.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import MoreVertical from '@/assets/icons/more-vertical.svg'

export default {
  name: 'SurgeryData',
  props: {
    initialForm: Object,
    readonly: Boolean,
    cirurgiasOftalmologicas: Array,
    setFormActive: Function
  },
  components: {
    Plus,
    ChevronDown,
    MoreVertical
  },
  data() {
    return {
      form: {
        fields: {},
        value: [],
        ...this.initialForm
      }
    }
  },
  methods: {
    setCustom(value, searchTerm) {
      this.form.fields.custom = value
      this.form.fields.cirurgia = value ? searchTerm : null
    },
    addItem() {
      if (this.form.editingIndex !== null) {
        this.form.value.splice(this.form.editingIndex, 1)
      }
      this.form.value.push(this.form.fields)

      this.form.fields = {
        custom: false,
        cirurgia: null,
        surgeryDataLaterality: 'Ambos os olhos'
      }

      this.form.editingIndex = null
      this.$emit('saveForm', this.form)
    },
    editItem(value, index) {
      this.form.fields = { ...value }
      this.form.editingIndex = index
    },
    removeItem(index) {
      this.form.value.splice(index, 1)
      this.$emit('saveForm', this.form)
    }
  },
  watch: {
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.value = newValue.value
        }
      },
      deep: true
    }
  }
}
</script>
