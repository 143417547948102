<template>
  <b-row>
    <b-col>
      <b-row v-if="!readonly">
        <b-col cols="12">
          <div class="getAutoRefraction">
            <button
            class="refraction" v-bind:class="{ 'disabled' : !hasPreConsultation }"
            @click="getAutoRefractionData()" @focus="setFormActive(form.id)" @blur="onBlurForm(form)">
            <span :id="`refraction-${this.form.id}`">Trazer resultados da autorrefração</span>
            </button>
            <p class="order-body" v-if="!hasPreConsultation">
              Não foram informados resultados da autorrefração {{ this.form.name === 'Refração estática' ? 'estática' : 'dinâmica' }} na pré-consulta.
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" >
          <div class="custom-input-group" >
            <div class="custom-input">
                <div class="eye-area" >
                  <EyeFill /> D
                </div>

                <div class="custom-input eye-box-container">
                  <div class="text-area type-2 with-br" @click="openOptions('esfericoDireito')">
                    <span >Esférico</span>
                    <div v-if="form.name === 'Refração dinâmica'">
                      <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoDireitoEsfericoDin" :readonly="readonly" :disabled="readonly"  @focus="setFormActive(form.id);" @blur="onBlurForm(form);">
                      <MultipleEyesOptions v-if="this.activeEyeBox ==='esfericoDireito' && !readonly" :form="form" activeInput="esfericoDireito" :setActiveInput="this.setActiveEyeBox" :options="form.fields.olhoDireitoEsfericoDin !== null ? (parseFloat(form.fields.olhoDireitoEsfericoDin.replace(',', '.')) < 0.00 ? optionsNegative : optionsPositive) : optionsPositive"/>
                    </div>

                    <div v-if="form.name === 'Refração estática'">
                      <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoDireitoEsfericoEst" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id);" @blur="onBlurForm(form);">
                      <MultipleEyesOptions v-if="this.activeEyeBox ==='esfericoDireito' && !readonly" :form="form" activeInput="esfericoDireito" :setActiveInput="this.setActiveEyeBox" :options="form.fields.olhoDireitoEsfericoEst  !== null ? (parseFloat(form.fields.olhoDireitoEsfericoEst.replace(',', '.')) < 0.00 ? optionsNegative : optionsPositive) : optionsPositive" />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="!readonly">
                      <div>
                        <button v-if="form.name === 'Refração dinâmica'" @click="changeInputValue('increase', 0.25, 'olhoDireitoEsfericoDin', null);"><p>+</p></button>
                        <button v-if="form.name === 'Refração estática'" @click="changeInputValue('increase', 0.25, 'olhoDireitoEsfericoEst', null);"><p>+</p></button>
                      </div>
                      <div>
                        <button v-if="form.name === 'Refração dinâmica'" @click="changeInputValue('decrease', 0.25, 'olhoDireitoEsfericoDin', null)"><p>-</p></button>
                        <button v-if="form.name === 'Refração estática'" @click="changeInputValue('decrease', 0.25, 'olhoDireitoEsfericoEst', null)"><p>-</p></button>
                      </div>
                  </div>
                </div>

              <div class="custom-input eye-box-container">
                <div class="text-area type-2 with-br" @click="openOptions('cilindricoDireito')">
                  <span>Cilíndrico</span>
                  <div v-if="form.name === 'Refração dinâmica'">
                    <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoDireitoCilindricoDin" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id);" @blur="onBlurForm(form);">
                    <MultipleEyesOptions v-if="this.activeEyeBox ==='cilindricoDireito' && !readonly" :form="form" activeInput="cilindricoDireito" :setActiveInput="this.setActiveEyeBox" :options="form.fields.olhoDireitoCilindricoDin !== null ? (parseFloat(form.fields.olhoDireitoCilindricoDin.replace(',', '.')) < 0.00 ? optionsNegative : optionsPositive) : optionsNegative"/>
                  </div>

                  <div v-if="form.name === 'Refração estática'">
                    <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoDireitoCilindricoEst" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id);" @blur="onBlurForm(form)">
                    <MultipleEyesOptions v-if="this.activeEyeBox ==='cilindricoDireito' && !readonly" :form="form" activeInput="cilindricoDireito" :setActiveInput="this.setActiveEyeBox" :options="form.fields.olhoDireitoCilindricoEst !== null ? (parseFloat(form.fields.olhoDireitoCilindricoEst.replace(',', '.')) < 0.00 ? optionsNegative : optionsPositive) : optionsNegative"/>
                  </div>
                </div>

                <div class="eyeValueChange" v-if="!readonly">
                    <div>
                      <button v-if="form.name === 'Refração dinâmica'" @click="changeInputValue('increase', 0.25, 'olhoDireitoCilindricoDin', null);"><p>+</p></button>
                      <button v-if="form.name === 'Refração estática'" @click="changeInputValue('increase', 0.25, 'olhoDireitoCilindricoEst', null);"><p>+</p></button>
                    </div>
                    <div>
                      <button v-if="form.name === 'Refração dinâmica'" @click="changeInputValue('decrease', 0.25, 'olhoDireitoCilindricoDin', null)"><p>-</p></button>
                      <button v-if="form.name === 'Refração estática'" @click="changeInputValue('decrease', 0.25, 'olhoDireitoCilindricoEst', null)"><p>-</p></button>
                    </div>
                </div>
              </div>

              <div class="custom-input eye-box-container">
                <div class="text-area type-2 with-br" @click="openOptions('eixoDireito')">
                  <span>Eixo</span>
                  <div v-if="form.name === 'Refração dinâmica'">
                    <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoDireitoEixoDin" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id);" @blur="onBlurForm(form)">
                    <MultipleEyesOptions v-if="this.activeEyeBox ==='eixoDireito' && !readonly" :form="form" activeInput="eixoDireito" :setActiveInput="this.setActiveEyeBox" :options="optionsEixo"/>
                  </div>

                  <div v-if="form.name === 'Refração estática'">
                    <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoDireitoEixoEst" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id);" @blur="onBlurForm(form)">
                    <MultipleEyesOptions v-if="this.activeEyeBox ==='eixoDireito' && !readonly" :form="form" activeInput="eixoDireito" :setActiveInput="this.setActiveEyeBox" :options="optionsEixo"/>
                  </div>
                </div>
                <div class="eyeValueChange" v-if="!readonly">
                    <div>
                      <button v-if="form.name === 'Refração dinâmica'" @click="changeInputValue('increase', 1, 'olhoDireitoEixoDin', 'eixo');"><p>+</p></button>
                      <button v-if="form.name === 'Refração estática'" @click="changeInputValue('increase', 1, 'olhoDireitoEixoEst', 'eixo');"><p>+</p></button>
                    </div>
                    <div>
                      <button v-if="form.name === 'Refração dinâmica'" @click="changeInputValue('decrease', 1, 'olhoDireitoEixoDin', 'eixo')"><p>-</p></button>
                      <button v-if="form.name === 'Refração estática'" @click="changeInputValue('decrease', 1, 'olhoDireitoEixoEst', 'eixo')"><p>-</p></button>
                    </div>
                </div>
              </div>


              <div class="custom-input eye-box-container">
                <div class="text-area type-2 with-br" @click="openOptions('DPDireito')">
                  <span>D.P.</span>
                  <div v-if="form.name === 'Refração dinâmica'">
                    <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoDireitoDPDin" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id); " @blur="onBlurForm(form)">
                    <MultipleEyesOptions v-if="this.activeEyeBox ==='DPDireito' && !readonly" :form="form" activeInput="DPDireito" :setActiveInput="this.setActiveEyeBox" :options="optionsDP"/>
                  </div>

                  <div v-if="form.name === 'Refração estática'">
                    <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoDireitoDPEst" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id); " @blur="onBlurForm(form)">
                    <MultipleEyesOptions v-if="this.activeEyeBox ==='DPDireito' && !readonly" :form="form" activeInput="DPDireito" :setActiveInput="this.setActiveEyeBox" :options="optionsDP"/>
                  </div>
                </div>
                <div class="eyeValueChange" v-if="!readonly">
                    <div>
                      <button v-if="form.name === 'Refração dinâmica'" @click="changeInputValue('increase', 0.1, 'olhoDireitoDPDin', 'DP');"><p>+</p></button>
                      <button v-if="form.name === 'Refração estática'" @click="changeInputValue('increase', 0.1, 'olhoDireitoDPEst', 'DP');"><p>+</p></button>
                    </div>
                    <div>
                      <button v-if="form.name === 'Refração dinâmica'" @click="changeInputValue('decrease', 0.1, 'olhoDireitoDPDin', 'DP')"><p>-</p></button>
                      <button v-if="form.name === 'Refração estática'" @click="changeInputValue('decrease', 0.1, 'olhoDireitoDPEst', 'DP')"><p>-</p></button>
                    </div>
                </div>
              </div>

              <div class="custom-input eye-box-container">
                <div class="text-area type-2" @click="openOptions('AdicaoDireito')">
                  <span>Adição</span>
                  <div v-if="form.name === 'Refração dinâmica'">
                    <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoDireitoAdicaoDin" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id); " @blur="onBlurForm(form)">
                    <MultipleEyesOptions v-if="this.activeEyeBox ==='AdicaoDireito' && !readonly" :form="form" activeInput="AdicaoDireito" :setActiveInput="this.setActiveEyeBox" :options="optionsPositive"/>
                  </div>

                  <div v-if="form.name === 'Refração estática'">
                    <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoDireitoAdicaoEst" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id); " @blur="onBlurForm(form)">
                    <MultipleEyesOptions v-if="this.activeEyeBox ==='AdicaoDireito' && !readonly" :form="form" activeInput="AdicaoDireito" :setActiveInput="this.setActiveEyeBox" :options="optionsPositive"/>
                  </div>
                </div>
                <div class="eyeValueChange" v-if="!readonly">
                    <div>
                      <button v-if="form.name === 'Refração dinâmica'" @click="changeInputValue('increase', 0.25, 'olhoDireitoAdicaoDin', null);"><p>+</p></button>
                      <button v-if="form.name === 'Refração estática'" @click="changeInputValue('increase', 0.25, 'olhoDireitoAdicaoEst',  null);"><p>+</p></button>
                    </div>
                    <div>
                      <button v-if="form.name === 'Refração dinâmica'" @click="changeInputValue('decrease', 0.25, 'olhoDireitoAdicaoDin', null)"><p>-</p></button>
                      <button v-if="form.name === 'Refração estática'" @click="changeInputValue('decrease', 0.25, 'olhoDireitoAdicaoEst', null)"><p>-</p></button>
                    </div>
                </div>
              </div>
            </div>

            <div class="custom-input no-bb">
                <div class="eye-area">
                <EyeFill /> E
                </div>

              <div class="custom-input eye-box-container">
                <div class="text-area type-2 with-br" @click="openOptions('esfericoEsquerdo')">
                  <div v-if="form.name === 'Refração dinâmica'">
                    <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoEsquerdoEsfericoDin" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id); " @blur="onBlurForm(form);">
                    <MultipleEyesOptions v-if="this.activeEyeBox ==='esfericoEsquerdo' && !readonly" :form="form" activeInput="esfericoEsquerdo" :setActiveInput="this.setActiveEyeBox" :options="form.fields.olhoEsquerdoEsfericoDin !== null ? (parseFloat(form.fields.olhoEsquerdoEsfericoDin.replace(',', '.')) < 0.00 ? optionsNegative : optionsPositive) : optionsPositive"/>
                  </div>

                  <div v-if="form.name === 'Refração estática'">
                    <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoEsquerdoEsfericoEst" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id); " @blur="onBlurForm(form);">
                    <MultipleEyesOptions v-if="this.activeEyeBox ==='esfericoEsquerdo' && !readonly" :form="form" activeInput="esfericoEsquerdo" :setActiveInput="this.setActiveEyeBox" :options="form.fields.olhoEsquerdoEsfericoEst  !== null ? (parseFloat(form.fields.olhoEsquerdoEsfericoEst.replace(',', '.')) < 0.00 ? optionsNegative : optionsPositive) : optionsPositive" />
                  </div>
                </div>
                <div class="eyeValueChange" v-if="!readonly">
                    <div>
                      <button v-if="form.name === 'Refração dinâmica'" @click="changeInputValue('increase', 0.25, 'olhoEsquerdoEsfericoDin', null);"><p>+</p></button>
                      <button v-if="form.name === 'Refração estática'" @click="changeInputValue('increase', 0.25, 'olhoEsquerdoEsfericoEst', null);"><p>+</p></button>
                    </div>
                    <div>
                      <button v-if="form.name === 'Refração dinâmica'" @click="changeInputValue('decrease', 0.25, 'olhoEsquerdoEsfericoDin', null)"><p>-</p></button>
                      <button v-if="form.name === 'Refração estática'" @click="changeInputValue('decrease', 0.25, 'olhoEsquerdoEsfericoEst', null)"><p>-</p></button>
                    </div>
                </div>
              </div>


              <div class="custom-input eye-box-container">
                <div class="text-area type-2 with-br" @click="openOptions('cilindricoEsquerdo')">
                  <div v-if="form.name === 'Refração dinâmica'">
                    <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoEsquerdoCilindricoDin" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id);" @blur="onBlurForm(form)">
                    <MultipleEyesOptions v-if="this.activeEyeBox ==='cilindricoEsquerdo' && !readonly" :form="form" activeInput="cilindricoEsquerdo" :setActiveInput="this.setActiveEyeBox" :options="form.fields.olhoEsquerdoCilindricoDin !== null ? (parseFloat(form.fields.olhoEsquerdoCilindricoDin.replace(',', '.')) < 0.00 ? optionsNegative : optionsPositive) : optionsNegative"/>
                  </div>

                  <div v-if="form.name === 'Refração estática'">
                    <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoEsquerdoCilindricoEst" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id);" @blur="onBlurForm(form)">
                    <MultipleEyesOptions v-if="this.activeEyeBox ==='cilindricoEsquerdo' && !readonly" :form="form" activeInput="cilindricoEsquerdo" :setActiveInput="this.setActiveEyeBox" :options="form.fields.olhoEsquerdoCilindricoEst !== null ? (parseFloat(form.fields.olhoEsquerdoCilindricoEst.replace(',', '.')) < 0.00 ? optionsNegative : optionsPositive) : optionsNegative"/>
                  </div>
                </div>

                <div class="eyeValueChange" v-if="!readonly">
                    <div>
                      <button v-if="form.name === 'Refração dinâmica'" @click="changeInputValue('increase', 0.25, 'olhoEsquerdoCilindricoDin', null);"><p>+</p></button>
                      <button v-if="form.name === 'Refração estática'" @click="changeInputValue('increase', 0.25, 'olhoEsquerdoCilindricoEst', null);"><p>+</p></button>
                    </div>
                    <div>
                      <button v-if="form.name === 'Refração dinâmica'" @click="changeInputValue('decrease', 0.25, 'olhoEsquerdoCilindricoDin', null)"><p>-</p></button>
                      <button v-if="form.name === 'Refração estática'" @click="changeInputValue('decrease', 0.25, 'olhoEsquerdoCilindricoEst', null)"><p>-</p></button>
                    </div>
                </div>
              </div>


              <div class="custom-input eye-box-container">
                <div class="text-area type-2 with-br" @click="openOptions('eixoEsquerdo')">
                  <div v-if="form.name === 'Refração dinâmica'">
                    <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoEsquerdoEixoDin" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id); " @blur="onBlurForm(form)">
                    <MultipleEyesOptions v-if="this.activeEyeBox ==='eixoEsquerdo' && !readonly" :form="form" activeInput="eixoEsquerdo" :setActiveInput="this.setActiveEyeBox" :options="optionsEixo"/>
                  </div>

                  <div v-if="form.name === 'Refração estática'">
                    <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoEsquerdoEixoEst" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id); " @blur="onBlurForm(form)">
                    <MultipleEyesOptions v-if="this.activeEyeBox ==='eixoEsquerdo' && !readonly" :form="form" activeInput="eixoEsquerdo" :setActiveInput="this.setActiveEyeBox" :options="optionsEixo"/>
                  </div>
                </div>
                <div class="eyeValueChange" v-if="!readonly">
                    <div>
                      <button v-if="form.name === 'Refração dinâmica'" @click="changeInputValue('increase', 1, 'olhoEsquerdoEixoDin', 'eixo');"><p>+</p></button>
                      <button v-if="form.name === 'Refração estática'" @click="changeInputValue('increase', 1, 'olhoEsquerdoEixoEst', 'eixo');"><p>+</p></button>
                    </div>
                    <div>
                      <button v-if="form.name === 'Refração dinâmica'" @click="changeInputValue('decrease', 1, 'olhoEsquerdoEixoDin', 'eixo')"><p>-</p></button>
                      <button v-if="form.name === 'Refração estática'" @click="changeInputValue('decrease', 1, 'olhoEsquerdoEixoEst', 'eixo')"><p>-</p></button>
                    </div>
                </div>
              </div>


              <div class="custom-input eye-box-container">
                <div class="text-area type-2 with-br" @click="openOptions('DPEsquerdo')">
                  <div v-if="form.name === 'Refração dinâmica'">
                    <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoEsquerdoDPDin" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id); " @blur="onBlurForm(form)">
                    <MultipleEyesOptions v-if="this.activeEyeBox ==='DPEsquerdo' && !readonly" :form="form" activeInput="DPEsquerdo" :setActiveInput="this.setActiveEyeBox" :options="optionsDP"/>
                  </div>

                  <div v-if="form.name === 'Refração estática'">
                    <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoEsquerdoDPEst" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id); " @blur="onBlurForm(form)">
                    <MultipleEyesOptions v-if="this.activeEyeBox ==='DPEsquerdo' && !readonly" :form="form" activeInput="DPEsquerdo" :setActiveInput="this.setActiveEyeBox" :options="optionsDP"/>
                  </div>
                </div>
                <div class="eyeValueChange" v-if="!readonly">
                    <div>
                      <button v-if="form.name === 'Refração dinâmica'" @click="changeInputValue('increase', 0.1, 'olhoEsquerdoDPDin', 'DP');"><p>+</p></button>
                      <button v-if="form.name === 'Refração estática'" @click="changeInputValue('increase', 0.1, 'olhoEsquerdoDPEst', 'DP');"><p>+</p></button>
                    </div>
                    <div>
                      <button v-if="form.name === 'Refração dinâmica'" @click="changeInputValue('decrease', 0.1, 'olhoEsquerdoDPDin', 'DP')"><p>-</p></button>
                      <button v-if="form.name === 'Refração estática'" @click="changeInputValue('decrease', 0.1, 'olhoEsquerdoDPEst', 'DP')"><p>-</p></button>
                    </div>
                </div>
              </div>


              <div class="custom-input eye-box-container">
                <div class="text-area type-2" @click="openOptions('AdicaoEsquerdo')">

                  <div v-if="form.name === 'Refração dinâmica'">
                    <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoEsquerdoAdicaoDin" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id); " @blur="onBlurForm(form)">
                    <MultipleEyesOptions v-if="this.activeEyeBox ==='AdicaoEsquerdo' && !readonly" :form="form" activeInput="AdicaoEsquerdo" :setActiveInput="this.setActiveEyeBox" :options="optionsPositive"/>
                  </div>

                  <div v-if="form.name === 'Refração estática'">
                    <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoEsquerdoAdicaoEst" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id); " @blur="onBlurForm(form)">
                    <MultipleEyesOptions v-if="this.activeEyeBox ==='AdicaoEsquerdo' && !readonly" :form="form" activeInput="AdicaoEsquerdo" :setActiveInput="this.setActiveEyeBox" :options="optionsPositive"/>
                  </div>
                </div>
                <div class="eyeValueChange" v-if="!readonly">
                    <div>
                      <button v-if="form.name === 'Refração dinâmica'" @click="changeInputValue('increase', 0.25, 'olhoEsquerdoAdicaoDin', null);"><p>+</p></button>
                      <button v-if="form.name === 'Refração estática'" @click="changeInputValue('increase', 0.25, 'olhoEsquerdoAdicaoEst', null);"><p>+</p></button>
                    </div>
                    <div>
                      <button v-if="form.name === 'Refração dinâmica'" @click="changeInputValue('decrease', 0.25, 'olhoEsquerdoAdicaoDin', null)"><p>-</p></button>
                      <button v-if="form.name === 'Refração estática'" @click="changeInputValue('decrease', 0.25, 'olhoEsquerdoAdicaoEst', null)"><p>-</p></button>
                    </div>
                </div>
              </div>

            </div>
            </div>
            <button class="blue-underline" @click="copyEyeValues" v-if="!readonly" @focus="setFormActive(form.id)">Copiar valores O.D para O.E</button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
            <label for="usar-valores-dinamica" class="checkbox" v-if="form.name === 'Refração dinâmica'">
              <input
                type="checkbox"
                id="usar-valores-dinamica"
                v-model="form.fields.usarValoresNaPrescricaoDeOculosDin"
                @click="dispatchRefractionToGlasses(!form.fields.usarValoresNaPrescricaoDeOculosDin, 'Din')"
                :disabled="readonly"
              />
              <span :class="`checkmark ${readonly ? 'checkmark-disabled' : ''}`">
                  <Check />
              </span>
              Usar valores na prescrição de óculos
            </label>

            <label v-if="form.name === 'Refração estática'" for="usar-valores-estatica" class="checkbox">
              <input
                type="checkbox"
                id="usar-valores-estatica"
                v-model="form.fields.usarValoresNaPrescricaoDeOculosEst"
                @click="dispatchRefractionToGlasses(!form.fields.usarValoresNaPrescricaoDeOculosEst, 'Est')"
                :disabled="readonly"
              />
              <span :class="`checkmark ${readonly ? 'checkmark-disabled' : ''}`">
                  <Check />
              </span>
              Usar valores na prescrição de óculos
            </label>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { EventBus } from '@/utils/eventBus'
import EyeFill from '@/assets/icons/eye-fill.svg'
import Check from '@/assets/icons/check.svg'
import MultipleEyesOptions from './MultipleEyesOptions.vue'


export default {
  components: { EyeFill, Check, MultipleEyesOptions },
  props: {
    initialForm: Object,
    readonly: Boolean,
    setFormActive: { type: Function },
    onBlurForm: { type: Function },
    attendanceReview: Object,
    preConsultation: Object,
  },
  data() {
    return {
      form: {
        fields: {},
        ...this.initialForm,
      },
      activeEyeBox: String,
      optionsPositive: [['+0,25', '+0,50', '+0,75', '+1,00', '+1,25'], ['+1,50', '+1,75', '+2,00', '+2,25', '+2,50'], ['+2,75', '+3,00', '+3,25', '+3,50', '+3,75'], ['+4,00', '+4,25', '+4,50', '+4,75', '+5,00']],
      optionsNegative: [['-0,25', '-0,50', '-0,75', '-1,00', '-1,25'], ['-1,50', '-1,75', '-2,00', '-2,25', '-2,50'], ['-2,75', '-3,00', '-3,25', '-3,50', '-3,75'], ['-4,00', '-4,25', '-4,50', '-4,75', '-5,00']],
      optionsEixo: [['5º', '10º', '15º', '20º', '25º', '30º'], ['35º', '40º', '45º', '50º', '55º', '60º'], ['65º', '70º', '75º', '80º', '85º', '90º'], ['95º', '100º', '105º', '110º', '115º', '120º'], ['125º', '130º', '135º', '140º', '145º', '150º'], ['155º', '160º', '165º', '170º', '175º', '180º']],
      optionsDP: [['3,0', '3,5', '4,0', '4,5'], ['5,0', '5,5', '6,0', '6,5'], ['7,0', '7,5', '8,0', '8,5'], ['9,0', '9,5', '10,0', '10,5']]
    }
  },
  mounted() {
    if (!this.readonly) {
      this.onChange();
    }

    EventBus.$on('getRefractionValues', this.getRefractionValues);
  },
  methods: {
    openOptions(input, clickable){
        this.activeEyeBox === input ? this.activeEyeBox = null : this.activeEyeBox = input
    },
    copyEyeValues(){
      const sufix = this.form.name === 'Refração dinâmica' ? 'Din' : 'Est';

      this.form.fields[`olhoEsquerdoEsferico${sufix}`] = this.form.fields[`olhoDireitoEsferico${sufix}`];
      this.form.fields[`olhoEsquerdoCilindrico${sufix}`] = this.form.fields[`olhoDireitoCilindrico${sufix}`];
      this.form.fields[`olhoEsquerdoEixo${sufix}`] = this.form.fields[`olhoDireitoEixo${sufix}`];
      this.form.fields[`olhoEsquerdoAdicao${sufix}`] = this.form.fields[`olhoDireitoAdicao${sufix}`];
      this.form.fields[`olhoEsquerdoDP${sufix}`] = this.form.fields[`olhoDireitoDP${sufix}`];

      this.onBlurForm(this.form);
    },
    setActiveEyeBox(BoxName){
      this.activeEyeBox = BoxName
    },
    changeInputValue(change, amount, value, type) {
      if(this.form.fields[value] === '' || this.form.fields[value] === null){
        this.form.fields[value] = '0'
      }
      if(!(isNaN(parseFloat(this.form.fields[value])))){
        if(type === 'eixo') {
          if(change === 'increase'){
            this.form.fields[value] = ((parseFloat(this.form.fields[value].replace(',', '.')) + amount).toFixed(0)).replace('.', ',')
          } else if(change === 'decrease'){
            this.form.fields[value] = ((parseFloat(this.form.fields[value].replace(',', '.')) - amount).toFixed(0)).replace('.', ',')
          }
          if(parseFloat(this.form.fields[value].replace(',', '.')) > 0) {
            this.form.fields[value] = this.form.fields[value]+'º'
          }
        } else if(type === 'DP'){
          if(change === 'increase'){
            this.form.fields[value] = ((parseFloat(this.form.fields[value].replace(',', '.')) + amount).toFixed(1)).replace('.', ',')

          } else if(change === 'decrease'){
            this.form.fields[value] = ((parseFloat(this.form.fields[value].replace(',', '.')) - amount).toFixed(1)).replace('.', ',')
          }
          if(parseFloat(this.form.fields[value].replace(',', '.')) > 0){
            this.form.fields[value] = this.form.fields[value]+''
          }
        } else if(!(isNaN(parseFloat(this.form.fields[value])))){
          if(change === 'increase'){
            this.form.fields[value] = ((parseFloat(this.form.fields[value].replace(',', '.')) + amount).toFixed(2)).replace('.', ',')

          } else if(change === 'decrease'){
            this.form.fields[value] = ((parseFloat(this.form.fields[value].replace(',', '.')) - amount).toFixed(2)).replace('.', ',')
          }
          if(parseFloat(this.form.fields[value].replace(',', '.')) > 0){
            this.form.fields[value] = '+'+this.form.fields[value]
          }
        }
        this.onChange();
        this.onBlurForm(this.form);
      }
    },
    onChange() {
    },
    dispatchRefractionToGlasses(checked, sufix) {
      EventBus.$emit('getRefractionValues', checked && sufix, this.form.id);
    },
    getRefractionValues(sufix, issuerFormId) {
      const to = issuerFormId === 'lentes-de-contato' ? 'ContactLens' : 'Glasses';

      if (sufix && this.form.id.includes(sufix.toLowerCase())) {
        EventBus.$emit(`copyRefractionTo${to}`, this.form.fields, sufix);
      }

      if (!sufix && this.form.id.includes('dinamica')) {
        EventBus.$emit(`copyRefractionTo${to}`, null, sufix);
      }

      if (issuerFormId !== 'lentes-de-contato') {
          if (this.form.id === 'refracao-dinamica')
            this.form.fields.usarValoresNaPrescricaoDeOculosDin = sufix === 'Din';
          else
            this.form.fields.usarValoresNaPrescricaoDeOculosEst = sufix === 'Est';
          this.onBlurForm(this.form);
      }
    },
    copyAutoRefraction() {
      const type = this.form.name === 'Refração estática' ? '_static' : '';
      return this.preConsultation && (
        this.preConsultation[`auto_refraction${type}_addition_l`] !== null ||
        this.preConsultation[`auto_refraction${type}_addition_r`] !== null ||
        this.preConsultation[`auto_refraction${type}_cylindrical_l`] !== null ||
        this.preConsultation[`auto_refraction${type}_cylindrical_r`] !== null ||
        this.preConsultation[`auto_refraction${type}_axis_l`] !== null ||
        this.preConsultation[`auto_refraction${type}_axis_r`] !== null ||
        this.preConsultation[`auto_refraction${type}_spherical_l`] !== null ||
        this.preConsultation[`auto_refraction${type}_spherical_r`] !== null
      );
    },
    getAutoRefractionData(){
      const type = this.form.name === 'Refração estática' ? 'Est' : 'Din';
      const preConsultationType = this.form.name === 'Refração estática' ? '_static' : '';
      this.form.fields[`olhoEsquerdoEsferico${type}`] = this.preConsultation[`auto_refraction${preConsultationType}_spherical_l`]
      this.form.fields[`olhoDireitoEsferico${type}`] = this.preConsultation[`auto_refraction${preConsultationType}_spherical_r`]
      this.form.fields[`olhoDireitoCilindrico${type}`] = this.preConsultation[`auto_refraction${preConsultationType}_cylindrical_r`]
      this.form.fields[`olhoEsquerdoCilindrico${type}`] = this.preConsultation[`auto_refraction${preConsultationType}_cylindrical_l`]
      this.form.fields[`olhoEsquerdoEixo${type}`] = this.preConsultation[`auto_refraction${preConsultationType}_axis_l`]
      this.form.fields[`olhoDireitoEixo${type}`] = this.preConsultation[`auto_refraction${preConsultationType}_axis_r`]
      this.form.fields[`olhoEsquerdoAdicao${type}`] = this.preConsultation[`auto_refraction${preConsultationType}_addition_l`]
      this.form.fields[`olhoDireitoAdicao${type}`] = this.preConsultation[`auto_refraction${preConsultationType}_addition_r`]
      this.onBlurForm(this.form);
    },
  },
  watch: {
    'form.fields': {
      handler() {
        this.onChange();
      },
      deep: true,
    },
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.fields = newValue.fields
        }
      },
      deep: true
    }
  },
  computed: {
    hasPreConsultation: function() {
      return this.copyAutoRefraction();
    }
  }
}
</script>


<style lang="scss" scoped>
.getAutoRefraction{

  &:hover {
    .order-body {
      display: block;
    }
  }
  .order-body {
    display: none;
    background-color: #fff;
    border: 1px solid var(--neutral-200);
    border-radius: 4px;
    padding: 5px 25px;
    position: absolute;
    z-index: 5;
    box-shadow: 10px 5px 5px var(--neutral-200);
  }
}

.eyeValueChange {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0 !important;
  position: relative;
  margin-left: -30px !important;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;

    button {
      height: 20px;
      width: 30px;
      color: var(--greys-60);
      background-color: #fff;
      position: relative !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 1.5px solid var(--blue-100) !important;

      p {
        font-weight: 700;
      }

    }
  }
}

.refraction {
  color: var(--blue-500);
  text-decoration: underline;
  margin-top: -10px;
  margin-bottom: 10px;
  font-weight: 700;
}

.disabled {
  color: var(--neutral-200);
}

.eye-box-container {
  .eyeValueChange {
    visibility: hidden;
  }

  &:hover {
    .eyeValueChange {
      visibility: visible;
    }
  }
}
</style>
