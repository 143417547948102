<template>
  <b-row>
    <toggle-button
      class="autoFillBtn"
      v-model="form.autoCompleted"
      :sync="true"
      :height="32"
      :disabled="readonly"
      :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
      @change="autoFill"
    />
    <span class="autoFillBtnLabel">Marcar campos em branco como “sem alterações”</span>
    <b-col cols="12">
      <div class="form-group">
        <label>Cílios e pálpebras</label>
        <b-row>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> D</p>
              <input
                autocomplete="off"
                v-model="form.fields.ciliosEPalpebrasDireito"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
            <button
              class="blue-underline"
              @click="
                () =>
                  copyEyeValues(
                    'ciliosEPalpebrasDireito',
                    'ciliosEPalpebrasEsquerdo'
                  )
              "
              v-if="!readonly"
              @focus="setFormActive(form.id)"
            >
              Copiar valores O.D para O.E
            </button>
          </b-col>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> E</p>
              <input
                autocomplete="off"
                v-model="form.fields.ciliosEPalpebrasEsquerdo"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>
    <b-col cols="12">
      <div class="form-group">
        <label>Conjuntiva</label>
        <b-row>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> D</p>
              <input
                autocomplete="off"
                v-model="form.fields.conjuntivaDireito"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
            <button
              class="blue-underline"
              @click="
                () => copyEyeValues('conjuntivaDireito', 'conjuntivaEsquerdo')
              "
              v-if="!readonly"
              @focus="setFormActive(form.id)"
            >
              Copiar valores O.D para O.E
            </button>
          </b-col>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> E</p>
              <input
                autocomplete="off"
                v-model="form.fields.conjuntivaEsquerdo"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>
    <b-col cols="12">
      <div class="form-group">
        <label>Córnea</label>
        <b-row>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> D</p>
              <input
                autocomplete="off"
                v-model="form.fields.corneaDireito"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
            <button
              class="blue-underline"
              @click="() => copyEyeValues('corneaDireito', 'corneaEsquerdo')"
              v-if="!readonly"
              @focus="setFormActive(form.id)"
            >
              Copiar valores O.D para O.E
            </button>
          </b-col>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> E</p>
              <input
                autocomplete="off"
                v-model="form.fields.corneaEsquerdo"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>
    <b-col cols="12">
      <div class="form-group">
        <label>Câmara anterior</label>
        <b-row>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> D</p>
              <input
                autocomplete="off"
                v-model="form.fields.camaraAnteriorDireito"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
            <button
              class="blue-underline"
              @click="
                () =>
                  copyEyeValues(
                    'camaraAnteriorDireito',
                    'camaraAnteriorEsquerdo'
                  )
              "
              v-if="!readonly"
              @focus="setFormActive(form.id)"
            >
              Copiar valores O.D para O.E
            </button>
          </b-col>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> E</p>
              <input
                autocomplete="off"
                v-model="form.fields.camaraAnteriorEsquerdo"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>
    <b-col cols="12">
      <div class="form-group">
        <label>Íris</label>
        <b-row>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> D</p>
              <input
                autocomplete="off"
                v-model="form.fields.irisDireito"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
            <button
              class="blue-underline"
              @click="() => copyEyeValues('irisDireito', 'irisEsquerdo')"
              v-if="!readonly"
              @focus="setFormActive(form.id)"
            >
              Copiar valores O.D para O.E
            </button>
          </b-col>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> E</p>
              <input
                autocomplete="off"
                v-model="form.fields.irisEsquerdo"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>
    <b-col cols="12">
      <div class="form-group">
        <label>Pupila</label>
        <b-row>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> D</p>
              <input
                autocomplete="off"
                v-model="form.fields.pupilaDireito"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
            <button
              class="blue-underline"
              @click="() => copyEyeValues('pupilaDireito', 'pupilaEsquerdo')"
              v-if="!readonly"
              @focus="setFormActive(form.id)"
            >
              Copiar valores O.D para O.E
            </button>
          </b-col>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> E</p>
              <input
                autocomplete="off"
                v-model="form.fields.pupilaEsquerdo"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>
    <b-col cols="12">
      <div class="form-group">
        <label>Cristalino/Lente Intraocular</label>
        <b-row>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> D</p>
              <input
                autocomplete="off"
                v-model="form.fields.cristalinoDireito"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
            <button
              class="blue-underline"
              @click="
                () => copyEyeValues('cristalinoDireito', 'cristalinoEsquerdo')
              "
              v-if="!readonly"
              @focus="setFormActive(form.id)"
            >
              Copiar valores O.D para O.E
            </button>
          </b-col>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> E</p>
              <input
                autocomplete="off"
                v-model="form.fields.cristalinoEsquerdo"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>
    <b-col cols="12">
      <div class="form-group">
        <label>Vítreo anterior</label>
        <b-row>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> D</p>
              <input
                autocomplete="off"
                v-model="form.fields.vitreoDireito"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
            <button
              class="blue-underline"
              @click="() => copyEyeValues('vitreoDireito', 'vitreoEsquerdo')"
              v-if="!readonly"
              @focus="setFormActive(form.id)"
            >
              Copiar valores O.D para O.E
            </button>
          </b-col>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> E</p>
              <input
                autocomplete="off"
                v-model="form.fields.vitreoEsquerdo"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>
    <b-col cols="12">
      <div class="form-group mb-0">
        <label>Biomicroscopia de fundo</label>
        <b-row>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> D</p>
              <input
                autocomplete="off"
                v-model="form.fields.biomicroscopiaDeFundoDireito"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
            <button
              class="blue-underline"
              @click="
                () =>
                  copyEyeValues(
                    'biomicroscopiaDeFundoDireito',
                    'biomicroscopiaDeFundoEsquerdo'
                  )
              "
              v-if="!readonly"
              @focus="setFormActive(form.id)"
            >
              Copiar valores O.D para O.E
            </button>
          </b-col>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> E</p>
              <input
                autocomplete="off"
                v-model="form.fields.biomicroscopiaDeFundoEsquerdo"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>
    <div class="add-file">
      <label for="imageSelector-biomicroscopia" class="file-input"
        ><Paperclip /> anexar arquivo</label
      >
      <input
        type="file"
        id="imageSelector-biomicroscopia"
        ref="image"
        style="display: none"
        accept=".jpg, .png, .pdf"
        :disabled="readonly"
        @change="onFileChange($event, 'Biomicroscopia')"
      />
      <div class="file-list">
        <p
          v-if="
            filesToUpload != null &&
            filesToUpload.find(element => element.group === 'Biomicroscopia')
          "
        >
          Anexos:
        </p>
        <ul v-for="(file, index) of filesToUpload" :key="index">
          <li v-if="file.group == 'Biomicroscopia'">
            <Pdf v-if="file.type == 'application/pdf'" class="icon" />
            <Files v-else class="icon" />
            <div class="attachment_file_name">{{ file.name }}</div>
            <CloseUploadedFile
              class="icon-close"
              @click="filesToUpload.splice(index, 1)"
            />
          </li>
        </ul>
      </div>
    </div>
  </b-row>
</template>

<script>
import Paperclip from '@/assets/icons/paperclip2.svg'
import Pdf from '@/assets/icons/pdf.svg'
import Files from '@/assets/icons/files.svg'
import CloseUploadedFile from '@/assets/icons/closeUploadedFile.svg'
import EyeFill from '@/assets/icons/eye-fill.svg'

export default {
  name: 'Biomicroscopy',
  props: {
    initialForm: Object,
    readonly: Boolean,
    onBlurForm: Function,
    setFormActive: Function,
    onFileChange: Function,
    filesToUpload: Array
  },
  components: {
    Paperclip,
    Pdf,
    Files,
    CloseUploadedFile,
    EyeFill
  },
  data() {
    return {
      form: {
        fields: {},
        ...this.initialForm
      }
    }
  },
  methods: {
    autoFill() {
      const newValue = this.form.autoCompleted ? 'sem alterações' : null;
      for (const item in this.form.fields) {
        const currentValue = this.form.fields[item]
        if(currentValue === null || currentValue === 'sem alterações'
          || currentValue.trim() === '') {
          this.form.fields[item] = newValue
        }
      }

      this.$emit('saveForm', this.form)
    },
    copyEyeValues(rightEyeFieldName, leftEyeFieldName) {
      this.form.fields[leftEyeFieldName] = this.form.fields[rightEyeFieldName];
      this.onBlurForm(this.form);
    }
  },
  watch: {
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.fields = newValue.fields
        }
      },
      deep: true
    }
  }
}
</script>
