<template>
  <b-row>
    <b-col>
      <validation-observer
        :ref="this.initialForm.id + '-form'"
        v-slot="{ invalid }"
        v-if="!readonly"
      >
        <b-row>
          <b-col cols="10">
            <div class="form-group">
              <validation-provider
                name="medicamento"
                :rules="{ required: true }"
                v-slot="{ errors, touched }"
              >
                <input
                  autocomplete="off"
                  id="medicamento"
                  v-model="form.fields.medicamento"
                  :readonly="readonly"
                  type="text"
                  class="form-control"
                  placeholder="Nome do medicamento"
                  :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                />
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="2">
            <b-button
              variant="primary"
              block
              class="h-38"
              :disabled="invalid"
              @click="saveItem"
            >
              Adicionar
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
      <b-row>
        <b-col>
          <table class="table-eyecare">
            <thead>
              <tr>
                <th>Nome</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, index) of form.value" :key="index">
                <td style="width: 100%">
                  <span
                    style="margin-top: 5px; margin-right: 10px; float: left"
                  >
                    {{ value.medicamento }}
                  </span>

                  <toggle-button
                    class="togle-button"
                    v-model="value.emUso"
                    :sync="true"
                    :height="32"
                    :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
                    @change="updateItem"
                    :disabled="readonly"
                  />
                  <span
                    class="medicine-status"
                    :class="{ enabled: value.emUso, disabled: !value.emUso }"
                    >{{ value.emUso ? 'Em uso' : 'Cessado' }}</span
                  >
                </td>
                <td class="text-right">
                  <div class="more-with-menu">
                    <MoreVertical class="more-icon" />
                    <div class="menu">
                      <b-button
                        variant="link remove mb-0 p-0"
                        :disabled="readonly"
                        @click="removeItem(index)"
                      >
                        Remover
                      </b-button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import MoreVertical from '@/assets/icons/more-vertical.svg'

export default {
  name: 'MedicinesInUse',
  props: {
    initialForm: Object,
    readonly: Boolean
  },
  components: {
    MoreVertical
  },
  data() {
    return {
      form: {
        fields: {},
        value: [],
        editingIndex: null,
        ...this.initialForm
      }
    }
  },
  methods: {
    saveItem() {
      const medicines = this.form.value.map(value => value.medicamento.toLowerCase())
      if (medicines.includes(this.form.fields.medicamento.toLowerCase())) {
        this.$toast.warning('Medicamento já adicionado')
        return
      }
      if (this.form.editingIndex !== null) {
        this.form.value.splice(this.form.editingIndex, 1)
      }

      this.form.value.push(this.form.fields)

      this.form.fields = {
        medicamento: null,
        emUso: true
      }
      this.form.editingIndex = null
      this.$emit('saveForm', this.form)
    },
    editItem(value, index) {
      this.form.fields = { ...value }
      this.form.editingIndex = index
    },
    removeItem(index) {
      const removed = this.form.value.splice(index, 1)
      this.$emit('saveForm', this.form, removed);
    },
    updateItem() {
      this.$emit('saveForm', this.form)
    }
  },
  watch: {
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.value = newValue.value
        }
      },
      deep: true
    }
  }
}
</script>
