<template >
<b-row >
<b-col>
    <b-row >
      <b-col cols="12" >
        <div class="custom-input-group" >
          <div class="custom-input">
              <div class="eye-area" >
                <EyeFill /> D
              </div>
              <div class="custom-input eye-box-container">
                <div class="text-area type-2 with-br" @click="openOptions('esfericoDireito')">
                  <span >Esférico</span>
                  <div >
                    <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoDireitoEsferico" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id);" @blur="onBlurForm(form);">
                    <MultipleEyesOptions v-if="this.activeEyeBox ==='esfericoDireito' && !readonly" :form="form" activeInput="esfericoDireito" :setActiveInput="this.setActiveEyeBox" :options="form.fields.olhoDireitoEsferico ? (parseFloat(form.fields.olhoDireitoEsferico.replace(',', '.')) < 0.00 ? optionsNegative : optionsPositive) : optionsPositive" />
                  </div>
                </div>
                <div class="eyeValueChange" v-if="!readonly">
                  <div>
                    <button @click="changeInputValue('increase', 0.25, 'olhoDireitoEsferico', null);"><p>+</p></button>
                  </div>
                  <div>
                    <button @click="changeInputValue('decrease', 0.25, 'olhoDireitoEsferico', null)"><p>-</p></button>
                  </div>
                </div>
              </div>

            <div class="custom-input eye-box-container">
              <div class="text-area type-2 with-br" @click="openOptions('cilindricoDireito')">
                <span>Cilíndrico</span>
                <div >
                  <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoDireitoCilindrico" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id);" @blur="onBlurForm(form)">
                  <MultipleEyesOptions v-if="this.activeEyeBox ==='cilindricoDireito' && !readonly" :form="form" activeInput="cilindricoDireito" :setActiveInput="this.setActiveEyeBox" :options="form.fields.olhoDireitoCilindrico !== null ? (parseFloat(form.fields.olhoDireitoCilindrico.replace(',', '.')) < 0.00 ? optionsNegative : optionsPositive) : optionsNegative"/>
                </div>
              </div>
              <div class="eyeValueChange" v-if="!readonly">
                <div>
                  <button @click="changeInputValue('increase', 0.25, 'olhoDireitoCilindrico', null);"><p>+</p></button>
                </div>
                <div>
                  <button @click="changeInputValue('decrease', 0.25, 'olhoDireitoCilindrico', null)"><p>-</p></button>
                </div>
              </div>
            </div>

            <div class="custom-input eye-box-container">
              <div class="text-area type-2 with-br" @click="openOptions('eixoDireito')">
                <span>Eixo</span>
                <div >
                  <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoDireitoEixo" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id);" @blur="onBlurForm(form)">
                  <MultipleEyesOptions v-if="this.activeEyeBox ==='eixoDireito' && !readonly" :form="form" activeInput="eixoDireito" :setActiveInput="this.setActiveEyeBox" :options="optionsEixo"/>
                </div>
              </div>
              <div class="eyeValueChange" v-if="!readonly">
                <div>
                  <button @click="changeInputValue('increase', 1, 'olhoDireitoEixo', 'eixo');"><p>+</p></button>
                </div>
                <div>
                  <button @click="changeInputValue('decrease', 1, 'olhoDireitoEixo', 'eixo')"><p>-</p></button>
                </div>
              </div>
            </div>
            <div class="custom-input eye-box-container">
              <div class="text-area type-2 with-br" @click="openOptions('DPDireito')">
                <span>D.P.</span>
                <div >
                  <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoDireitoDP" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id);" @blur="onBlurForm(form)">
                  <MultipleEyesOptions v-if="this.activeEyeBox ==='DPDireito' && !readonly" :form="form" activeInput="DPDireito" :setActiveInput="this.setActiveEyeBox" :options="optionsDP"/>
                </div>
              </div>
              <div class="eyeValueChange" v-if="!readonly">
                <div>
                  <button @click="changeInputValue('increase', 0.1, 'olhoDireitoDP', 'DP');"><p>+</p></button>
                </div>
                <div>
                  <button @click="changeInputValue('decrease', 0.1, 'olhoDireitoDP', 'DP')"><p>-</p></button>
                </div>
              </div>
            </div>
            <div class="custom-input eye-box-container">
              <div class="text-area type-2" @click="openOptions('AdicaoDireito')">
                <span>Adição</span>
                <div >
                  <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoDireitoAdicao" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id);" @blur="onBlurForm(form)">
                  <MultipleEyesOptions v-if="this.activeEyeBox ==='AdicaoDireito' && !readonly" :form="form" activeInput="AdicaoDireito" :setActiveInput="this.setActiveEyeBox" :options="optionsPositive"/>
                </div>
              </div>
              <div class="eyeValueChange" v-if="!readonly">
                <div>
                  <button @click="changeInputValue('increase', 0.25, 'olhoDireitoAdicao',  null);"><p>+</p></button>
                </div>
                <div>
                  <button @click="changeInputValue('decrease', 0.25, 'olhoDireitoAdicao', null)"><p>-</p></button>
                </div>
              </div>
            </div>
          </div>
          <div class="custom-input no-bb">
              <div class="eye-area">
              <EyeFill /> E
              </div>
            <div class="custom-input eye-box-container">
              <div class="text-area type-2 with-br" @click="openOptions('esfericoEsquerdo')">
                <div >
                  <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoEsquerdoEsferico" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id); " @blur="onBlurForm(form);">
                  <MultipleEyesOptions v-if="this.activeEyeBox ==='esfericoEsquerdo' && !readonly" :form="form" activeInput="esfericoEsquerdo" :setActiveInput="this.setActiveEyeBox" :options="form.fields.olhoEsquerdoEsferico ? (parseFloat(form.fields.olhoEsquerdoEsferico.replace(',', '.')) < 0.00 ? optionsNegative : optionsPositive) : optionsPositive" />
                </div>
              </div>
              <div class="eyeValueChange" v-if="!readonly">
                <div>
                  <button @click="changeInputValue('increase', 0.25, 'olhoEsquerdoEsferico', null);"><p>+</p></button>
                </div>
                <div>
                  <button @click="changeInputValue('decrease', 0.25, 'olhoEsquerdoEsferico', null)"><p>-</p></button>
                </div>
              </div>
            </div>
            <div class="custom-input eye-box-container">
              <div class="text-area type-2 with-br" @click="openOptions('cilindricoEsquerdo')">
                <div >
                  <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoEsquerdoCilindrico" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id);" @blur="onBlurForm(form)">
                  <MultipleEyesOptions v-if="this.activeEyeBox ==='cilindricoEsquerdo' && !readonly" :form="form" activeInput="cilindricoEsquerdo" :setActiveInput="this.setActiveEyeBox" :options="form.fields.olhoEsquerdoCilindrico !== null ? (parseFloat(form.fields.olhoEsquerdoCilindrico.replace(',', '.')) < 0.00 ? optionsNegative : optionsPositive) : optionsNegative"/>
                </div>
              </div>
              <div class="eyeValueChange" v-if="!readonly">
                <div>
                  <button @click="changeInputValue('increase', 0.25, 'olhoEsquerdoCilindrico', null);"><p>+</p></button>
                </div>
                <div>
                  <button @click="changeInputValue('decrease', 0.25, 'olhoEsquerdoCilindrico', null)"><p>-</p></button>
                </div>
              </div>
            </div>
            <div class="custom-input eye-box-container">
              <div class="text-area type-2 with-br" @click="openOptions('eixoEsquerdo')">
                <div >
                  <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoEsquerdoEixo" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id);" @blur="onBlurForm(form)">
                  <MultipleEyesOptions v-if="this.activeEyeBox ==='eixoEsquerdo' && !readonly" :form="form" activeInput="eixoEsquerdo" :setActiveInput="this.setActiveEyeBox" :options="optionsEixo"/>
                </div>
              </div>
              <div class="eyeValueChange" v-if="!readonly">
                <div>
                  <button @click="changeInputValue('increase', 1, 'olhoEsquerdoEixo', 'eixo');"><p>+</p></button>
                </div>
                <div>
                  <button @click="changeInputValue('decrease', 1, 'olhoEsquerdoEixo', 'eixo')"><p>-</p></button>
                </div>
              </div>
            </div>
            <div class="custom-input eye-box-container">
              <div class="text-area type-2 with-br" @click="openOptions('DPEsquerdo')">
                <div >
                  <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoEsquerdoDP" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id);" @blur="onBlurForm(form)">
                  <MultipleEyesOptions v-if="this.activeEyeBox ==='DPEsquerdo' && !readonly" :form="form" activeInput="DPEsquerdo" :setActiveInput="this.setActiveEyeBox" :options="optionsDP"/>
                </div>
              </div>
              <div class="eyeValueChange" v-if="!readonly">
                <div>
                  <button @click="changeInputValue('increase', 0.1, 'olhoEsquerdoDP', 'DP');"><p>+</p></button>
                </div>
                <div>
                  <button @click="changeInputValue('decrease', 0.1, 'olhoEsquerdoDP', 'DP')"><p>-</p></button>
                </div>
              </div>
            </div>
            <div class="custom-input eye-box-container">
              <div class="text-area type-2" @click="openOptions('AdicaoEsquerdo')">
                <div >
                  <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoEsquerdoAdicao" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id);" @blur="onBlurForm(form)">
                  <MultipleEyesOptions v-if="this.activeEyeBox ==='AdicaoEsquerdo' && !readonly" :form="form" activeInput="AdicaoEsquerdo" :setActiveInput="this.setActiveEyeBox" :options="optionsPositive"/>
                </div>
              </div>
              <div class="eyeValueChange" v-if="!readonly">
                <div>
                  <button @click="changeInputValue('increase', 0.25, 'olhoEsquerdoAdicao', null);"><p>+</p></button>
                </div>
                <div>
                  <button @click="changeInputValue('decrease', 0.25, 'olhoEsquerdoAdicao', null)"><p>-</p></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          v-if="!readonly"
          class="blue-underline"
          @click="copyEyeValues"
          @focus="setFormActive(form.id)"
          @blur="onBlurForm(form)">
          Copiar valores O.D para O.E
        </button>
      </b-col>
    </b-row>
  <b-row>
    <b-col class="use-refraction-container">
      <label for="use-static-refraction-lens" class="checkbox">
        <input
          type="checkbox"
          id="use-static-refraction-lens"
          @click="dispatchGetRefractionValues(!useStaticRefraction, 'Est')"
          v-model="useStaticRefraction"
          :disabled="readonly"
        />
        <span :class="`checkmark ${readonly ? 'checkmark-disabled' : ''}`">
            <Check />
        </span>
        Usar valores da refração estática
      </label>
      <label for="use-dynamic-refraction-lens" class="checkbox">
        <input
          type="checkbox"
          id="use-dynamic-refraction-lens"
          @click="dispatchGetRefractionValues(!useDynamicRefraction, 'Din')"
          v-model="useDynamicRefraction"
          :disabled="readonly"
        />
        <span :class="`checkmark ${readonly ? 'checkmark-disabled' : ''}`">
            <Check />
        </span>
        Usar valores da refração dinâmica
      </label>
    </b-col>
  </b-row>
  <b-row>
    <b-col class="guidance-container">
      <div class="form-group mb-0">
        <v-text-area
          id="lentes-de-contato-orientacao"
          :value="form.fields.orientacao"
          @focus="setFormActive(form.id)"
          @blur="!readonly && onBlurForm(form)"
          @inputTextArea="inputTextArea"
          :readonly="readonly"
          rows="1"
          placeholder="Insira orientações para a prescrição de lentes de contato"
        />
      </div>
    </b-col>
  </b-row>
</b-col>
</b-row>
</template>

<script>
import { EventBus } from '@/utils/eventBus'
import EyeFill from '@/assets/icons/eye-fill.svg'
import Check from '@/assets/icons/check.svg'
import MultipleEyesOptions from './MultipleEyesOptions.vue'
import TextArea from '@/components/General/TextArea'


export default {
  components: {
    EyeFill,
    Check,
    MultipleEyesOptions,
    'v-text-area': TextArea
  },
  props: {
    initialForm: Object,
    readonly: Boolean,
    setFormActive: { type: Function },
    onBlurForm: { type: Function },
    attendanceReview: Object,
  },
  data() {
    return {
      form: {
        fields: {},
        ...this.initialForm,
      },
      useDynamicRefraction: false,
      useStaticRefraction: false,
      activeEyeBox: String,
        optionsPositive: [['+0,25', '+0,50', '+0,75', '+1,00', '+1,25'], ['+1,50', '+1,75', '+2,00', '+2,25', '+2,50'], ['+2,75', '+3,00', '+3,25', '+3,50', '+3,75'], ['+4,00', '+4,25', '+4,50', '+4,75', '+5,00']],
        optionsNegative: [['-0,25', '-0,50', '-0,75', '-1,00', '-1,25'], ['-1,50', '-1,75', '-2,00', '-2,25', '-2,50'], ['-2,75', '-3,00', '-3,25', '-3,50', '-3,75'], ['-4,00', '-4,25', '-4,50', '-4,75', '-5,00']],
        optionsEixo: [['5º', '10º', '15º', '20º', '25º', '30º'], ['35º', '40º', '45º', '50º', '55º', '60º'], ['65º', '70º', '75º', '80º', '85º', '90º'], ['95º', '100º', '105º', '110º', '115º', '120º'], ['125º', '130º', '135º', '140º', '145º', '150º'], ['155º', '160º', '165º', '170º', '175º', '180º']],
        optionsDP: [['3,0', '3,5', '4,0', '4,5'], ['5,0', '5,5', '6,0', '6,5'], ['7,0', '7,5', '8,0', '8,5'], ['9,0', '9,5', '10,0', '10,5']]
    }
  },
  mounted() {
    if (!this.readonly) {
      this.onChange()
    }

    EventBus.$on('copyRefractionToContactLens', this.copyRefractionToContactLens);
  },
  methods: {
    openOptions(input, clickable){
        this.activeEyeBox === input ? this.activeEyeBox = null : this.activeEyeBox = input
    },
    copyEyeValues(){
      this.form.fields.olhoEsquerdoEsferico = this.form.fields.olhoDireitoEsferico
      this.form.fields.olhoEsquerdoCilindrico = this.form.fields.olhoDireitoCilindrico
      this.form.fields.olhoEsquerdoEixo = this.form.fields.olhoDireitoEixo
      this.form.fields.olhoEsquerdoAdicao = this.form.fields.olhoDireitoAdicao
      this.form.fields.olhoEsquerdoDP = this.form.fields.olhoDireitoDP
      this.onBlurForm(this.form);
    },
    setActiveEyeBox(BoxName){
      this.activeEyeBox = BoxName
    },
    changeInputValue(change, amount, value, type){
      if(this.form.fields[value] === '' || this.form.fields[value] === null){
        this.form.fields[value] = '0'
      }
      if(!(isNaN(parseFloat(this.form.fields[value])))){
          if(type === 'eixo'){
              if(change === 'increase'){
                this.form.fields[value] = ((parseFloat(this.form.fields[value].replace(',', '.')) + amount).toFixed(0)).replace('.', ',')

              } else if(change === 'decrease'){
                this.form.fields[value] = ((parseFloat(this.form.fields[value].replace(',', '.')) - amount).toFixed(0)).replace('.', ',')
              }
              if(parseFloat(this.form.fields[value].replace(',', '.')) > 0){
                this.form.fields[value] = this.form.fields[value]+'º'
              }
          }

          else if(type === 'DP'){
              if(change === 'increase'){
                this.form.fields[value] = ((parseFloat(this.form.fields[value].replace(',', '.')) + amount).toFixed(1)).replace('.', ',')

              } else if(change === 'decrease'){
                this.form.fields[value] = ((parseFloat(this.form.fields[value].replace(',', '.')) - amount).toFixed(1)).replace('.', ',')
              }
              if(parseFloat(this.form.fields[value].replace(',', '.')) > 0){
                this.form.fields[value] = this.form.fields[value]+''
              }
            }

          else if(!(isNaN(parseFloat(this.form.fields[value])))){
            if(change === 'increase'){
              this.form.fields[value] = ((parseFloat(this.form.fields[value].replace(',', '.')) + amount).toFixed(2)).replace('.', ',')

            } else if(change === 'decrease'){
              this.form.fields[value] = ((parseFloat(this.form.fields[value].replace(',', '.')) - amount).toFixed(2)).replace('.', ',')
            }
            if(parseFloat(this.form.fields[value].replace(',', '.')) > 0){
              this.form.fields[value] = '+'+this.form.fields[value]
            }
        }
        this.onBlurForm(this.form);
      }
    },
    onChange() {
      this.attendanceReview.prescricaoDeLentes = Object.keys(this.form.fields).some(key =>this.form.fields[key]);
    },
    dispatchGetRefractionValues(checked, sufix) {
      EventBus.$emit('getRefractionValues', checked && sufix, this.form.id);
    },
    copyRefractionToContactLens(fields, sufix) {
      this.clearRefractionFields();
      this.useStaticRefraction = sufix === 'Est';
      this.useDynamicRefraction = sufix === 'Din';
      this.form.fields.usarValoresRefracao = sufix;

      Object.keys(this.form.fields).map(key => {
        if (fields && fields[`${key}${sufix}`]) {
          this.form.fields[key] = fields[`${key}${sufix}`];
        }
      });
      this.onBlurForm(this.form);
    },
    clearRefractionFields() {
      this.form.fields.olhoDireitoEsferico = null;
      this.form.fields.olhoDireitoCilindrico = null;
      this.form.fields.olhoDireitoEixo = null;
      this.form.fields.olhoDireitoAdicao = null;
      this.form.fields.olhoDireitoDP = null;
      this.form.fields.olhoEsquerdoEsferico = null;
      this.form.fields.olhoEsquerdoCilindrico = null;
      this.form.fields.olhoEsquerdoEixo = null;
      this.form.fields.olhoEsquerdoAdicao = null;
      this.form.fields.olhoEsquerdoDP = null;
    },
    inputTextArea(value) {
      this.form.fields.orientacao = value
      this.onBlurForm(this.form);
    }
  },
  watch: {
    'form.fields': {
      handler() {
        this.onChange();
      },
      deep: true,
    },
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.fields = newValue.fields
        }
      },
      deep: true
    }
  },
}
</script>


<style lang="scss" scoped>
.eyeValueChange {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0 !important;
  position: relative;
  margin-left: -30px !important;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;

    button {
      height: 20px;
      width: 30px;
      color: var(--greys-60);
      background-color: #fff;
      position: relative !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 1.5px solid var(--blue-100) !important;

      p {
        font-weight: 700;
      }

    }
  }
}

.eye-box-container {
  .eyeValueChange {
    visibility: hidden;
  }

  &:hover {
    .eyeValueChange {
      visibility: visible;
    }
  }
}

.use-refraction-container {
  display: flex;
  margin-top: 6px;

  label:last-child {
    margin-left: 16px;
  }
}

.guidance-container {
  margin-top: 8px;
}
</style>
