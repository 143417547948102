<template>
  <div>
    <validation-observer
      :ref="this.form.id + '-form'"
      v-slot="{ invalid }"
      v-if="!readonly"
    >
      <b-row>
        <b-col cols="10">
          <div class="form-group">
            <validation-provider
              name="intercorrencias"
              :rules="{ required: true }"
              v-slot="{ errors, touched }"
            >
              <v-text-area
                id="intercorrencias"
                placeholder="Descrever"
                :touched="touched"
                :errors="errors"
                :value="form.fields.intercorrencias"
                @inputTextArea="inputTextArea"
                @focus.native="setFormActive(form.id)"
              />
            </validation-provider>
          </div>
        </b-col>
        <b-col cols="2">
          <b-button
            variant="primary"
            block
            class="h-38"
            :disabled="invalid"
            @click="saveItem"
          >
            Adicionar
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>

    <b-row>
      <b-col>
        <table class="table-eyecare">
          <thead>
            <tr>
              <th>Intercorrências</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, index) of form.value" :key="index">
              <td class="multiple-lines">{{ value.intercorrencias }}</td>
              <td class="text-right">
                <div class="more-with-menu">
                  <MoreVertical class="more-icon" />
                  <div class="menu">
                    <b-button
                      :disabled="readonly"
                      variant="link"
                      @click="editItem(value, index)"
                    >
                      Editar
                    </b-button>
                    <b-button
                      variant="link remove mb-0"
                      :disabled="readonly"
                      @click="removeItem(index)"
                    >
                      Remover
                    </b-button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TextArea from '@/components/General/TextArea'
import MoreVertical from '@/assets/icons/more-vertical.svg'

export default {
  name: 'Intercurrencies',
  props: {
    initialForm: Object,
    readonly: Boolean,
    setFormActive: Function
  },
  components: {
    'v-text-area': TextArea,
    MoreVertical
  },
  data() {
    return {
      form: {
        multiple: true,
        fields: {},
        value: [],
        editingIndex: null,
        ...this.initialForm
      }
    }
  },
  methods: {
    saveItem() {
      if (this.form.editingIndex !== null) {
        this.form.value.splice(this.form.editingIndex, 1)
      }
      this.form.value.push(this.form.fields)

      this.form.fields = {
        intercorrencias: null
      }
      this.form.editingIndex = null

      this.$emit('saveForm', this.form)
    },
    editItem(value, index) {
      this.form.fields = { ...value }
      this.form.editingIndex = index
    },
    removeItem(index) {
      this.form.value.splice(index, 1)
      this.$emit('saveForm', this.form)
    },
    inputTextArea(value) {
      this.form.fields.intercorrencias = value
    }
  },
  watch: {
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.value = newValue.value
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.multiple-lines {
  white-space: pre-line;
}
</style>
