<template>
  <b-row>
    <b-col>
      <div class="form-group">
        <input
          autocomplete="off"
          v-model="form.fields.coverTest"
          :readonly="readonly"
          @focus="setFormActive(form.id)"
          @blur="onBlurForm(form)"
          type="text"
          class="form-control"
          placeholder="Descrever"
        />
      </div>
      <div class="add-file">
        <label for="imageSelector-coverTest" class="file-input"
          ><Paperclip /> anexar arquivo</label
        >
        <input
          type="file"
          id="imageSelector-coverTest"
          ref="image"
          style="display: none"
          accept=".jpg, .png, .pdf"
          :disabled="readonly"
          @change="onFileChange($event, 'Cover Test')"
        />
        <div class="file-list">
          <p
            v-if="
              filesToUpload != null &&
              filesToUpload.find(element => element.group === 'Cover Test')
            "
          >
            Anexos:
          </p>
          <ul v-for="(file, index) of filesToUpload" :key="index">
            <li v-if="file.group == 'Cover Test'">
              <Pdf v-if="file.type == 'application/pdf'" class="icon" />
              <Files v-else class="icon" />
              <div class="attachment_file_name">{{ file.name }}</div>
              <CloseUploadedFile
                class="icon-close"
                @click="filesToUpload.splice(index, 1)"
              />
            </li>
          </ul>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import Paperclip from '@/assets/icons/paperclip2.svg'
import Pdf from '@/assets/icons/pdf.svg'
import Files from '@/assets/icons/files.svg'
import CloseUploadedFile from '@/assets/icons/closeUploadedFile.svg'

export default {
  name: 'CoverTest',
  props: {
    initialForm: Object,
    readonly: Boolean,
    setFormActive: Function,
    onBlurForm: Function,
    onFileChange: Function,
    filesToUpload: Array
  },
  components: {
    Paperclip,
    Pdf,
    Files,
    CloseUploadedFile
  },
  data() {
    return {
      form: {
        fields: {},
        ...this.initialForm,
      }
    }
  },
  watch: {
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.fields = newValue.fields
        }
      },
      deep: true
    }
  }
}
</script>
