<template>
  <b-row>
    <b-col cols="12">
      <div class="form-group">
        <label for="campo-visual-aparelho">Aparelho utilizado</label>
        <multiselect
          v-model="form.fields.aparelho"
          id="campo-visual-aparelho"
          :options="['Humphrey', 'Octupus']"
          :option-height="40"
          :showLabels="false"
          :searchable="false"
          :allowEmpty="false"
          placeholder="Selecionar"
          :disabled="readonly"
          class="with-border"
        >
          <template slot="caret">
            <div class="chevron">
              <ChevronDown />
            </div>
          </template>
          <template slot="noOptions"> Nenhuma opção </template>
          <template slot="noResult"> Nenhum resultado </template>
        </multiselect>
      </div>
    </b-col>

    <b-col cols="6">
      <div class="form-group mb-0">
        <div class="input-eye">
          <p class="tag-eye"><EyeFill /> D</p>
          <input
            autocomplete="off"
            v-model="form.fields.olhoDireito"
            :readonly="readonly"
            @focus="setFormActive(form.id)"
            @blur="onBlurForm(form)"
            id="campo-visual-direito"
            type="text"
            class="form-control"
            placeholder="Resultado"
          />
        </div>
        <button
          class="blue-underline"
          @click="copyEyeValues"
          v-if="!readonly"
          @focus="setFormActive(form.id)"
        >
          Copiar valores O.D para O.E
        </button>
      </div>
    </b-col>
    <b-col cols="6">
      <div class="form-group mb-0">
        <div class="input-eye">
          <p class="tag-eye"><EyeFill /> E</p>
          <input
            autocomplete="off"
            v-model="form.fields.olhoEsquerdo"
            :readonly="readonly"
            @focus="setFormActive(form.id)"
            @blur="onBlurForm(form)"
            id="campo-visual-esquerdo"
            type="text"
            class="form-control"
            placeholder="Resultado"
          />
        </div>
      </div>
    </b-col>
    <div class="add-file">
      <label for="imageSelector-campoVisual" class="file-input"
        ><Paperclip /> anexar arquivo</label
      >
      <input
        type="file"
        id="imageSelector-campoVisual"
        ref="image"
        style="display: none"
        accept=".jpg, .png, .pdf"
        :disabled="readonly"
        @change="onFileChange($event, 'Campo Visual')"
      />
      <div class="file-list">
        <p
          v-if="
            filesToUpload != null &&
            filesToUpload.find(element => element.group === 'Campo Visual')
          "
        >
          Anexos:
        </p>
        <ul v-for="(file, index) of filesToUpload" :key="index">
          <li v-if="file.group == 'Campo Visual'">
            <Pdf v-if="file.type == 'application/pdf'" class="icon" />
            <Files v-else class="icon" />
            <div class="attachment_file_name">{{ file.name }}</div>
            <CloseUploadedFile
              class="icon-close"
              @click="filesToUpload.splice(index, 1)"
            />
          </li>
        </ul>
      </div>
    </div>
  </b-row>
</template>

<script>
import Paperclip from '@/assets/icons/paperclip2.svg'
import Pdf from '@/assets/icons/pdf.svg'
import CloseUploadedFile from '@/assets/icons/closeUploadedFile.svg'
import Files from '@/assets/icons/files.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import EyeFill from '@/assets/icons/eye-fill.svg'

export default {
  name: 'VisualField',
  props: {
    initialForm: Object,
    readonly: Boolean,
    setFormActive: Function,
    onBlurForm: Function,
    onFileChange: Function,
    filesToUpload: Array
  },
  components: {
    Paperclip,
    Pdf,
    CloseUploadedFile,
    Files,
    ChevronDown,
    EyeFill
  },
  data() {
    return {
      form: {
        fields: {},
        ...this.initialForm
      }
    }
  },
  methods: {
    copyEyeValues() {
      this.form.fields.olhoEsquerdo = this.form.fields.olhoDireito;
      this.onBlurForm(this.form);
    }
  },
  watch: {
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.fields = newValue.fields
        }
      },
      deep: true
    }
  }
}
</script>
