<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <label for="atestado" class="checkbox">
            <input
              v-model="form.fields.atestado"
              type="checkbox"
              :disabled="readonly"
              @change="onBlurForm(form)"
              id="atestado"
            />
            <span :class="`checkmark ${readonly ? 'checkmark-disabled' : ''}`">
              <Check />
            </span>
            Atestado
          </label>
        </b-col>
      </b-row>
      <b-row v-if="form.fields.atestado">
        <b-col cols="auto">
          <div class="form-group">
            <label for="diasAtestado">Dias de atestado</label>
            <input
              autocomplete="off"
              v-model="form.fields.dias"
              id="diasAtestado"
              type="text"
              :readonly="readonly"
              class="form-control"
              @focus="setFormActive(form.id)"
              @blur="onBlurForm(form)"
            />
          </div>
        </b-col>
        <b-col>
          <div class="form-group">
            <label for="cidAtestado">CID</label>
            <multiselect
              v-model="form.fields.cid"
              id="cidAtestado"
              :options="cidOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :allowEmpty="false"
              placeholder="Selecionar"
              :disabled="readonly"
              class="with-border"
              :class="{ error: touched && errors.length > 0 && 1 === 2 }"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="declaracao-de-comparecimento" class="checkbox">
            <input
              v-model="form.fields.declaracaoDeComparecimento"
              type="checkbox"
              @change="onBlurForm(form)"
              :disabled="readonly"
              id="declaracao-de-comparecimento"
            />
            <span :class="`checkmark ${readonly ? 'checkmark-disabled' : ''}`">
              <Check />
            </span>
            Declaração de Comparecimento
          </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label
            for="declaracao-de-comparecimento-de-acompanhante"
            class="checkbox"
          >
            <input
              v-model="form.fields.declaracaoDeComparecimentoAcompanhante"
              type="checkbox"
              @change="onBlurForm(form)"
              :disabled="readonly"
              id="declaracao-de-comparecimento-de-acompanhante"
            />
            <span :class="`checkmark ${readonly ? 'checkmark-disabled' : ''}`">
              <Check />
            </span>
            Declaração de Comparecimento de Acompanhante
          </label>
        </b-col>
      </b-row>
      <b-row v-if="form.fields.declaracaoDeComparecimentoAcompanhante">
        <b-col cols="auto">
          <div class="form-group">
            <label for="nomeAcompanhante">Nome do acompanhante</label>
            <input
              autocomplete="off"
              v-model="form.fields.nomeAcompanhante"
              id="nomeAcompanhante"
              type="text"
              class="form-control"
              :readonly="readonly"
              @focus="setFormActive(form.id)"
              @blur="onBlurForm(form)"
            />
          </div>
        </b-col>
        <b-col cols="auto">
          <div class="form-group">
            <label for="cpfAcompanhante">CPF</label>
            <input
              autocomplete="off"
              id="cpfAcompanhante"
              type="text"
              v-model="form.fields.cpfAcompanhante"
              class="form-control"
              placeholder="000.000.000-00"
              v-mask="'###.###.###-##'"
              :readonly="readonly"
              @focus="setFormActive(form.id)"
              @blur="onBlurForm(form)"
            />
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import Check from '@/assets/icons/check.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'

export default {
  name: 'MedicalCertificates',
  props: {
    initialForm: Object,
    attendanceReview: Object,
    cidOptions: Array,
    readonly: Boolean,
    touched: Boolean,
    setFormActive: Function,
    onBlurForm: Function
  },
  components: {
    Check,
    ChevronDown
  },
  data() {
    return {
      form: {
        fields: {},
        ...this.initialForm
      }
    }
  },
  mounted() {
    if (!this.readonly) {
      this.onChange()
    }
  },
  methods: {
    onChange() {
      this.attendanceReview.atestado = this.form.fields.atestado
      this.attendanceReview.declaracaoDeComparecimento =
        this.form.fields.declaracaoDeComparecimento
      this.attendanceReview.declaracaoDeComparecimentoAcompanhante =
        this.form.fields.declaracaoDeComparecimentoAcompanhante
    }
  },
  watch: {
    'form.fields.atestado'() {
      this.onChange()
    },
    'form.fields.declaracaoDeComparecimento'() {
      this.onChange()
    },
    'form.fields.declaracaoDeComparecimentoAcompanhante'() {
      this.onChange()
    },
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.fields = newValue.fields
        }
      },
      deep: true
    }
  },
}
</script>
