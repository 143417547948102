<template>
  <b-row>
    <b-col>
      <div v-if="hasHtml() && readonly" v-html="form.fields.historicoDaDoencaAtual" class="form-group mb-0 break-spaces"></div>
      <div v-else class="form-group mb-0 break-spaces">
        <textarea
          v-model="form.fields.historicoDaDoencaAtual"
          :readonly="readonly"
          @focus="setFormActive(form.id)"
          @blur="!readonly && onBlurForm(form)"
          class="form-control"
          rows="5"
        ></textarea>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'CurrentIllness',
  props: {
    initialForm: Object,
    readonly: Boolean,
    setFormActive: Function,
    onBlurForm: Function
  },
  data() {
    return {
      form: {
        fields: {},
        ...this.initialForm
      }
    }
  },
  methods: {
    hasHtml() {
      return /<\/?[a-z][\s\S]*>/i.test(this.form.fields.historicoDaDoencaAtual);
    }
  },
  watch: {
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.fields = newValue.fields
        }
      },
      deep: true
    }
  }
}
</script>
