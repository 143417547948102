<template>
  <b-row>
    <b-col cols="6">
      <div class="form-group mb-0">
        <div class="input-eye">
          <p class="tag-eye"><EyeFill /> D</p>
          <input
            autocomplete="off"
            v-model="form.fields.olhoDireito"
            :readonly="readonly"
            @focus="setFormActive(form.id)"
            @blur="!readonly && onBlurForm(form)"
            id="gonioscopia-direito"
            type="text"
            class="form-control"
            placeholder="Resultado"
          />
        </div>
        <button
          class="blue-underline"
          @click="copyEyeValues"
          v-if="!readonly"
          @focus="setFormActive(form.id)"
        >
          Copiar valores O.D para O.E
        </button>
      </div>
    </b-col>
    <b-col cols="6">
      <div class="form-group mb-0">
        <div class="input-eye">
          <p class="tag-eye"><EyeFill /> E</p>
          <input
            autocomplete="off"
            v-model="form.fields.olhoEsquerdo"
            :readonly="readonly"
            @focus="setFormActive(form.id)"
            @blur="!readonly && onBlurForm(form)"
            id="gonioscopia-esquerdo"
            type="text"
            class="form-control"
            placeholder="Resultado"
          />
        </div>
      </div>
    </b-col>
    <div class="add-file">
      <label :for="`imageSelector-${type}`" class="file-input"
        ><Paperclip /> anexar arquivo</label
      >
      <input
        type="file"
        :id="`imageSelector-${type}`"
        ref="image"
        style="display: none"
        accept=".jpg, .png, .pdf"
        :disabled="readonly"
        @change="onFileChange($event, type)"
      />
      <div class="file-list">
        <p
          v-if="
            filesToUpload != null &&
            filesToUpload.find(element => element.group === type)
          "
        >
          Anexos:
        </p>
        <ul v-for="(file, index) of filesToUpload" :key="index">
          <li v-if="file.group == type">
            <Pdf v-if="file.type == 'application/pdf'" class="icon" />
            <Files v-else class="icon" />
            <div class="attachment_file_name">{{ file.name }}</div>
            <CloseUploadedFile
              class="icon-close"
              @click="filesToUpload.splice(index, 1)"
            />
          </li>
        </ul>
      </div>
    </div>
  </b-row>
</template>

<script>
import EyeFill from '@/assets/icons/eye-fill.svg'
import Paperclip from '@/assets/icons/paperclip2.svg'
import Pdf from '@/assets/icons/pdf.svg'
import Files from '@/assets/icons/files.svg'
import CloseUploadedFile from '@/assets/icons/closeUploadedFile.svg'

export default {
  name: 'EyeContainer',
  props: {
    initialForm: Object,
    readonly: Boolean,
    setFormActive: Function,
    onBlurForm: Function,
    onFileChange: Function,
    filesToUpload: Array,
    type: String
  },
  components: {
    EyeFill,
    Paperclip,
    Pdf,
    Files,
    CloseUploadedFile
  },
  data() {
    return {
      form: {
        fields: {},
        ...this.initialForm,
      }
    }
  },
   methods: {
    copyEyeValues() {
      this.form.fields.olhoEsquerdo = this.form.fields.olhoDireito;
      this.onBlurForm(this.form);
    }
  },
  watch: {
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.fields = newValue.fields
        }
      },
      deep: true
    }
  }
}
</script>
