<template>
  <b-row>
    <b-col>
      <validation-observer
        :ref="this.initialForm.id + '-form'"
        v-slot="{ invalid }"
        v-if="!readonly"
      >
        <b-row>
          <b-col cols="6">
            <div class="form-group">
              <label for="antecedente-pessoais">Antecedente</label>
              <validation-provider
                name="antecedentes"
                :rules="{ required: true }"
                v-slot="{ errors, touched }"
              >
                <multiselect
                  v-if="!form.fields.custom"
                  v-model="form.fields.antecedente"
                  id="antecedente-pessoais"
                  :options="antecedentesPessoais"
                  :option-height="40"
                  :showLabels="false"
                  :searchable="true"
                  :allowEmpty="false"
                  :showNoResults="true"
                  placeholder="Pesquisar antecedente"
                  class="search-mode with-border"
                  :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                >
                  <template slot="caret">
                    <div class="search">
                      <Search />
                    </div>
                  </template>
                  <template slot="noResult" slot-scope="props">
                    <li @click="setCustom(true, props.search)">
                      <div class="multiselect__option custom-item">
                        <Plus class="icon" />
                        Adicionar antecedente "{{ props.search }}"
                      </div>
                    </li>
                  </template>

                  <template slot="noOptions"> Nenhuma opção </template>
                </multiselect>
                <div class="custom-input" v-else>
                  <b-button variant="link" @click="setCustom(false)"
                    >Selecionar um item da lista</b-button
                  >
                  <input
                    autocomplete="off"
                    v-model="form.fields.antecedente"
                    :readonly="readonly"
                    type="text"
                    class="form-control"
                    placeholder=""
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                  />
                </div>
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="4">
            <div class="form-group">
              <label for="ano">Desde o ano</label>
              <validation-provider
                name="ano"
                :rules="{ required: false }"
                v-slot="{ errors, touched }"
              >
                <multiselect
                  v-model="form.fields.ano"
                  id="ano"
                  :options="sinceYears"
                  :option-height="40"
                  :showLabels="false"
                  :searchable="true"
                  :allowEmpty="false"
                  placeholder="Selecionar"
                  class="with-border"
                  :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                >
                  <perfect-scrollbar>
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>
                  </perfect-scrollbar>

                  <template slot="noOptions"> Nenhuma opção </template>

                  <template slot="noResult"> Nenhum resultado </template>
                </multiselect>
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="2">
            <b-button
              variant="primary"
              block
              class="h-38 mt-27"
              :disabled="invalid"
              @click="saveItem"
              >Adicionar</b-button
            >
          </b-col>
        </b-row>
      </validation-observer>
      <b-row>
        <b-col>
          <table class="table-eyecare">
            <thead>
              <tr>
                <th width="51%">Nome</th>
                <th>Ano</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, index) of form.value" :key="index">
                <td>{{ value.antecedente }}</td>
                <td>{{ value.ano }}</td>
                <td class="text-right">
                  <div class="more-with-menu">
                    <MoreVertical class="more-icon" />
                    <div class="menu">
                      <b-button
                        variant="link"
                        @click="editItem(value, index)"
                        :disabled="readonly"
                      >
                        Editar
                      </b-button>
                      <b-button
                        variant="link remove mb-0"
                        @click="removeItem(index)"
                        :disabled="readonly"
                      >
                        Remover
                      </b-button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import Search from '@/assets/icons/search.svg'
import Plus from '@/assets/icons/plus.svg'
import MoreVertical from '@/assets/icons/more-vertical.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'

export default {
  name: 'PersonalBackground',
  props: {
    initialForm: Object,
    readonly: Boolean,
    antecedentesPessoais: Array
  },
  data() {
    return {
      sinceYears: [],
      form: {
        fields: {},
        value: [],
        editingIndex: null,
        ...this.initialForm
      }
    }
  },
  components: {
    Search,
    MoreVertical,
    ChevronDown,
    Plus
  },
  mounted() {
    for (let i = new Date().getFullYear(); i >= 1940; i--) {
      this.sinceYears.push(i)
    }
  },
  methods: {
    setCustom(value, searchTerm) {
      this.form.fields.custom = value
      this.form.fields.antecedente = value ? searchTerm : null
    },
    saveItem() {
      const antecedentes = this.form.value.map(value => value.antecedente.toLowerCase())
      if (antecedentes.includes(this.form.fields.antecedente.toLowerCase()) && this.form.editingIndex === null) {
        this.$toast.warning('Antecedente pessoal já adicionado')
        return
      }
      if (this.form.editingIndex !== null) {
        this.form.value.splice(this.form.editingIndex, 1)
      }

      this.form.value.push(this.form.fields)

      this.form.fields = {
        custom: false,
        antecedente: null,
        ano: null
      }
      this.form.editingIndex = null

      this.$emit('saveForm', this.form)
    },
    editItem(value, index) {
      this.form.fields = { ...value }
      this.form.editingIndex = index
    },
    removeItem(index) {
      const removed = this.form.value.splice(index, 1)
      this.$emit('saveForm', this.form, removed);
    }
  },
  watch: {
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.value = newValue.value
        }
      },
      deep: true
    }
  }
}
</script>
