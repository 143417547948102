<template>
  <b-row>
    <b-col>
      <validation-observer
        :ref="this.form.id + '-form'"
        v-slot="{ invalid }"
        v-if="!readonly"
      >
        <b-row>
          <b-col cols="10">
            <div class="form-group">
              <validation-provider
                name="encaminhamento"
                rules="required"
                v-slot="{ touched, errors }"
              >
                <multiselect
                  v-if="!form.fields.custom"
                  v-model="form.fields.encaminhamento"
                  :options="encaminhamento"
                  :option-height="40"
                  :showLabels="false"
                  :searchable="true"
                  :allowEmpty="false"
                  :showNoResults="true"
                  placeholder="Digite o nome da especialidade/especialista"
                  class="search-mode with-border"
                  :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                >
                  <template slot="caret">
                    <div class="search">
                      <Search />
                    </div>
                  </template>
                  <template slot="noResult" slot-scope="props">
                    <li @click="setCustom(true, props.search)">
                      <div class="multiselect__option custom-item">
                        <Plus class="icon" />
                        Adicionar encaminhamento "{{ props.search }}"
                      </div>
                    </li>
                  </template>

                  <template slot="noOptions"> Nenhuma opção </template>
                </multiselect>
                <div class="custom-input" v-else>
                  <b-button variant="link" @click="setCustom(false)"
                    >Selecionar um item da lista</b-button
                  >
                  <input
                    autocomplete="off"
                    v-model="form.fields.encaminhamento"
                    :readonly="readonly"
                    type="text"
                    class="form-control"
                    placeholder=""
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                  />
                </div>
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="2">
            <b-button
              variant="primary"
              block
              class="h-38"
              :disabled="invalid"
              @click="addItem"
              >Adicionar</b-button
            >
          </b-col>
        </b-row>
      </validation-observer>
      <b-row>
        <b-col>
          <table class="table-eyecare">
            <thead>
              <tr>
                <th>Especialidade</th>
                <th width="40%">Comentário</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, index) of form.value" :key="index">
                <td>{{ value.encaminhamento }}</td>
                <td>
                  <div v-if="value.editing !== true">
                    <b-button
                      variant="link"
                      v-if="value.comentario === null"
                      class="p-0"
                      @click="editItem(index)"
                      >Adicionar comentário</b-button
                    >
                    {{ value.comentario }}
                  </div>

                  <div class="text-right" v-else>
                    <textarea
                      v-model="value.comentario"
                      rows="3"
                      class="form-control"
                    ></textarea>
                    <b-button
                      variant="primary"
                      class="mt-8"
                      @click="saveItem(index)"
                      >Salvar comentário</b-button
                    >
                  </div>
                </td>
                <td class="text-right">
                  <div class="more-with-menu">
                    <MoreVertical class="more-icon" />
                    <div class="menu">
                      <b-button variant="link" @click="editItem(index)"
                        >Editar</b-button
                      >
                      <b-button
                        variant="link remove mb-0"
                        @click="removeItem(index)"
                        >Remover</b-button
                      >
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import MoreVertical from '@/assets/icons/more-vertical.svg'
import Plus from '@/assets/icons/plus.svg'
import Search from '@/assets/icons/search.svg'

export default {
  name: 'Forwarding',
  props: {
    initialForm: Object,
    attendanceReview: Object,
    readonly: Boolean,
    encaminhamento: Array
  },
  components: {
    MoreVertical,
    Plus,
    Search
  },
  data() {
    return {
      form: {
        fields: {},
        value: [],
        ...this.initialForm
      }
    }
  },
  mounted() {
    if (!this.readonly) {
      this.onChange()
    }
  },
  methods: {
    setCustom(value, searchTerm) {
      this.form.fields.custom = value
      this.form.fields.encaminhamento = value ? searchTerm : null
    },
    addItem() {
      this.form.value.push(this.form.fields)

      this.form.fields = {
        custom: false,
        encaminhamento: null,
        comentario: null
      }

      this.$emit('saveForm', this.form)
      this.onChange()
    },
    saveItem(index) {
      this.form.value = this.form.value.map((opt, i) => {
        if (i === index) {
          delete opt.editing
        }

        return opt
      })

      this.$emit('saveForm', this.form)
      this.onChange()
    },
    editItem(index) {
      this.form.value = this.form.value.map((opt, i) => {
        if (i === index) {
          opt.editing = true
        }

        return opt
      })
    },
    removeItem(index) {
      this.form.value.splice(index, 1)
      this.$emit('saveForm', this.form)
      this.onChange()
    },
    onChange() {
      this.attendanceReview.encaminhamento = this.form.value.length > 0
    }
  },
  watch: {
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.value = newValue.value
        }
      },
      deep: true
    }
  }
}
</script>
