<template>
  <b-row>
    <b-col cols="4">
      <div class="form-group">
        <label for="avcc-direito">Olho direito</label>
        <div class="input-eye">
          <p class="tag-eye"><EyeFill /> D</p>
          <input
            autocomplete="off"
            id="avcc-direito"
            v-model="form.fields.olhoDireito"
            :readonly="readonly" :disabled="readonly"
            @focus="setFormActive(form.id)"
            @blur="!readonly && onBlurForm(form)"
            @click="setActiveForm('avccDireito')"
            type="text"
            class="form-control"
            placeholder="Resultado"
          />
        </div>
        <Options
          :form="form"
          :options="options"
          activeInput="avccDireito"
          :setActiveInput="this.setActiveForm"
          v-if="activeForm === 'avccDireito' && !readonly"
        />
        <button
          class="blue-underline"
          @click="copyEyeValues"
          v-if="!readonly"
          @focus="setFormActive(form.id)"
        >
          Copiar valores O.D para O.E
        </button>
      </div>
    </b-col>
    <b-col cols="4">
      <div class="form-group">
        <label for="avcc-esquerdo">Olho esquerdo</label>
        <div class="input-eye">
          <p class="tag-eye"><EyeFill /> E</p>
          <input
            autocomplete="off"
            id="avcc-esquerdo"
            v-model="form.fields.olhoEsquerdo"
            :readonly="readonly" :disabled="readonly"
            @focus="setFormActive(form.id)"
            @blur="!readonly && onBlurForm(form)"
            @click="setActiveForm('avccEsquerdo')"
            type="text"
            class="form-control"
            placeholder="Resultado"
          />
        </div>
        <Options
          :form="form"
          :options="options"
          activeInput="avccEsquerdo"
          :setActiveInput="this.setActiveForm"
          v-if="activeForm === 'avccEsquerdo' && !readonly"
        />
      </div>
    </b-col>
    <b-col cols="12">
      <label for="possui-autorrefrator" class="checkbox">
        <input
          type="checkbox"
          id="possui-autorrefrator"
          :disabled="readonly"
          v-model="form.fields.possuiAutorrefrator"
          @change="onBlurForm(form)"
        />
        <span :class="`checkmark ${readonly ? 'checkmark-disabled' : ''}`">
          <Check />
        </span>
        Possui autorrefrator
      </label>
    </b-col>
    <b-col cols="5">
      <b-row>
        <b-col cols="4">
          <div class="form-group mb-1">
            <label for="avcc-direito-esferico">Esférico</label>
            <input
              autocomplete="off"
              style="text-align: center"
              id="avcc-direito-esferico"
              v-model="form.fields.olhoDireitoEsferico"
              :readonly="readonly || !form.fields.possuiAutorrefrator"
              :disabled="readonly"
              @focus="setFormActive(form.id)"
              @blur="!readonly && onBlurForm(form)"
              type="text"
              class="form-control small"
            />
          </div>
        </b-col>
        <b-col cols="4">
          <div class="form-group mb-1">
            <label for="avcc-direito-cilindrico">Cilíndrico</label>
            <input
              autocomplete="off"
              style="text-align: center"
              id="avcc-direito-cilindrico"
              v-model="form.fields.olhoDireitoCilindrico"
              :readonly="readonly || !form.fields.possuiAutorrefrator"
              :disabled="readonly"
              @focus="setFormActive(form.id)"
              @blur="!readonly && onBlurForm(form)"
              type="text"
              class="form-control small"
            />
          </div>
        </b-col>
        <b-col cols="4">
          <div class="form-group mb-1">
            <label for="avcc-direito-eixo">Eixo</label>
            <input
              autocomplete="off"
              style="text-align: center"
              id="avcc-direito-eixo"
              v-model="form.fields.olhoDireitoEixo"
              :readonly="readonly || !form.fields.possuiAutorrefrator"
              :disabled="readonly"
              @focus="setFormActive(form.id)"
              @blur="!readonly && onBlurForm(form)"
              type="text"
              class="form-control small"
            />
          </div>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="5">
      <b-row>
        <b-col cols="4">
          <div class="form-group mb-0">
            <label for="avcc-esquerdo-esferico">Esférico</label>
            <input
              autocomplete="off"
              style="text-align: center"
              id="avcc-esquerdo-esferico"
              v-model="form.fields.olhoEsquerdoEsferico"
              :readonly="readonly || !form.fields.possuiAutorrefrator"
              :disabled="readonly"
              @focus="setFormActive(form.id)"
              @blur="!readonly && onBlurForm(form)"
              type="text"
              class="form-control small"
            />
          </div>
        </b-col>
        <b-col cols="4">
          <div class="form-group mb-0">
            <label for="avcc-esquerdo-cilindrico">Cilíndrico</label>
            <input
              autocomplete="off"
              style="text-align: center"
              id="avcc-esquerdo-cilindrico"
              v-model="form.fields.olhoEsquerdoCilindrico"
              :readonly="readonly || !form.fields.possuiAutorrefrator"
              :disabled="readonly"
              @focus="setFormActive(form.id)"
              @blur="!readonly && onBlurForm(form)"
              type="text"
              class="form-control small"
            />
          </div>
        </b-col>
        <b-col cols="4">
          <div class="form-group mb-0">
            <label for="avcc-esquerdo-eixo">Eixo</label>
            <input
              autocomplete="off"
              style="text-align: center"
              id="avcc-esquerdo-eixo"
              v-model="form.fields.olhoEsquerdoEixo"
              :readonly="readonly || !form.fields.possuiAutorrefrator"
              :disabled="readonly"
              @focus="setFormActive(form.id)"
              @blur="!readonly && onBlurForm(form)"
              type="text"
              class="form-control small"
            />
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import EyeFill from '@/assets/icons/eye-fill.svg'
import Check from '@/assets/icons/check.svg'
import MultipleEyesOptions from './MultipleEyesOptions.vue'

export default {
  name: 'Avcc',
  props: {
    initialForm: Object,
    readonly: Boolean,
    setFormActive: Function,
    onBlurForm: Function,
  },
  components: {
    EyeFill,
    Check,
    Options: MultipleEyesOptions
  },
  data() {
    return {
      form: {
        fields: {},
        ...this.initialForm
      },
      activeForm: String,
      options: [
        ['20/10', '20/15', '20/20', '20/25', '20/30'],
        ['20/40', '20/50', '20/70', '20/100', '20/200'],
        ['20/400', 'CD 3m', 'CD 2m', 'CD 1m', 'CD 0,5m'],
        ['MM', 'PL', 'SPL']
      ]
    }
  },
  methods: {
    setActiveForm(FormName) {
      this.activeForm = FormName
    },
    copyEyeValues() {
      this.form.fields.olhoEsquerdo = this.form.fields.olhoDireito;
      this.onBlurForm(this.form);
    }
  },
  watch: {
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.fields = newValue.fields
        }
      },
      deep: true
    }
  }
}
</script>
