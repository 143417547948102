<template >
<b-row >
<b-col>
    <b-row >
      <b-col cols="12" >
        <div class="custom-input-group" >
          <div class="custom-input">
              <div class="eye-area" >
                <EyeFill /> D
              </div>
              <div class="custom-input eye-box-container">
                <div class="text-area type-2 with-br" @click="openOptions('esfericoDireito')">
                  <span >Esférico</span>
                  <div >
                    <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoDireitoEsferico" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id);" @blur="onBlurForm(form);">
                    <MultipleEyesOptions v-if="this.activeEyeBox ==='esfericoDireito' && !readonly" :form="form" activeInput="esfericoDireito" :setActiveInput="this.setActiveEyeBox" :options="form.fields.olhoDireitoEsferico ? (parseFloat(form.fields.olhoDireitoEsferico.replace(',', '.')) < 0.00 ? optionsNegative : optionsPositive) : optionsPositive" />
                  </div>
                </div>
                <div class="eyeValueChange" v-if="!readonly">
                  <div>
                    <button @click="changeInputValue('increase', 0.25, 'olhoDireitoEsferico', null);"><p>+</p></button>
                  </div>
                  <div>
                    <button @click="changeInputValue('decrease', 0.25, 'olhoDireitoEsferico', null)"><p>-</p></button>
                  </div>
                </div>
              </div>

            <div class="custom-input eye-box-container">
              <div class="text-area type-2 with-br" @click="openOptions('cilindricoDireito')">
                <span>Cilíndrico</span>
                <div >
                  <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoDireitoCilindrico" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id);" @blur="onBlurForm(form)">
                  <MultipleEyesOptions v-if="this.activeEyeBox ==='cilindricoDireito' && !readonly" :form="form" activeInput="cilindricoDireito" :setActiveInput="this.setActiveEyeBox" :options="form.fields.olhoDireitoCilindrico !== null ? (parseFloat(form.fields.olhoDireitoCilindrico.replace(',', '.')) < 0.00 ? optionsNegative : optionsPositive) : optionsNegative"/>
                </div>
              </div>
              <div class="eyeValueChange" v-if="!readonly">
                <div>
                  <button @click="changeInputValue('increase', 0.25, 'olhoDireitoCilindrico', null);"><p>+</p></button>
                </div>
                <div>
                  <button @click="changeInputValue('decrease', 0.25, 'olhoDireitoCilindrico', null)"><p>-</p></button>
                </div>
              </div>
            </div>

            <div class="custom-input eye-box-container">
              <div class="text-area type-2 with-br" @click="openOptions('eixoDireito')">
                <span>Eixo</span>
                <div >
                  <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoDireitoEixo" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id);" @blur="onBlurForm(form)">
                  <MultipleEyesOptions v-if="this.activeEyeBox ==='eixoDireito' && !readonly" :form="form" activeInput="eixoDireito" :setActiveInput="this.setActiveEyeBox" :options="optionsEixo"/>
                </div>
              </div>
              <div class="eyeValueChange" v-if="!readonly">
                <div>
                  <button @click="changeInputValue('increase', 1, 'olhoDireitoEixo', 'eixo');"><p>+</p></button>
                </div>
                <div>
                  <button @click="changeInputValue('decrease', 1, 'olhoDireitoEixo', 'eixo')"><p>-</p></button>
                </div>
              </div>
            </div>
            <div class="custom-input eye-box-container">
              <div class="text-area type-2 with-br" @click="openOptions('DPDireito')">
                <span>D.P.</span>
                <div >
                  <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoDireitoDP" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id);" @blur="onBlurForm(form)">
                  <MultipleEyesOptions v-if="this.activeEyeBox ==='DPDireito' && !readonly" :form="form" activeInput="DPDireito" :setActiveInput="this.setActiveEyeBox" :options="optionsDP"/>
                </div>
              </div>
              <div class="eyeValueChange" v-if="!readonly">
                <div>
                  <button @click="changeInputValue('increase', 0.1, 'olhoDireitoDP', 'DP');"><p>+</p></button>
                </div>
                <div>
                  <button @click="changeInputValue('decrease', 0.1, 'olhoDireitoDP', 'DP')"><p>-</p></button>
                </div>
              </div>
            </div>
            <div class="custom-input eye-box-container">
              <div class="text-area type-2" @click="openOptions('AdicaoDireito')">
                <span>Adição</span>
                <div >
                  <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoDireitoAdicao" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id);" @blur="onBlurForm(form)">
                  <MultipleEyesOptions v-if="this.activeEyeBox ==='AdicaoDireito' && !readonly" :form="form" activeInput="AdicaoDireito" :setActiveInput="this.setActiveEyeBox" :options="optionsPositive"/>
                </div>
              </div>
              <div class="eyeValueChange" v-if="!readonly">
                <div>
                  <button @click="changeInputValue('increase', 0.25, 'olhoDireitoAdicao',  null);"><p>+</p></button>
                </div>
                <div>
                  <button @click="changeInputValue('decrease', 0.25, 'olhoDireitoAdicao', null)"><p>-</p></button>
                </div>
              </div>
            </div>
          </div>
          <div class="custom-input no-bb">
              <div class="eye-area">
              <EyeFill /> E
              </div>
            <div class="custom-input eye-box-container">
              <div class="text-area type-2 with-br" @click="openOptions('esfericoEsquerdo')">
                <div >
                  <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoEsquerdoEsferico" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id); " @blur="onBlurForm(form);">
                  <MultipleEyesOptions v-if="this.activeEyeBox ==='esfericoEsquerdo' && !readonly" :form="form" activeInput="esfericoEsquerdo" :setActiveInput="this.setActiveEyeBox" :options="form.fields.olhoEsquerdoEsferico ? (parseFloat(form.fields.olhoEsquerdoEsferico.replace(',', '.')) < 0.00 ? optionsNegative : optionsPositive) : optionsPositive" />
                </div>
              </div>
              <div class="eyeValueChange" v-if="!readonly">
                <div>
                  <button @click="changeInputValue('increase', 0.25, 'olhoEsquerdoEsferico', null);"><p>+</p></button>
                </div>
                <div>
                  <button @click="changeInputValue('decrease', 0.25, 'olhoEsquerdoEsferico', null)"><p>-</p></button>
                </div>
              </div>
            </div>
            <div class="custom-input eye-box-container">
              <div class="text-area type-2 with-br" @click="openOptions('cilindricoEsquerdo')">
                <div >
                  <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoEsquerdoCilindrico" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id);" @blur="onBlurForm(form)">
                  <MultipleEyesOptions v-if="this.activeEyeBox ==='cilindricoEsquerdo' && !readonly" :form="form" activeInput="cilindricoEsquerdo" :setActiveInput="this.setActiveEyeBox" :options="form.fields.olhoEsquerdoCilindrico !== null ? (parseFloat(form.fields.olhoEsquerdoCilindrico.replace(',', '.')) < 0.00 ? optionsNegative : optionsPositive) : optionsNegative"/>
                </div>
              </div>
              <div class="eyeValueChange" v-if="!readonly">
                <div>
                  <button @click="changeInputValue('increase', 0.25, 'olhoEsquerdoCilindrico', null);"><p>+</p></button>
                </div>
                <div>
                  <button @click="changeInputValue('decrease', 0.25, 'olhoEsquerdoCilindrico', null)"><p>-</p></button>
                </div>
              </div>
            </div>
            <div class="custom-input eye-box-container">
              <div class="text-area type-2 with-br" @click="openOptions('eixoEsquerdo')">
                <div >
                  <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoEsquerdoEixo" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id);" @blur="onBlurForm(form)">
                  <MultipleEyesOptions v-if="this.activeEyeBox ==='eixoEsquerdo' && !readonly" :form="form" activeInput="eixoEsquerdo" :setActiveInput="this.setActiveEyeBox" :options="optionsEixo"/>
                </div>
              </div>
              <div class="eyeValueChange" v-if="!readonly">
                <div>
                  <button @click="changeInputValue('increase', 1, 'olhoEsquerdoEixo', 'eixo');"><p>+</p></button>
                </div>
                <div>
                  <button @click="changeInputValue('decrease', 1, 'olhoEsquerdoEixo', 'eixo')"><p>-</p></button>
                </div>
              </div>
            </div>
            <div class="custom-input eye-box-container">
              <div class="text-area type-2 with-br" @click="openOptions('DPEsquerdo')">
                <div >
                  <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoEsquerdoDP" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id);" @blur="onBlurForm(form)">
                  <MultipleEyesOptions v-if="this.activeEyeBox ==='DPEsquerdo' && !readonly" :form="form" activeInput="DPEsquerdo" :setActiveInput="this.setActiveEyeBox" :options="optionsDP"/>
                </div>
              </div>
              <div class="eyeValueChange" v-if="!readonly">
                <div>
                  <button @click="changeInputValue('increase', 0.1, 'olhoEsquerdoDP', 'DP');"><p>+</p></button>
                </div>
                <div>
                  <button @click="changeInputValue('decrease', 0.1, 'olhoEsquerdoDP', 'DP')"><p>-</p></button>
                </div>
              </div>
            </div>
            <div class="custom-input eye-box-container">
              <div class="text-area type-2" @click="openOptions('AdicaoEsquerdo')">
                <div >
                  <input autocomplete="off" type="text" class="form-control text-center" v-model="form.fields.olhoEsquerdoAdicao" :readonly="readonly" :disabled="readonly" @focus="setFormActive(form.id);" @blur="onBlurForm(form)">
                  <MultipleEyesOptions v-if="this.activeEyeBox ==='AdicaoEsquerdo' && !readonly" :form="form" activeInput="AdicaoEsquerdo" :setActiveInput="this.setActiveEyeBox" :options="optionsPositive"/>
                </div>
              </div>
              <div class="eyeValueChange" v-if="!readonly">
                <div>
                  <button @click="changeInputValue('increase', 0.25, 'olhoEsquerdoAdicao', null);"><p>+</p></button>
                </div>
                <div>
                  <button @click="changeInputValue('decrease', 0.25, 'olhoEsquerdoAdicao', null)"><p>-</p></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button class="blue-underline" @click="copyEyeValues" v-if="!readonly" @focus="setFormActive(form.id)" @blur="onBlurForm(form)">Copiar valores O.D para O.E</button>
      </b-col>
    </b-row>
  <b-row>
    <b-col class="use-refraction-container">
      <label for="use-static-refraction" class="checkbox">
        <input
          type="checkbox"
          id="use-static-refraction"
          @click="dispatchGetRefractionValues(!useStaticRefraction, 'Est')"
          v-model="useStaticRefraction"
          :disabled="readonly"
        />
        <span :class="`checkmark ${readonly ? 'checkmark-disabled' : ''}`">
            <Check />
        </span>
        Usar valores da refração estática
      </label>
      <label for="use-dynamic-refraction" class="checkbox">
        <input
          type="checkbox"
          id="use-dynamic-refraction"
          @click="dispatchGetRefractionValues(!useDynamicRefraction, 'Din')"
          v-model="useDynamicRefraction"
          :disabled="readonly"
        />
        <span :class="`checkmark ${readonly ? 'checkmark-disabled' : ''}`">
            <Check />
        </span>
        Usar valores da refração dinâmica
      </label>
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <label for="materia-prima-lente">Matéria-prima da lente</label>
      <multiselect
        v-model="form.fields.materiaPrima"
        id="materia-prima-lente"
        :options="opcoesMateriaPrima"
        :option-height="40"
        :showLabels="false"
        :showNoResults="true"
        :disabled="readonly"
        @select="onBlurForm(form)"
        placeholder="Selecionar"
        class="with-border"
      >
        <template slot="caret">
          <div class="chevron">
            <ChevronDown />
          </div>
        </template>
        <template slot="noResult" slot-scope="props">
          <li @click="setMateriaPrima(props.search)">
            <div class="multiselect__option custom-item">
              <Plus class="icon" />
              Adicionar "{{ props.search }}"
            </div>
          </li>
        </template>
      </multiselect>
    </b-col>
    <b-col id="tratamento-lente-selecionado">
      <label for="tratamento-lente">Tratamento da lente</label>
        <multiselect
          v-model="form.fields.tratamentoLente"
          id="tratamento-lente"
          ref="tratamentoLente"
          :options="opcoesTratamentoLente"
          :option-height="40"
          :searchable="false"
          :close-on-select="false"
          :showLabels="false"
          :multiple="true"
          @select="onBlurForm(form)"
          @remove="onBlurForm(form)"
          :disabled="readonly"
          placeholder="Selecionar"
          class="with-border multiple"
        >
          <template slot="caret">
            <div class="chevron">
              <ChevronDown />
            </div>
          </template>
          <template slot="selection" slot-scope="{ values }">
            <div class="treatment">
              {{ values.join(', ') }}
            </div>
          </template>
        </multiselect>
        <b-tooltip v-if="readonly && form.fields.tratamentoLente && form.fields.tratamentoLente.length" target="tratamento-lente-selecionado" triggers="hover" placement="bottom">
          <div
            v-for="value in form.fields.tratamentoLente"
            :key="value"
            class="treatment-tooltip">
            {{ value }}
          </div>
        </b-tooltip>
    </b-col>
    <b-col>
      <label for="tipo-lente">Tipo de lente</label>
      <multiselect
        v-model="form.fields.tipoLente"
        id="tipo-lente"
        :options="opcoesTipoLente"
        :option-height="40"
        :showLabels="false"
        :showNoResults="true"
        @select="onBlurForm(form)"
        :disabled="readonly"
        placeholder="Selecionar"
        class="with-border"
      >
        <template slot="caret">
          <div class="chevron">
            <ChevronDown />
          </div>
        </template>
        <template slot="noResult" slot-scope="props">
          <li @click="setTipoLente(props.search)">
            <div class="multiselect__option custom-item">
              <Plus class="icon" />
              Adicionar "{{ props.search }}"
            </div>
          </li>
        </template>
      </multiselect>
    </b-col>
  </b-row>
  <b-row>
    <b-col class="guidance-container">
      <div class="form-group mb-0">
        <v-text-area
          id="oculos-orientacao"
          :value="form.fields.orientacao"
          @focus="setFormActive(form.id)"
          @blur="!readonly && onBlurForm(form)"
          @inputTextArea="inputTextArea"
          :readonly="readonly"
          rows="1"
          placeholder="Insira mais informações para a prescrição de óculos"
        />
      </div>
    </b-col>
  </b-row>
  <!-- <b-row>
    <b-col>
      <label for="recommended-brand" class="checkbox">
          <input
            type="checkbox"
            id="recommended-brand"
            v-model="marcaRecomendada"
            @change="setMarcaRecomendada"
            :disabled="readonly"
          />
          <span :class="`checkmark ${readonly ? 'checkmark-disabled' : ''}`">
              <Check />
          </span>
          Recomendar lentes ZEISS
        </label>
    </b-col>
  </b-row> -->
</b-col>
</b-row>
</template>

<script>
import { EventBus } from '@/utils/eventBus'
import EyeFill from '@/assets/icons/eye-fill.svg'
import Check from '@/assets/icons/check.svg'
import MultipleEyesOptions from './MultipleEyesOptions.vue'
import TextArea from '@/components/General/TextArea'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import Plus from '@/assets/icons/plus.svg'

export default {
  name: 'Glasses',
  components: {
    EyeFill,
    Check,
    MultipleEyesOptions,
    'v-text-area': TextArea,
    ChevronDown,
    Plus,
  },
  props: {
    initialForm: Object,
    readonly: Boolean,
    setFormActive: { type: Function },
    onBlurForm: { type: Function },
    attendanceReview: Object,
  },
  data() {
    const opcoesTipoLente = ['Para Longe', 'Para Perto', 'Multifocal', 'Multifocal Intermediário/Perto',
        'Bifocal', 'Longe e perto separados'];
    if (this.initialForm.fields.tipoLente && !opcoesTipoLente.includes(this.initialForm.fields.tipoLente)) {
      opcoesTipoLente.push(this.initialForm.fields.tipoLente);
    }

    const opcoesMateriaPrima = ['Resina', 'Policarbonato', 'Alto Índice', 'Trivex'];
    if (this.initialForm.fields.materiaPrima && !opcoesMateriaPrima.includes(this.initialForm.fields.materiaPrima)) {
      opcoesMateriaPrima.push(this.initialForm.fields.materiaPrima);
    }

    return {
      form: {
        fields: {},
        ...this.initialForm,
      },
      opcoesTratamentoLente: ['Antirreflexo', 'Antirrisco', 'Filtro de luz azul',
        'Fotossensível', 'Proteção UV', 'Antiembaçante', 'Antiengordurante', 'Antivírus'],
      opcoesTipoLente,
      opcoesMateriaPrima,
      useDynamicRefraction: this.initialForm.fields.usarValoresRefracao === 'Din',
      useStaticRefraction: this.initialForm.fields.usarValoresRefracao === 'Est',
      marcaRecomendada: !!this.initialForm.fields.marcaRecomendada,
      activeEyeBox: String,
        optionsPositive: [['+0,25', '+0,50', '+0,75', '+1,00', '+1,25'], ['+1,50', '+1,75', '+2,00', '+2,25', '+2,50'], ['+2,75', '+3,00', '+3,25', '+3,50', '+3,75'], ['+4,00', '+4,25', '+4,50', '+4,75', '+5,00']],
        optionsNegative: [['-0,25', '-0,50', '-0,75', '-1,00', '-1,25'], ['-1,50', '-1,75', '-2,00', '-2,25', '-2,50'], ['-2,75', '-3,00', '-3,25', '-3,50', '-3,75'], ['-4,00', '-4,25', '-4,50', '-4,75', '-5,00']],
        optionsEixo: [['5º', '10º', '15º', '20º', '25º', '30º'], ['35º', '40º', '45º', '50º', '55º', '60º'], ['65º', '70º', '75º', '80º', '85º', '90º'], ['95º', '100º', '105º', '110º', '115º', '120º'], ['125º', '130º', '135º', '140º', '145º', '150º'], ['155º', '160º', '165º', '170º', '175º', '180º']],
        optionsDP: [['3,0', '3,5', '4,0', '4,5'], ['5,0', '5,5', '6,0', '6,5'], ['7,0', '7,5', '8,0', '8,5'], ['9,0', '9,5', '10,0', '10,5']]
    }
  },
  mounted() {
    if (!this.readonly) {
      this.onChange()
    }

    EventBus.$on('copyRefractionToGlasses', this.copyRefractionToGlasses);
  },
  methods: {
    openOptions(input, clickable){
        this.activeEyeBox === input ? this.activeEyeBox = null : this.activeEyeBox = input
    },
    copyEyeValues(){
      this.form.fields.olhoEsquerdoEsferico = this.form.fields.olhoDireitoEsferico
      this.form.fields.olhoEsquerdoCilindrico = this.form.fields.olhoDireitoCilindrico
      this.form.fields.olhoEsquerdoEixo = this.form.fields.olhoDireitoEixo
      this.form.fields.olhoEsquerdoAdicao = this.form.fields.olhoDireitoAdicao
      this.form.fields.olhoEsquerdoDP = this.form.fields.olhoDireitoDP
      this.onBlurForm(this.form);
    },
    setActiveEyeBox(BoxName){
      this.activeEyeBox = BoxName
    },
    changeInputValue(change, amount, value, type){
      if(this.form.fields[value] === '' || this.form.fields[value] === null){
        this.form.fields[value] = '0'
      }
      if(!(isNaN(parseFloat(this.form.fields[value])))){
          if(type === 'eixo'){
              if(change === 'increase'){
                this.form.fields[value] = ((parseFloat(this.form.fields[value].replace(',', '.')) + amount).toFixed(0)).replace('.', ',')

              } else if(change === 'decrease'){
                this.form.fields[value] = ((parseFloat(this.form.fields[value].replace(',', '.')) - amount).toFixed(0)).replace('.', ',')
              }
              if(parseFloat(this.form.fields[value].replace(',', '.')) > 0){
                this.form.fields[value] = this.form.fields[value]+'º'
              }
          }

          else if(type === 'DP'){
              if(change === 'increase'){
                this.form.fields[value] = ((parseFloat(this.form.fields[value].replace(',', '.')) + amount).toFixed(1)).replace('.', ',')

              } else if(change === 'decrease'){
                this.form.fields[value] = ((parseFloat(this.form.fields[value].replace(',', '.')) - amount).toFixed(1)).replace('.', ',')
              }
              if(parseFloat(this.form.fields[value].replace(',', '.')) > 0){
                this.form.fields[value] = this.form.fields[value]+''
              }
            }

          else if(!(isNaN(parseFloat(this.form.fields[value])))){
            if(change === 'increase'){
              this.form.fields[value] = ((parseFloat(this.form.fields[value].replace(',', '.')) + amount).toFixed(2)).replace('.', ',')

            } else if(change === 'decrease'){
              this.form.fields[value] = ((parseFloat(this.form.fields[value].replace(',', '.')) - amount).toFixed(2)).replace('.', ',')
            }
            if(parseFloat(this.form.fields[value].replace(',', '.')) > 0){
              this.form.fields[value] = '+'+this.form.fields[value]
            }
        }
        this.onBlurForm(this.form);
      }
    },
    onChange() {
      this.attendanceReview.prescricaoDeOculos = Object.keys(this.form.fields).some(key =>this.form.fields[key]);
    },
    dispatchGetRefractionValues(checked, sufix) {
      EventBus.$emit('getRefractionValues', checked && sufix, this.form.id);
    },
    copyRefractionToGlasses(fields, sufix) {
      this.clearRefractionFields();
      this.useStaticRefraction = sufix === 'Est';
      this.useDynamicRefraction = sufix === 'Din';
      this.form.fields.usarValoresRefracao = sufix;

      Object.keys(this.form.fields).map(key => {
        if (fields && fields[`${key}${sufix}`]) {
          this.form.fields[key] = fields[`${key}${sufix}`];
        }
      });
      this.onBlurForm(this.form);
    },
    clearRefractionFields() {
      this.form.fields.olhoDireitoEsferico = null;
      this.form.fields.olhoDireitoCilindrico = null;
      this.form.fields.olhoDireitoEixo = null;
      this.form.fields.olhoDireitoAdicao = null;
      this.form.fields.olhoDireitoDP = null;
      this.form.fields.olhoEsquerdoEsferico = null;
      this.form.fields.olhoEsquerdoCilindrico = null;
      this.form.fields.olhoEsquerdoEixo = null;
      this.form.fields.olhoEsquerdoAdicao = null;
      this.form.fields.olhoEsquerdoDP = null;
    },
    inputTextArea(value) {
      this.form.fields.orientacao = value
      this.onBlurForm(this.form);
    },
    setMarcaRecomendada() {
      this.form.fields.marcaRecomendada = this.marcaRecomendada ? 'ZEISS' : null;
      this.onBlurForm(this.form);
    },
    setTipoLente(value) {
      if (!value.length) return;
      this.opcoesTipoLente.push(value);
      this.form.fields.tipoLente = value
      this.onBlurForm(this.form);
    },
    setMateriaPrima(value) {
      if (!value.length) return;
      this.opcoesMateriaPrima.push(value);
      this.form.fields.materiaPrima = value
      this.onBlurForm(this.form);
    },
  },
  watch: {
    'form.fields': {
      handler() {
        this.onChange();
      },
      deep: true,
    },
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.fields = newValue.fields
        }
      },
      deep: true
    }
  },
}
</script>


<style lang="scss" scoped>
  label {
    margin-top: 16px;
    font-size: 16px;
    line-height: 150%;
    font-weight: 600;
    color: var(--dark-blue);
  }

  .eyeValueChange {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 0 !important;
    position: relative;
    margin-left: -30px !important;
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 30px;

      button {
        height: 20px;
        width: 30px;
        color: var(--greys-60);
        background-color: #fff;
        position: relative !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 1.5px solid var(--blue-100) !important;

        p {
          font-weight: 700;
        }

      }
    }
  }

  .eye-box-container {
    .eyeValueChange {
      visibility: hidden;
    }

    &:hover {
      .eyeValueChange {
        visibility: visible;
      }
    }
  }

  .use-refraction-container {
    display: flex;
    label {
      margin-top: 6px;
    }

    label:last-child {
      margin-left: 16px;
    }
  }

  .guidance-container {
    margin-top: 8px;
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    fill: var(--blue-500)
  }

  .treatment {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

     @media (min-width: 900px) {
      max-width: 10ch;
    }

    @media (min-width: 1200px) {
      max-width: 15ch;
    }
    @media (min-width: 1400px) {
      max-width: 20ch;
    }
    @media (min-width: 1600px) {
      max-width: 25ch;
    }
  }
  .treatment-tooltip{
    text-align: left;
  }
</style>
