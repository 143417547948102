<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col cols="12">
          <p class="title-tags">Selecionados</p>
          <div class="tags">
            <div
              class="tag selected"
              v-for="(opt, index) of form.value"
              :key="index"
              @click="!readonly ? removeTag(index) : null"
            >
              {{ opt }}
            </div>
          </div>
          <p class="title-tags">Sugestões</p>
          <div class="tags">
            <div
              class="tag"
              v-for="(opt, index) of options.filter(
                opt => !form.value.includes(opt)
              )"
              :key="index"
              @click="!readonly ? addTag(opt) : null"
            >
              {{ opt }}
            </div>
            <input
              autocomplete="off"
              v-model="form.fields.custom"
              type="text"
              placeholder="Adicionar avaliação pré anestésica personalizada"
              v-on:keyup.enter="addCustom"
              v-if="!readonly"
            />
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'Apa',
  props: {
    initialForm: Object,
    attendanceReview: Object,
    readonly: Boolean
  },
  data() {
    return {
      form: {
        fields: {},
        value: [],
        ...this.initialForm
      },
      options: [
        'Hemograma',
        'Glicemia',
        'Sódio',
        'Potássio',
        'Creatinina',
        'RX de Toráx',
        'Eletrocardiograma'
      ]
    }
  },
  mounted() {
    if (!this.readonly) {
      this.onChange()
    }
  },
  methods: {
    addTag(opt) {
      this.form.value.push(opt)
      this.$emit('saveForm', this.form)
      this.onChange()
    },
    removeTag(index) {
      this.form.value.splice(index, 1)
      this.$emit('saveForm', this.form)
      this.onChange()
    },
    addCustom() {
      this.form.value.push(this.form.fields.custom)

      this.form.fields = {
        custom: ''
      }
      this.$emit('saveForm', this.form)
      this.onChange()
    },
    onChange() {
      this.attendanceReview.solicitacaoDeAPA = this.form.value.length > 0
    }
  },
  watch: {
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.value = newValue.value
        }
      },
      deep: true
    }
  }
}
</script>
