<template>
  <div>
    <v-simple-protocol-modal :protocolMedicines="this.form.value" />
    <b-row>
      <b-col>
        <validation-observer
          ref="prescricaoMedicamentosaForm"
          v-slot="{ invalid }"
          v-if="!readonly"
        >
          <b-row v-if="!readonly">
            <b-col cols="12">
              <b-button
                variant="outline-primary"
                outline
                class="show-protocol-button protocol-button"
                @click="$bvModal.show('protocols-select-modal')"
              >
                Ver protocolos prontos
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="5">
              <div class="form-group">
                <label class="d-flex">Nome do medicamento
                  <label for="antimicrobiano" class="checkbox ml-auto">
                    <input
                      type="checkbox"
                      id="antimicrobiano"
                      v-model="form.fields.antimicrobiano"
                      :disabled="readonly || typeof form.fields.medicamento === 'object'"
                    />
                    <span :class="`checkmark ${readonly || typeof form.fields.medicamento === 'object' ? 'checkmark-disabled' : ''}`">
                      <Check />
                    </span>
                    Antimicrobiano
                  </label>
                </label>
                <validation-provider
                  name="medicamento"
                  :rules="{ required: true }"
                  v-slot="{ errors, touched }"
                >
                  <multiselect
                    v-if="!form.fields.custom"
                    v-model="form.fields.medicamento"
                    :options="medicinesUse"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    :internal-search="false"
                    @search-change="searchMedicines"
                    @select="selectMedicine"
                    placeholder="Pesquisar medicamento"
                    class="search-mode with-border"
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      <div class="text-truncate">
                        {{ props.option.product }}<br />
                        <small>
                          {{ props.option.company_name }} -
                          {{ props.option.description }}
                        </small>
                      </div>
                    </template>
                    <template slot="option" slot-scope="props">
                      {{ props.option.product }}<br />
                      <small
                        >{{ props.option.company_name }} -
                        {{ props.option.description }}
                      </small>
                    </template>
                    <template slot="caret">
                      <div class="search">
                        <v-search />
                      </div>
                    </template>
                    <template slot="noOptions">
                      Digite para pesquisar um medicamento
                    </template>
                    <template slot="noResult" slot-scope="props">
                      <li @click="setCustom(true, props.search)">
                        <div class="multiselect__option custom-item">
                          <v-plus class="icon" />
                          Adicionar medicamento "{{ props.search }}"
                        </div>
                      </li>
                    </template>
                  </multiselect>
                  <div class="custom-input" v-else>
                    <v-close class="close-svg" @click="setCustom(false)" />
                    <input
                      autocomplete="off"
                      v-model="form.fields.medicamento"
                      :readonly="readonly"
                      type="text"
                      class="form-control"
                      placeholder=""
                      :class="{
                        error: touched && errors.length > 0 && 1 === 2
                      }"
                    />
                  </div>
                </validation-provider>
              </div>
            </b-col>

            <b-col cols="3">
              <div class="form-group">
                <label for="via">Via</label>
                <validation-provider
                  name="via"
                  :rules="{ required: true }"
                  v-slot="{ errors, touched }"
                >
                  <multiselect
                    v-model="form.fields.via"
                    id="via"
                    :options="['Ocular', 'Oral', 'Uso tópico']"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    :allowEmpty="false"
                    placeholder="Selecionar"
                    class="with-border"
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                  >
                    <perfect-scrollbar>
                      <template slot="caret">
                        <div class="chevron">
                          <v-chevron-down />
                        </div>
                      </template>
                    </perfect-scrollbar>
                    <template slot="noOptions"> Nenhuma opção </template>
                    <template slot="noResult"> Nenhum resultado </template>
                  </multiselect>
                </validation-provider>
              </div>
            </b-col>

            <b-col cols="4">
              <div class="form-group">
                <label for="quantidade">Quantidade</label>
                <span class="help" id="help"> (Opcional)</span>
                <validation-provider
                  name="quantidade"
                  :rules="{ required: false }"
                  v-slot="{ errors, touched }"
                >
                  <multiselect
                    @search-change="changeOptionsQuantidade($event)"
                    v-model="form.fields.quantidade"
                    id="quantidade"
                    :options="options"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    :internal-search="true"
                    :allowEmpty="false"
                    placeholder="Selecionar"
                    class="with-border"
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <v-chevron-down />
                      </div>
                    </template>
                    <template slot="singleLabel" slot-scope="{ option }">
                      {{ option }}
                    </template>
                    <template slot="noOptions">
                      <div>Digite para pesquisar...</div>
                    </template>
                    <template slot="noResult">
                      <div>Nenhuma informação encontrada...</div>
                    </template>
                  </multiselect>
                </validation-provider>
              </div>
            </b-col>

            <b-col cols="3">
              <div class="form-group">
                <label for="dose">Dose</label>
                <span v-if="form.fields.via === 'Uso tópico'" class="help">
                  (Opcional)
                </span>
                <validation-provider
                  name="dose"
                  :rules="{ required: form.fields.via !== 'Uso tópico' }"
                  v-slot="{ errors, touched }"
                >
                  <multiselect
                    @search-change="changeDose($event)"
                    v-model="form.fields.dose"
                    id="dose"
                    :options="optionsDose"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    :internal-search="true"
                    :allowEmpty="false"
                    placeholder="Descrever"
                    class="with-border"
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <v-chevron-down />
                      </div>
                    </template>
                    <template slot="singleLabel" slot-scope="{ option }">
                      {{ option }}
                    </template>
                    <template slot="noOptions">
                      <div>Digite para pesquisar...</div>
                    </template>
                    <template slot="noResult">
                      <div>Nenhuma informação encontrada...</div>
                    </template>
                  </multiselect>
                </validation-provider>
              </div>
            </b-col>

            <b-col cols="4">
              <div class="form-group">
                <label for="intervalo">Intervalo</label>
                <validation-provider
                  name="intervalo"
                  :rules="{ required: true }"
                  v-slot="{ errors, touched }"
                >
                  <multiselect
                    @search-change="changeOptionsIntervalo($event)"
                    v-model="form.fields.intervalo"
                    id="intervalo"
                    :options="optionsIntervalo"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    :internal-search="true"
                    :allowEmpty="false"
                    placeholder="Descrever"
                    class="with-border"
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <v-chevron-down />
                      </div>
                    </template>
                    <template slot="singleLabel" slot-scope="{ option }">
                      {{ option }}
                    </template>
                    <template slot="noOptions">
                      <div>Digite para pesquisar...</div>
                    </template>
                    <template slot="noResult">
                      <div>Nenhuma informação encontrada...</div>
                    </template>
                  </multiselect>
                </validation-provider>
              </div>
            </b-col>

            <b-col v-if="form.fields.via === 'Ocular'" cols="3">
              <div class="form-group">
                <label for="lateralidade">Lateralidade</label>
                <validation-provider
                  name="lateralidade"
                  :rules="{ required: form.fields.via === 'Ocular' }"
                  v-slot="{ errors, touched }"
                >
                  <multiselect
                    v-model="form.fields.lateralidade"
                    id="lateralidade"
                    :options="[
                      'Olho esquerdo',
                      'Olho direito',
                      'Ambos os olhos'
                    ]"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    :allowEmpty="false"
                    placeholder="Descrever"
                    class="with-border"
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                  >
                    <perfect-scrollbar>
                      <template slot="caret">
                        <div class="chevron">
                          <v-chevron-down />
                        </div>
                      </template>
                    </perfect-scrollbar>
                    <template slot="noOptions"> Nenhuma opção </template>
                    <template slot="noResult"> Nenhum resultado </template>
                  </multiselect>
                </validation-provider>
              </div>
            </b-col>

            <b-col cols="4">
              <div class="form-group">
                <label for="tempo_de_uso">Tempo de uso</label>
                <validation-provider
                  name="tempo_de_uso"
                  :rules="{ required: true }"
                  v-slot="{ errors, touched }"
                >
                  <multiselect
                    @search-change="changeTempoDeUso($event)"
                    v-model="form.fields.tempo_uso"
                    id="tempo_de_uso"
                    :options="optionTempoDeUso"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    :allowEmpty="false"
                    placeholder="Descrever"
                    class="with-border"
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <v-chevron-down />
                      </div>
                    </template>
                    <template slot="singleLabel" slot-scope="{ option }">
                      {{ option }}
                    </template>
                    <template slot="noOptions">
                      <div>Digite para pesquisar...</div>
                    </template>
                    <template slot="noResult">
                      <div>Nenhuma informação encontrada...</div>
                    </template>
                  </multiselect>
                </validation-provider>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <div class="form-group">
                <label for="">Horários</label>
                <span class="help" id="help"> (Opcional)</span>
                <p class="title-tags">Selecionados</p>
                <div class="tags">
                  <div
                    class="tag selected"
                    v-for="(opt, index) of form.fields.horarios"
                    :key="index"
                    @click="!readonly ? removeTag(index) : null"
                  >
                    {{ opt }}
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <p class="title-tags">Sugestões</p>
              <div class="tags">
                <div
                  class="tag"
                  v-for="(opt, index) of optionsHour.filter(
                    opt => !form.fields.horarios.includes(opt)
                  )"
                  :key="index"
                  @click="!readonly ? addTag(opt) : null"
                >
                  {{ opt }}
                </div>
                <input
                  class="input-time"
                  autocomplete="off"
                  v-model="form.fields.customHour"
                  type="time"
                  placeholder="Adicionar horário personalizado"
                  v-on:keyup.enter="addTag(form.fields.customHour)"
                  v-if="!readonly"
                />
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="10">
              <div class="form-group">
                <label for="">Orientação</label>
                <span class="help" id="help"> (Opcional)</span>
                <validation-provider
                  name="tempo_de_uso"
                  :rules="{ required: false }"
                  v-slot="{ errors, touched }"
                >
                  <input
                    class="form-control"
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                    v-model="form.fields.orientacao"
                    type="text"
                    placeholder="Aplicar colírio no centro do olho e não esfregar"
                    v-if="!readonly"
                  />
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="2">
              <b-button
                variant="primary"
                block
                class="h-38 mt-27"
                :disabled="invalid || !form.fields.tempo_uso"
                @click="saveItem()"
              >
                Adicionar
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
        <b-row>
          <b-col cols="12">
            <table class="table-eyecare">
              <thead>
                <tr>
                  <th width="50%">Nome do medicamento</th>
                  <th width="17%" class="text-center">Quantidade</th>
                  <th width="10%">Via</th>
                  <th width="23%">Posologia e orientação</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, index) of form.value" :key="index">
                  <td
                    class="td-large"
                    v-if="typeof value.medicamento === 'object'"
                  >
                    <span class="medicine">
                      {{ value.medicamento ? value.medicamento.product : '-' }}
                      <br />
                      <small>
                        {{
                          value.medicamento
                            ? value.medicamento.company_name
                            : '-'
                        }}
                        -
                        {{
                          value.medicamento ? value.medicamento.description : ''
                        }}
                      </small>
                      <div v-if="value.orientacao" class="orientation">
                        * {{ value.orientacao }}
                      </div>
                      <div class="antimicrobial" v-if="value.antimicrobiano || parseInt(value.medicamento.antimicrobiano)">
                        Antimicrobiano
                      </div>
                    </span>
                    <div v-if="value.via === 'Ocular'" class="d-block">
                      <div class="form-check">
                        <div>
                          <input
                            :disabled="readonly"
                            @change="changeLateralidade('Olho esquerdo', value)"
                            class="form-check-input"
                            type="radio"
                            :name="`lateralidadeDireito${index}`"
                            :id="`lateralidadeDireito${index}`"
                            :checked="value.lateralidade === 'Olho esquerdo'"
                          />
                          <label
                            class="form-check-label"
                            :for="`lateralidadeDireito${index}`"
                          >
                            Olho esquerdo
                          </label>
                        </div>

                        <div>
                          <input
                            :disabled="readonly"
                            @change="changeLateralidade('Olho direito', value)"
                            class="form-check-input"
                            type="radio"
                            :name="`lateralidadeEsquerdo${index}`"
                            :id="`lateralidadeEsquerdo${index}`"
                            :checked="value.lateralidade === 'Olho direito'"
                          />
                          <label
                            class="form-check-label"
                            :for="`lateralidadeEsquerdo${index}`"
                          >
                            Olho direito
                          </label>
                        </div>

                        <div>
                          <input
                            :disabled="readonly"
                            @change="
                              changeLateralidade('Ambos os olhos', value)
                            "
                            class="form-check-input"
                            type="radio"
                            :name="`lateralidadeAmbos${index}`"
                            :id="`lateralidadeAmbos${index}`"
                            :checked="value.lateralidade === 'Ambos os olhos'"
                          />
                          <label
                            class="form-check-label"
                            :for="`lateralidadeAmbos${index}`"
                          >
                            Ambos os olhos
                          </label>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="td-large" v-else>
                    {{ value.medicamento }}
                    <div v-if="value.orientacao" class="orientation">
                      * {{ value.orientacao }}
                    </div>
                    <div class="antimicrobial" v-if="value.antimicrobiano">
                      Antimicrobiano
                    </div>
                    <div v-if="value.via === 'Ocular'" class="d-block">
                      <div class="form-check">
                        <div>
                          <input
                            :disabled="readonly"
                            @change="changeLateralidade('Olho esquerdo', value)"
                            class="form-check-input"
                            type="radio"
                            :name="`lateralidadeDireito${index}`"
                            :id="`lateralidadeDireito${index}`"
                            :checked="value.lateralidade === 'Olho esquerdo'"
                          />
                          <label
                            class="form-check-label"
                            :for="`lateralidadeDireito${index}`"
                          >
                            Olho esquerdo
                          </label>
                        </div>

                        <div>
                          <input
                            :disabled="readonly"
                            @change="changeLateralidade('Olho direito', value)"
                            class="form-check-input"
                            type="radio"
                            :name="`lateralidadeEsquerdo${index}`"
                            :id="`lateralidadeEsquerdo${index}`"
                            :checked="value.lateralidade === 'Olho direito'"
                          />
                          <label
                            class="form-check-label"
                            :for="`lateralidadeEsquerdo${index}`"
                          >
                            Olho direito
                          </label>
                        </div>

                        <div>
                          <input
                            :disabled="readonly"
                            @change="
                              changeLateralidade('Ambos os olhos', value)
                            "
                            class="form-check-input"
                            type="radio"
                            :name="`lateralidadeAmbos${index}`"
                            :id="`lateralidadeAmbos${index}`"
                            :checked="value.lateralidade === 'Ambos os olhos'"
                          />
                          <label
                            class="form-check-label"
                            :for="`lateralidadeAmbos${index}`"
                          >
                            Ambos os olhos
                          </label>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-center">{{ value.quantidade }}</td>
                  <td>{{ value.via }}</td>
                  <td v-if="!value.hasOwnProperty('posologia')">
                    <span v-if="value.via === 'Ocular'">
                      {{ value.dose }}
                    </span>
                    <span v-else>{{ value.dose }}</span>
                    <span v-if="value.intervalo.includes('De')">
                      a cada
                      {{
                        value.intervalo
                          .split(/[^0-9]/g)
                          .filter(n => n !== '')[0]
                      }}
                      {{
                        value.intervalo.split(' ')[
                          value.intervalo.split(' ').length - 1
                        ]
                      }}
                    </span>
                    <span v-else>
                      {{ value.intervalo }}
                    </span>
                    <span v-if="value.horarios.length">
                      <span v-for="(horario, index) in value.horarios" :key="index">{{ horario }}; </span>
                    </span>
                    <span v-if="value.tempo_uso">
                        <span v-if="!parseInt(value.tempo_uso.replace(/\D/g, ''))">
                         {{ value.tempo_uso }}
                        </span>
                        <span v-else>durante {{ value.tempo_uso }}</span>
                      </span>
                  </td>
                  <td v-else>
                    {{ value.posologia }}
                  </td>
                  <td class="text-right">
                    <div class="more-with-menu">
                      <v-more-vertical class="more-icon" />
                      <div class="menu">
                        <b-button
                          variant="link"
                          @click="editItem(value, index)"
                          :disabled="readonly"
                        >
                          Editar
                        </b-button>
                        <b-button
                          variant="link remove mb-0"
                          @click="removeItem(index)"
                          :disabled="readonly"
                        >
                          Remover
                        </b-button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
          <b-col cols="12">
            <b-button
              v-if="!readonly"
              variant="outline-primary"
              outline
              class="protocol-button mt-5"
              @click="$bvModal.show('simple-protocol-modal')"
            >
              Salvar em meus protocolos
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Search from '@/assets/icons/search.svg'
import MoreVertical from '@/assets/icons/more-vertical.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import Plus from '@/assets/icons/plus.svg'
import Close from '@/assets/icons/close.svg'
import SimpleProtocolModal from '@/components/Protocols/SimpleProtocolModal.vue'
import Check from '@/assets/icons/check.svg'

export default {
  name: 'MedicalPrescription',
  components: {
    'v-search': Search,
    'v-more-vertical': MoreVertical,
    'v-chevron-down': ChevronDown,
    'v-plus': Plus,
    'v-close': Close,
    'v-simple-protocol-modal': SimpleProtocolModal,
    Check
  },
  props: {
    initialForm: Object,
    attendanceReview: Object,
    medicines: Object,
    medicinesAntimicrobianos: Object,
    formsMedicalPrescription: Array,
    readonly: Boolean
  },
  data() {
    return {
      form: {
        fields: {},
        value: [],
        multiple: true,
        ...this.initialForm
      },
      optionsHour: Array.from({ length: 24 }, (_, i) => {
        if (i < 10) {
          return ('0' + i++).slice(-2) + ':00'
        } else {
          return i++ + ':00'
        }
      }),
      options: [],
      optionTempoDeUso: [],
      optionsDose: [],
      optionsIntervalo: [],
      medicinesUse: []
    }
  },
  mounted() {
    if (!this.readonly) {
      this.onChange()
    }
  },
  methods: {
    setCustom(value, searchTerm) {
      this.form.fields.antimicrobiano = false
      this.form.fields.custom = value
      this.form.fields.medicamento = value ? searchTerm : null
    },
    addTag(opt) {
      this.form.fields.horarios.push(opt)
      this.form.fields.customHour = null
    },
    removeTag(index) {
      this.form.fields.horarios.splice(index, 1)
    },
    changeOptionsQuantidade(query) {
      const options = []
      const treatedText = query.replace(/\D/g, '')
      if (parseInt(treatedText)) {
        if (treatedText === '1') {
          options.push(
            '1 frasco',
            '1 caixa',
            '1 comprimido',
            '1 bisnaga',
            '1 cápsula',
            '1 flaconete',
            '1 pílula'
          )
        } else {
          options.push(
            `${treatedText} frascos`,
            `${treatedText} caixas`,
            `${treatedText} comprimidos`,
            `${treatedText} bisnagas`,
            `${treatedText} cápsulas`,
            `${treatedText} flaconetes`,
            `${treatedText} pílulas`
          )
        }
      } else {
        options.push(
          '1 frasco',
          '1 caixa',
          '1 comprimido',
          '1 bisnaga',
          '1 cápsula',
          '1 flaconete',
          '1 pílula'
        )
      }
      !options.includes(query) && query && options.push(query);
      this.options = [...options]
    },
    changeTempoDeUso(query) {
      const options = []
      const treatedText = query.replace(/\D/g, '')
      if (parseInt(treatedText)) {
        if (treatedText === '1') {
          options.push('1 dia', '1 semana', '1 mês', '1 ano')
        } else {
          options.push(
            `${treatedText} dias`,
            `${treatedText} semanas`,
            `${treatedText} meses`,
            `${treatedText} anos`
          )
        }
      } else {
        options.push('1 dia', '1 semana', '1 mês', '1 ano')
      }
      options.push('Uso contínuo')
      options.push('Até o retorno')
      !options.includes(query) && query && options.push(query);
      this.optionTempoDeUso = [...options]
    },
    changeDose(query) {
      const options = []
      const treatedText = query.replace(/\D/g, '')
      if (!query.includes('-') && parseInt(treatedText)) {
        if (treatedText === '1') {
          options.push(
            `${treatedText} ml`,
            `${treatedText} comprimido`,
            `${treatedText} sache`,
            `${treatedText} capsula`,
            `${treatedText} pílula`,
            `${treatedText} gota`,
            `${treatedText} mg/kg`
          )
        } else {
          options.push(
            `${treatedText} mls`,
            `${treatedText} comprimidos`,
            `${treatedText} saches`,
            `${treatedText} capsulas`,
            `${treatedText} pílulas`,
            `${treatedText} gotas`,
            `${treatedText} mg/kg`
          )
        }
      }

      !options.includes(query) && query && options.push(query);
      this.optionsDose = [...options]
    },
    changeOptionsIntervalo(query) {
      const options = []
      const treatedText = query.replace(/\D/g, '')
      if (parseInt(treatedText)) {
        if (treatedText === '1') {
          options.push(
            `De ${treatedText} em ${treatedText} minuto`,
            `De ${treatedText} em ${treatedText} hora`,
            `De ${treatedText} em ${treatedText} dia`,
            `${treatedText} vez ao dia`,
            `De ${treatedText} em ${treatedText} semana`,
            `De ${treatedText} em ${treatedText} mês`
          )
        } else {
          options.push(
            `De ${treatedText} em ${treatedText} minutos`,
            `De ${treatedText} em ${treatedText} horas`,
            `De ${treatedText} em ${treatedText} dias`,
            `${treatedText} vezes ao dia`,
            `De ${treatedText} em ${treatedText} semanas`,
            `De ${treatedText} em ${treatedText} meses`
          )
        }
      } else {
        options.push(
          `De 1 em 1 minuto`,
          `De 1 em 1 hora`,
          `De 1 em 1 dia`,
          '1 vez ao dia',
          `1 vez por dia`,
          `De 1 em 1 semana`,
          `De 1 em 1 mês`
        )
      }
      options.push('Várias vezes ao dia');
      !options.includes(query) && query && options.push(query);
      this.optionsIntervalo = [...options]
    },
    changeLateralidade(lateralidade, value) {
      value.lateralidade = lateralidade
      this.$emit('saveForm', this.form)
      this.onChange()
      this.$forceUpdate()
    },
    editItem(value, index) {
      this.form.fields = {
        ...value,
        custom: typeof value.medicamento === 'string'
      }
      this.form.editingIndex = index
    },
    removeItem(index) {
      if (this.form.value.length > 1) {
        this.form.value.splice(index, 1);
      } else {
        this.form.value = [];
      }
      this.$emit('saveForm', this.form)
      this.onChange()
    },
    async searchMedicines(query) {
      if (query.length > 2) {
        this.medicinesUse = []
        try {
          const response = await this.api.searchMedicines({ term: query })
          this.medicinesUse = response.data.map(medicine => {
            return medicine
          })
        } catch (error) {
          this.$toast.error(error.message)
        }
      }
    },
    selectMedicine(medicine) {
      if (typeof medicine === 'object' && parseInt(medicine.antimicrobiano) === 1) {
        this.form.fields.antimicrobiano = true
      } else {
        this.form.fields.antimicrobiano = false
      }
      this.medicinesUse = []
    },
    onChange() {
      this.attendanceReview.prescricaoMedicamentosa = this.form.value.length > 0
    },
    saveItem(values) {
      if (values) {
        values.forEach(value => {
          if (!value.fields.antimicrobiano) {
            if (value.fields.via === 'Oral') {
              this.medicines.oral.push(value.fields)
            } else if (value.fields.via === 'Ocular') {
              this.medicines.ocular.push(value.fields)
            }
          } else if (value.fields.antimicrobiano) {
            if (value.fields.via === 'Oral') {
              this.medicinesAntimicrobianos.oral.push(value.fields)
            } else if (value.fields.via === 'Ocular') {
              this.medicinesAntimicrobianos.ocular.push(value.fields)
            }
          }
          this.form.value.push(value.fields)
        })
      } else {
        if (this.form.editingIndex !== null) {
          this.form.value.splice(this.form.editingIndex, 1)
        }
        if (!this.form.fields.antimicrobiano) {
          if (this.form.fields.via === 'Oral') {
            this.medicines.oral.push(this.form.fields)
          } else if (this.form.fields.via === 'Ocular') {
            this.medicines.ocular.push(this.form.fields)
          }
        } else if (this.form.fields.antimicrobiano) {
          if (this.form.fields.via === 'Oral') {
            this.medicinesAntimicrobianos.oral.push(this.form.fields)
          } else if (this.form.fields.via === 'Ocular') {
            this.medicinesAntimicrobianos.ocular.push(this.form.fields)
          }
        }
        this.form.value.push(this.form.fields)
      }
      this.$emit('saveForm', this.form)
      this.onChange()

      this.form.fields = {
        custom: false,
        medicamento: null,
        via: null,
        quantidade: null,
        dose: null,
        intervalo: null,
        lateralidade: null,
        tempo_uso: null,
        horarios: [],
        orientacao: null,
        antimicrobiano: null
      }
      this.form.editingIndex = null
    }
  },
  watch: {
    'form.value'() {
      this.onChange()
    },
    formsMedicalPrescription(values) {
      this.saveItem(values)
    },
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.value = newValue.value
        }
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
  .form-group {
   .checkbox {
      margin: 0;
    }
    .input-checkbox ~ .checkmark {
      width: 22px;
      height: 22px;
    }
    .input-checkbox:disabled ~ .checkmark {
      background-color: var(--neutral-200);
      border: var(--neutral-200);
    }
  }

  .td-large {
    min-height: 120px;

    .form-check {
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      div {
        width: 150px;
      }
    }
  }
.custom-input {
  position: relative;
  button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
  }
  .close-svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    fill: var(--neutral-500);
  }
}
.orientation {
  font-size: 12px;
  color: var(--type-placeholder);
  font-style: italic;
  line-height: normal;
}

  .antimicrobial {
    font-style: italic;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: var(--neutral-600);
  }

.show-protocol-button {
  margin-left: 0px !important;
  margin-bottom: 20px;
}

.protocol-button {
  color: var(--blue-500);
}
</style>
