<template>
  <div>
    <validation-observer
      :ref="this.initialForm.id + '-form'"
      v-slot="{ invalid }"
      v-if="!readonly"
    >
      <b-row>
        <b-col cols="6">
          <div class="form-group">
            <label>Nome do produto</label>
            <validation-provider
              name="medicamento"
              :rules="{ required: true }"
              v-slot="{ errors, touched }"
            >
              <multiselect
                v-if="!form.fields.custom"
                v-model="form.fields.produto"
                :options="products"
                :option-height="40"
                :showLabels="false"
                :searchable="true"
                :internal-search="false"
                @search-change="searchProducts"
                @select="selectProducts"
                placeholder="Pesquisar produto"
                class="search-mode with-border"
                @focus.native="setFormActive(form.id)"
                :class="{ error: touched && errors.length > 0 && 1 === 2 }"
              >
                <template slot="singleLabel" slot-scope="props">
                  {{ props.option }}
                </template>
                <template slot="caret">
                  <div class="search">
                    <Search />
                  </div>
                </template>
                <template slot="noOptions">
                  Digite para pesquisar um produto
                </template>
                <template slot="noResult" slot-scope="props">
                  <li @click="setCustom(true, props.search)">
                    <div class="multiselect__option custom-item">
                      <Plus class="icon" />
                      Adicionar produto "{{ props.search }}"
                    </div>
                  </li>
                </template>
              </multiselect>
              <div class="custom-input" v-else>
                <Close class="close-svg" @click="setCustom(false)" />
                <input
                  autocomplete="off"
                  v-model="form.fields.produto"
                  :readonly="readonly"
                  type="text"
                  class="form-control"
                  placeholder=""
                  :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                />
              </div>
            </validation-provider>
          </div>
        </b-col>

        <b-col cols="4">
          <div class="form-group">
            <label for="quantidade">Quantidade</label>
            <validation-provider
              name="quantidade"
              :rules="{ required: true }"
              v-slot="{ errors, touched }"
            >
              <multiselect
                v-model="form.fields.quantidade"
                id="quantidade"
                :options="Array.from({ length: 10 }, (_, i) => i + 1)"
                :option-height="40"
                :showLabels="false"
                :searchable="true"
                :internal-search="true"
                :allowEmpty="false"
                placeholder="Selecionar"
                class="with-border"
                @focus.native="setFormActive(form.id)"
                :class="{ error: touched && errors.length > 0 && 1 === 2 }"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option }}
                </template>
                <template slot="noResult">
                  <div>Nenhuma informação encontrada...</div>
                </template>
              </multiselect>
            </validation-provider>
          </div>
        </b-col>

        <b-col cols="2" class="div-button">
          <b-button
            variant="primary h-48"
            block
            :disabled="invalid"
            @click="addItem"
            >Adicionar
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>

    <b-row>
      <table class="table-eyecare with-laterality">
        <thead>
          <tr>
            <th>Nome do produto</th>
            <th>Quantidade</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, index) of form.value" :key="index">
            <td>{{ value.produto }}</td>
            <td>{{ value.quantidade }}</td>
            <td class="text-right">
              <div class="more-with-menu">
                <MoreVertical class="more-icon" />
                <div class="menu">
                  <b-button
                    variant="link"
                    @click="editItem(value, index)"
                    :disabled="readonly"
                  >
                    Editar
                  </b-button>
                  <b-button
                    variant="link remove mb-0"
                    :disabled="readonly"
                    @click="removeItem(index)"
                  >
                    Remover
                  </b-button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </b-row>
  </div>
</template>

<script>
import MoreVertical from '@/assets/icons/more-vertical.svg'
import Plus from '@/assets/icons/plus.svg'
import Search from '@/assets/icons/search.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import Close from '@/assets/icons/close.svg'

export default {
  name: 'ProductsUsed',
  props: {
    initialForm: Object,
    readonly: Boolean,
    setFormActive: Function
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      form: {
        fields: {},
        value: [],
        ...this.initialForm
      },
      products: []
    }
  },
  components: {
    MoreVertical,
    Plus,
    Search,
    ChevronDown,
    Close
  },
  methods: {
    async searchProducts(query) {
      if (query.length > 2) {
        this.products = []
        try {
          await this.getProducts(query)
        } catch (error) {
          this.$toast.error(error.message)
        }
      }
    },
    selectProducts() {
      this.products = []
    },
    async getProducts(query) {
      try {
        const response = await this.api.getProducts(this.clinic.id, null, query)
        const products = response.data.data.map(data => {
          return data.name
        })

        this.products = products.filter(function (a) {
          return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true)
        }, Object.create(null))
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    setCustom(value, searchTerm) {
      this.form.fields.custom = value
      this.form.fields.produto = value ? searchTerm : null
    },
    addItem() {
      if (this.form.editingIndex !== null) {
        this.form.value.splice(this.form.editingIndex, 1)
      }
      this.form.value.push(this.form.fields)

      this.form.fields = {
        custom: false,
        produto: null,
        quantidade: null
      }

      this.form.editingIndex = null
      this.$emit('saveForm', this.form)
    },
    editItem(value, index) {
      this.form.fields = { ...value }
      this.form.editingIndex = index
    },
    removeItem(index) {
      this.form.value.splice(index, 1)
      this.$emit('saveForm', this.form)
    }
  },
  watch: {
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.value = newValue.value
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .div-button {
    display: flex;
    align-items: center;
  }
</style>
