<template>
  <b-row>
    <b-col cols="12">
      <div class="form-group">
        <label>Fotomotor direto</label>
        <b-row>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> D</p>
              <input
                autocomplete="off"
                v-model="form.fields.fotomotorDiretoDireito"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
            <button
              class="blue-underline"
              @click="
                () =>
                  copyEyeValues(
                    'fotomotorDiretoDireito',
                    'fotomotorDiretoEsquerdo'
                  )
              "
              v-if="!readonly"
              @focus="setFormActive(form.id)"
            >
              Copiar valores O.D para O.E
            </button>
          </b-col>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> E</p>
              <input
                autocomplete="off"
                v-model="form.fields.fotomotorDiretoEsquerdo"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>
    <b-col cols="12">
      <div class="form-group">
        <label>Consensual</label>
        <b-row>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> D</p>
              <input
                autocomplete="off"
                v-model="form.fields.consensualDireito"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
            <button
              class="blue-underline"
              @click="
                () => copyEyeValues('consensualDireito', 'consensualEsquerdo')
              "
              v-if="!readonly"
              @focus="setFormActive(form.id)"
            >
              Copiar valores O.D para O.E
            </button>
          </b-col>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> E</p>
              <input
                autocomplete="off"
                v-model="form.fields.consensualEsquerdo"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>
    <b-col cols="12">
      <div class="form-group mb-0">
        <label>DPAR</label>
        <b-row>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> D</p>
              <input
                autocomplete="off"
                v-model="form.fields.dparDireito"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
            <button
              class="blue-underline"
              @click="() => copyEyeValues('dparDireito', 'dparEsquerdo')"
              v-if="!readonly"
              @focus="setFormActive(form.id)"
            >
              Copiar valores O.D para O.E
            </button>
          </b-col>
          <b-col cols="6">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> E</p>
              <input
                autocomplete="off"
                v-model="form.fields.dparEsquerdo"
                :readonly="readonly"
                @focus="setFormActive(form.id)"
                @blur="onBlurForm(form)"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>
    <div class="add-file">
      <label for="imageSelector-reflexos" class="file-input"
        ><Paperclip /> anexar arquivo</label
      >
      <input
        type="file"
        id="imageSelector-reflexos"
        ref="image"
        style="display: none"
        accept=".jpg, .png, .pdf"
        :disabled="readonly"
        @change="onFileChange($event, 'Reflexos')"
      />
      <div class="file-list">
        <p
          v-if="
            filesToUpload != null &&
            filesToUpload.find(element => element.group === 'Reflexos')
          "
        >
          Anexos:
        </p>
        <ul v-for="(file, index) of filesToUpload" :key="index">
          <li v-if="file.group == 'Reflexos'">
            <Pdf v-if="file.type == 'application/pdf'" class="icon" />
            <Files v-else class="icon" />
            <div class="attachment_file_name">{{ file.name }}</div>
            <CloseUploadedFile
              class="icon-close"
              @click="filesToUpload.splice(index, 1)"
            />
          </li>
        </ul>
      </div>
    </div>
  </b-row>
</template>

<script>
import Paperclip from '@/assets/icons/paperclip2.svg'
import Pdf from '@/assets/icons/pdf.svg'
import Files from '@/assets/icons/files.svg'
import CloseUploadedFile from '@/assets/icons/closeUploadedFile.svg'
import EyeFill from '@/assets/icons/eye-fill.svg'

export default {
  name: 'Reflexes',
  props: {
    initialForm: Object,
    readonly: Boolean,
    onBlurForm: Function,
    setFormActive: Function,
    onFileChange: Function,
    filesToUpload: Array
  },
  components: {
    Paperclip,
    Pdf,
    Files,
    CloseUploadedFile,
    EyeFill
  },
  data() {
    return {
      form: {
        fields: {},
        ...this.initialForm,
      }
    }
  },
  methods: {
    copyEyeValues(rightEyeFieldName, leftEyeFieldName) {
      this.form.fields[leftEyeFieldName] = this.form.fields[rightEyeFieldName];
      this.onBlurForm(this.form);
    }
  },
  watch: {
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.fields = newValue.fields
        }
      },
      deep: true
    }
  }
}
</script>
