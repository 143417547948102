<template>
  <b-row :ref="form.id">
    <b-col>
      <validation-observer
        :ref="this.initialForm.id + '-form'"
        v-slot="{ invalid }"
        v-if="!readonly"
      >
        <b-row>
          <b-col cols="12">
            <div class="form-group">
              <validation-provider
                name="orientacao"
                :rules="{ required: true }"
                v-slot="{ errors, touched }"
              >
                <multiselect
                  v-if="!form.fields.custom"
                  v-model="form.fields.orientacao"
                  :options="options"
                  :option-height="40"
                  :showLabels="false"
                  :searchable="true"
                  :internal-search="false"
                  placeholder="Pesquisar por orientação"
                  class="search-mode with-border"
                  @select="select"
                  @focus.native="setFormActive"
                  :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                >
                  <template slot="orientacoes" slot-scope="orientacao">
                    <small>{{ orientacao }}</small>
                  </template>
                  <template slot="caret">
                    <div class="search">
                      <Search />
                    </div>
                  </template>
                  <template slot="noOptions">
                    Digite para pesquisar uma orientação
                  </template>
                  <template slot="noResult" slot-scope="orientacao">
                    <li @click="setCustom(true, orientacao.search)">
                      <div class="multiselect__option custom-item">
                        <Plus class="icon" />
                        Adicionar orientação pós operatória personalizada "{{
                          orientacao.search
                        }}"
                      </div>
                    </li>
                  </template>
                </multiselect>
                <div class="custom-input" v-else>
                  <b-button variant="link" @click="setCustom(false)">
                    Selecionar um item da lista
                  </b-button>
                  <input
                    autocomplete="off"
                    v-model="form.fields.orientacao"
                    :readonly="readonly"
                    type="text"
                    class="form-control"
                    placeholder=""
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                  />
                </div>
              </validation-provider>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div class="form-group">
              <validation-provider
                name="descricao"
                :rules="{ required: true }"
                v-slot="{ errors, touched }"
              >
                <v-text-area
                  id="descricao"
                  :placeholder="'Descrever'"
                  :value="form.fields.descricao"
                  :errors="errors"
                  :touched="touched"
                  @focus.native="setFormActive"
                  @inputTextArea="inputTextArea"
                />
              </validation-provider>
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-end">
          <b-col cols="2">
            <b-button
              variant="primary"
              block
              class="mt-27 h-56"
              :disabled="invalid"
              @click="addItem"
            >
              Adicionar
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
      <b-row>
        <b-col>
          <table class="table-eyecare">
            <thead>
              <tr>
                <th width="80%">Nome</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, index) of form.value" :key="index">
                <td>{{ value.orientacao }}</td>
                <td class="text-right">
                  <div class="more-with-menu">
                    <MoreVertical class="more-icon" />
                    <div class="menu">
                      <b-button
                        variant="link"
                        @click="editItem(value, index)"
                        :disabled="readonly"
                      >
                        Editar
                      </b-button>
                      <b-button
                        :disabled="readonly"
                        variant="link remove mb-0"
                        @click="removeItem(index)"
                      >
                        Remover
                      </b-button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import Search from '@/assets/icons/search.svg'
import Plus from '@/assets/icons/plus.svg'
import MoreVertical from '@/assets/icons/more-vertical.svg'
import TextArea from '@/components/General/TextArea'

export default {
  name: 'PostOperativeGuideline',
  props: {
    initialForm: Object,
    attendanceReview: Object,
    readonly: Boolean,
    setFormActive: Function,
    onBlurForm: Function,
    goTo: Function
  },
  components: {
    Search,
    Plus,
    MoreVertical,
    'v-text-area': TextArea
  },
  data() {
    return {
      form: {
        multiple: true,
        fields: {},
        value: [],
        editingIndex: null,
        ...this.initialForm
      },
      postOperativeGuidelines: [],
      options: []
    }
  },
  async mounted() {
    if (!this.readonly) {
      this.onChange()
      await this.getPostOperativeGuidelines()
    }
  },
  methods: {
    onChange() {
      this.attendanceReview.posOperatorio = this.form.value.length > 0
    },
    async getPostOperativeGuidelines() {
      try {
        const response = await this.api.getPostOperativeGuidelines()
        this.postOperativeGuidelines = response.data
        this.options = response.data.map(data => {
          return data.name
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    select(query) {
      this.postOperativeGuidelines.find(value => {
        if (value.name === query) this.form.fields.descricao = value.template
      })
    },
    setCustom(value, searchTerm) {
      this.form.fields.custom = value
      this.form.fields.orientacao = value ? searchTerm : null
    },
    addItem() {
      if (this.form.editingIndex !== null) {
        this.form.value.splice(this.form.editingIndex, 1)
      }
      this.form.value.push(this.form.fields)

      this.form.fields = {
        custom: false,
        orientacao: null,
        descricao: null
      }

      this.goTo(this.form)
      this.onChange()
      this.$emit('saveForm', this.form)
    },
    editItem(value, index) {
      this.form.fields = { ...value }
      this.form.editingIndex = index
    },
    removeItem(index) {
      this.form.value.splice(index, 1)
      this.onChange()
      this.$emit('saveForm', this.form)
    },
    inputTextArea(value) {
      this.form.fields.descricao = value
    }
  },
  watch: {
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.value = newValue.value
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
textarea {
  min-height: 200px;
  max-height: 1000px;
}
</style>
