<template>
  <b-row>
    <b-col>
      <validation-observer
        :ref="this.initialForm.id + '-form'"
        v-slot="{ invalid }"
        v-if="!readonly"
      >
        <div class="exam-request">
          <div class="form-group">
            <validation-provider
              name="exame"
              rules="required"
              v-slot="{ touched, errors }"
            >
              <multiselect
                v-if="!form.fields.custom"
                v-model="form.fields.exame"
                id="exames"
                :options="examesOftalmologicos"
                :option-height="40"
                :showLabels="false"
                :searchable="true"
                :allowEmpty="false"
                :showNoResults="true"
                placeholder="Selecionar"
                :class="{ error: touched && errors.length > 0 && 1 === 2 }"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
                <template slot="noResult" slot-scope="props">
                  <li @click="setCustom(true, props.search)">
                    <div class="multiselect__option custom-item">
                      <Plus class="icon" />
                      Adicionar solicitação de exame "{{ props.search }}"
                    </div>
                  </li>
                </template>
                <template slot="noOptions"> Nenhuma opção </template>
              </multiselect>
              <div class="custom-input" v-else>
                <b-button variant="link" @click="setCustom(false)"
                  >Selecionar um item da lista</b-button
                >
                <input
                  autocomplete="off"
                  v-model="form.fields.exame"
                  :readonly="readonly"
                  type="text"
                  class="form-control"
                  placeholder=""
                  :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                />
              </div>
            </validation-provider>
          </div>

          <div class="laterality-container">
            <p>Lateralidade</p>
            <div class="laterality-content">
              <div class="laterality-input">
                <div class="laterality-option">
                  <input
                    type="radio"
                    name="exam-laterality"
                    value="Ambos os olhos"
                    v-model="form.fields.examLaterality"
                  />Ambos os olhos
                </div>
                <div class="laterality-option">
                  <input
                    type="radio"
                    name="exam-laterality"
                    value="Olho esquerdo"
                    v-model="form.fields.examLaterality"
                  />Olho esquerdo
                </div>
                <div class="laterality-option">
                  <input
                    type="radio"
                    name="exam-laterality"
                    value="Olho direito"
                    v-model="form.fields.examLaterality"
                  />Olho direito
                </div>
              </div>

              <b-button
                variant="primary"
                block
                class="h-38"
                :disabled="invalid"
                @click="addItem"
                >Adicionar</b-button
              >
            </div>
          </div>
        </div>
      </validation-observer>

      <b-row>
        <b-col>
          <table class="table-eyecare with-laterality">
            <thead>
              <tr>
                <th>Exame</th>
                <th>Lateralidade</th>
                <th width="40%">Comentário</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, index) of form.value" :key="index">
                <td>{{ value.exame }}</td>

                <td>{{ value.examLaterality }}</td>

                <td>
                  <div v-if="value.editing !== true">
                    <b-button
                      variant="link"
                      v-if="value.comentario === null"
                      class="p-0"
                      @click="editItem(index)"
                      >Adicionar comentário</b-button
                    >
                    {{ value.comentario }}
                  </div>

                  <div class="text-right" v-else>
                    <textarea
                      v-model="value.comentario"
                      rows="3"
                      class="form-control"
                    ></textarea>
                    <b-button
                      variant="primary"
                      class="mt-8"
                      @click="saveItem(index)"
                      >Salvar comentário</b-button
                    >
                  </div>
                </td>

                <td class="text-right">
                  <div class="more-with-menu">
                    <MoreVertical class="more-icon" />
                    <div class="menu">
                      <b-button variant="link" @click="editItem(index)"
                        >Editar</b-button
                      >
                      <b-button
                        variant="link remove mb-0"
                        @click="removeItem(index)"
                        >Remover</b-button
                      >
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import ChevronDown from '@/assets/icons/chevron-down.svg'
import Plus from '@/assets/icons/plus.svg'
import MoreVertical from '@/assets/icons/more-vertical.svg'

export default {
  name: 'ExamRequest',
  props: {
    initialForm: Object,
    attendanceReview: Object,
    readonly: Boolean,
    examesOftalmologicos: Array
  },
  components: {
    ChevronDown,
    Plus,
    MoreVertical
  },
  mounted() {
    if (!this.readonly) {
      this.onChange()
    }
  },
  data() {
    return {
      form: {
        fields: {},
        value: [],
        ...this.initialForm
      }
    }
  },
  methods: {
    setCustom(value, searchTerm) {
      this.form.fields.custom = value
      this.form.fields.exame = value ? searchTerm : null
    },
    addItem() {
      this.form.value.push(this.form.fields)

      this.form.fields = {
        custom: false,
        exame: null,
        comentario: null,
        examLaterality: 'Ambos os olhos'
      }
      this.$emit('saveForm', this.form)
      this.onChange()
    },
    saveItem(index) {
      this.form.value = this.form.value.map((opt, i) => {
        if (i === index) {
          delete opt.editing
        }
        return opt
      })
      this.$emit('saveForm', this.form)
      this.onChange()
    },
    editItem(index) {
      this.form.value = this.form.value.map((opt, i) => {
        if (i === index) {
          opt.editing = true
        }
        return opt
      })
    },
    removeItem(index) {
      this.form.value.splice(index, 1)
      this.$emit('saveForm', this.form)
      this.onChange()
    },
    onChange() {
      this.attendanceReview.solicitacaoDeExames = this.form.value.length > 0
    }
  },
  watch: {
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.value = newValue.value
        }
      },
      deep: true
    }
  }
}
</script>
