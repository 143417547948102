<template>
  <b-row>
    <b-col cols="4">
      <div class="form-group mb-0">
        <label for="pio-aparelho">Aparelho de medição</label>
        <multiselect
          v-model="form.fields.aparelho"
          id="pio-aparelho"
          :disabled="readonly"
          :options="['Tonômetro de sopro', 'Aplanação', 'iCare', 'Tonopen']"
          :option-height="40"
          :showLabels="false"
          :searchable="false"
          :allowEmpty="false"
          placeholder="Selecionar"
          class="with-border"
        >
          <template slot="caret">
            <div class="chevron">
              <ChevronDown />
            </div>
          </template>
          <template slot="noOptions"> Nenhuma opção </template>
          <template slot="noResult"> Nenhum resultado </template>
        </multiselect>
      </div>
      <button
        class="blue-underline"
        @click="copyEyeValues"
        v-if="!readonly"
        @focus="setFormActive(form.id)"
      >
        Copiar valores O.D para O.E
      </button>
    </b-col>

    <b-col cols="2">
      <div class="form-group mb-0">
        <label for="pio-horario">Horário</label>
        <input
          autocomplete="off"
          v-model="form.fields.horario"
          :readonly="readonly"
          @focus="setFormActive(form.id)"
          @blur="onBlurForm(form)"
          id="pio-horario"
          type="text"
          class="form-control"
          placeholder="00:00"
          v-mask="'##:##'"
        />
      </div>
    </b-col>
    <b-col cols="3">
      <div class="form-group mb-0">
        <label for="pio-direito">Olho direito</label>
        <div class="input-eye">
          <p class="tag-eye"><EyeFill /> D</p>
          <input
            autocomplete="off"
            v-model="form.fields.olhoDireito"
            :readonly="readonly"
            @focus="setFormActive(form.id)"
            @blur="onBlurForm(form)"
            id="pio-direito"
            type="text"
            class="form-control"
            placeholder="Resultado"
          />
        </div>
      </div>
    </b-col>
    <b-col cols="3">
      <div class="form-group mb-0">
        <label for="pio-esquerdo">Olho esquerdo</label>
        <div class="input-eye">
          <p class="tag-eye"><EyeFill /> E</p>
          <input
            autocomplete="off"
            v-model="form.fields.olhoEsquerdo"
            :readonly="readonly"
            @focus="setFormActive(form.id)"
            @blur="onBlurForm(form)"
            id="pio-esquerdo"
            type="text"
            class="form-control"
            placeholder="Resultado"
          />
        </div>
      </div>
    </b-col>
    <div class="add-file">
      <label for="imageSelector-pressaoIntraocular" class="file-input"
        ><Paperclip /> anexar arquivo</label
      >
      <input
        type="file"
        id="imageSelector-pressaoIntraocular"
        ref="image"
        style="display: none"
        accept=".jpg, .png, .pdf"
        :disabled="readonly"
        @change="onFileChange($event, 'Pressão intraocular')"
      />
      <div class="file-list">
        <p
          v-if="
            filesToUpload != null &&
            filesToUpload.find(
              element => element.group === 'Pressão intraocular'
            )
          "
        >
          Anexos:
        </p>
        <ul v-for="(file, index) of filesToUpload" :key="index">
          <li v-if="file.group == 'Pressão intraocular'">
            <Pdf v-if="file.type == 'application/pdf'" class="icon" />
            <Files v-else class="icon" />
            <div class="attachment_file_name">{{ file.name }}</div>
            <CloseUploadedFile
              class="icon-close"
              @click="filesToUpload.splice(index, 1)"
            />
          </li>
        </ul>
      </div>
    </div>
  </b-row>
</template>

<script>
import Paperclip from '@/assets/icons/paperclip2.svg'
import Pdf from '@/assets/icons/pdf.svg'
import Files from '@/assets/icons/files.svg'
import CloseUploadedFile from '@/assets/icons/closeUploadedFile.svg'
import EyeFill from '@/assets/icons/eye-fill.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'

export default {
  name: 'IntraocularPressure',
  props: {
    initialForm: Object,
    readonly: Boolean,
    setFormActive: Function,
    onBlurForm: Function,
    filesToUpload: Array,
    onFileChange: Function,
  },
  components: {
    Paperclip,
    Pdf,
    Files,
    CloseUploadedFile,
    EyeFill,
    ChevronDown
  },
  data() {
    return {
      form: {
        fields: {},
        ...this.initialForm
      }
    }
  },
  methods: {
    copyEyeValues() {
      this.form.fields.olhoEsquerdo = this.form.fields.olhoDireito;
      this.onBlurForm(this.form);
    }
  },
  watch: {
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.fields = newValue.fields
        }
      },
      deep: true
    }
  }
}
</script>
