<template>
  <b-modal
    id="medical-report-modal"
    hide-header
    hide-footer
    centered
    size="lg"
    @hidden="closeModal"
  >
    <div class="modal-header">
      <div class="modal-title" v-if="medicalReport && medicalReport.id">
        Editar modelo de laudo personalizado
      </div>
      <div class="modal-title" v-else>Criar modelo de laudo personalizado</div>
      <span class="icon-box">
        <v-close class="icon stroke" @click="closeModal" />
      </span>
    </div>
    <div class="around-content">
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <label for="laudo">Título do laudo</label>
            <input
              type="text"
              id="laudo"
              class="form-control"
              :class="{ error: validate && !laudo }"
              v-model="laudo"
            />
            <div v-if="validate && !laudo" class="custom-invalid-feedback">
              Campo obrigatório
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group>
            <label for="descricao">Texto do laudo</label>
            <v-text-area
              id="descricao"
              :value="descricao"
              :class="{ error: validate && !descricao }"
              @inputTextArea="inputTextArea"
            />
            <div v-if="validate && !descricao" class="custom-invalid-feedback">
              Campo obrigatório
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <div class="wrapper-button">
        <div class="d-flex" v-if="medicalReport && medicalReport.id">
          <b-button
            variant="outline-primary"
            class="mr-2"
            size="lg"
            @click="closeModal"
          >
            Cancelar
          </b-button>
          <b-button variant="primary" size="lg" @click="updateMedicalReport">
            Salvar alterações
          </b-button>
        </div>
        <b-button variant="primary" size="lg" v-else @click="saveMedicalReport">
          Adicionar
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Close from '@/assets/icons/close.svg'
import TextArea from '@/components/General/TextArea'

export default {
  name: 'MedicalReportModal',
  props: {
    report: String,
    medicalReport: Object,
    getMedicalReports: Function,
    reportType: String,
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      laudo: null,
      descricao: null,
      validate: false
    }
  },
  components: {
    'v-close': Close,
    'v-text-area': TextArea
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('medical-report-modal')
      this.$emit('onHide')
    },
    inputTextArea(value) {
      this.descricao = value
    },
    isValidForm() {
      this.validate = true
      return Boolean(this.laudo && this.descricao)
    },
    clearModal() {
      this.laudo = null
      this.descricao = null
      this.validate = false
    },
    async saveMedicalReport() {
      if (!this.isValidForm()) return
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.storeMedicalReport({
          clinicId: this.clinic.id,
          type: this.reportType,
          name: this.laudo,
          template: this.descricao,
        })
        this.$toast.success('Modelo de laudo salvo com sucesso!')
        await this.getMedicalReports()
        this.$emit('setMedicalReport', response.data)
        this.closeModal()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async updateMedicalReport() {
      if (!this.isValidForm()) return
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.updateMedicalReport(this.medicalReport.id, {
          name: this.laudo,
          template: this.descricao
        })
        this.$toast.success('Alterações no laudo salvas com sucesso!')
        this.getMedicalReports()
        this.$emit('setMedicalReport', response.data)
        this.closeModal()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    setDefaultFields(){
      this.laudo = null
      this.descricao = null
    }
  },
  watch: {
    report(value) {
      if (value) {
        this.laudo = value
      }
    },
    medicalReport: {
      handler: function (value) {
        if (value && value.id) {
          this.laudo = this.medicalReport.name
          this.descricao = this.medicalReport.template
        } else {
          this.setDefaultFields()
        }
      },
      deep: true,
      immediate: true,
    }
  }
}
</script>

<style lang="scss">
#medical-report-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }
  }

  .around-content {
    padding: 24px 24px 0 24px;
    textarea {
      min-height: 200px;
      max-height: 1000px;
    }
  }

  .wrapper-button {
    width: 100%;
    display: flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>
