<template>
  <b-row :ref="form.id">
    <b-col>
      <validation-observer ref="medicalReportForm" v-slot="{ invalid }" v-if="!readonly">
        <b-row>
          <b-col cols="12">
            <div class="position-relative">
              <validation-provider name="medicamento" :rules="{ required: true }" v-slot="{ errors, touched }">
                <multiselect 
                  @click.native="showAddCustom = true" 
                  v-model="form.fields.laudo" 
                  :options="laudosOptions" 
                  :option-height="40" 
                  :showLabels="false" 
                  :searchable="true" 
                  :loading="isLoading" 
                  :internal-search="false" 
                  @search-change="searchMedicalReports" 
                  @select="selectMedicalReports" placeholder="Pesquisar laudo médico" class="search-mode with-border" 
                  :class="{ 'error': touched && errors.length > 0 && 1 === 2 }">
                  
                  <template slot="singleLabel" slot-scope="props">
                    <span class="single_label d-inline-block text-truncate">{{ props.option }}</span>
                  </template>
                  <template slot="laudos" slot-scope="laudo" >
                    <small>{{ laudo }}</small>
                  </template>
                  <template slot="caret">
                    <div class="search">
                      <Search class="search-icon"/>
                    </div>
                  </template>
                  <template slot="noOptions">Digite para pesquisar um laudo médico</template>
                  <template slot="noResult">Nenhum laudo médico encontrado</template>
                </multiselect>
                <div class="new-medical-report" v-if="showAddCustom">
                  <v-icon tooltip="Criar novo modelo de laudo">
                    <Plus class="icon" @click="$bvModal.show('medical-report-modal')" />
                  </v-icon>
                </div>
                <div class="new-medical-report edit" v-else-if="!showAddCustom && Object.keys(selected).length !== 0">
                  <v-icon tooltip="Editar">
                    <Edit class="icon edit mr-2" @click="$bvModal.show('medical-report-modal')"/>
                  </v-icon>
                  <v-icon tooltip="Deletar">
                    <Trash class="icon trash" @click="$bvModal.show('delete-medical-report-modal')" />
                  </v-icon>
                </div>
              </validation-provider>
            </div>
          </b-col>
          </b-row>
            <b-row>
              <b-col cols="12">
                <div class="form-group">
                  <validation-provider name="´laudo" :rules="{ required: true }" v-slot="{ errors, touched }">
                    <VueEditor v-if="vueEditor" class="text-area"/>
                    <v-text-area
                      v-else
                      id="descricao"
                      :value="form.fields.descricao"
                      :errors="errors"
                      :touched="touched"
                      @inputTextArea="inputTextArea"
                    />
                  </validation-provider>
                </div>
              </b-col>
            </b-row>
          <b-row class="justify-content-end">
            <b-col cols="2">
              <b-button variant="primary" block class="mt-27 h-56" :disabled="invalid" @click="addMedicalReport">Adicionar</b-button>
            </b-col>
          </b-row>
      </validation-observer>
      <b-row>
        <b-col>
          <table class="table-eyecare">
            <thead>
              <tr>
                <th width="80%">Nome</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, index) of form.value" :key="index">
                <td >{{ value.laudo }}</td>
                <td class="text-right">
                  <div class="more-with-menu">
                    <MoreVertical class="more-icon" />
                    <div class="menu">
                      <b-button variant="link" @click="editItem(value, index)">Editar</b-button>
                      <b-button variant="link remove mb-0" @click="removeItem(index)">Remover</b-button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-col>

    <v-medical-report-modal
      :report="temporaryReport"
      :getMedicalReports="getMedicalReports"
      :medicalReport="selected"
      @setMedicalReport="setMedicalReport"
    />
    <v-delete-medical-report-modal :name="form.fields.laudo" :confirmDelete="confirmDelete" />
  </b-row>
</template>

<script>
import Search from '@/assets/icons/search.svg'
import Plus from '@/assets/icons/plus.svg'
import Edit from '@/assets/icons/edit.svg'
import Trash from '@/assets/icons/trash.svg'
import MoreVertical from '@/assets/icons/more-vertical.svg'
import TextArea from '@/components/General/TextArea'
import MedicalReportModal from './MedicalReport/MedicalReportModal.vue'
import DeleteMedicalReportModal from './MedicalReport/DeleteMedicalReportModal.vue'
import Icon from '@/components/General/Icon'
import { VueEditor } from "vue2-editor"

export default {
  components: {
    Search,
    Plus,
    MoreVertical,
    'v-text-area': TextArea,
    'v-medical-report-modal': MedicalReportModal,
    'v-delete-medical-report-modal': DeleteMedicalReportModal,
    'v-icon': Icon,
    Edit,
    Trash,
    VueEditor,
  },
  props: {
    form: Object,
    attendanceReview: Object,
    readonly: Boolean,
    setFormActive: Function,
    onBlurForm: Function,
    vueEditor: Boolean,
  },
  data () {
    return {
      multiple: true,
      touched: false,
      errors: [],
      isLoading: false,
      laudosOptions: [],
      laudosMedicos: [],
      showAddCustom: true,
      temporaryReport: '',
      selected: {}
    }
  },
  async mounted() {
    if (!this.readonly) {
      this.$el.addEventListener('click', this.onClick);
      this.onChange()
      await this.getMedicalReports()
    }
  },
  watch: {
    'form.fields'() {
      this.onChange()
    }
  },
  methods: {
    onClick(event) {
      const element = event.target
      if (element.tagName !== 'TEXTAREA' && element.tagName !== 'BUTTON') {
        this.showAddCustom = true
      } else {
        this.showAddCustom = false
      }
    },
    onChange() {
      this.attendanceReview.laudo = this.form.value.length > 0
    },
    editItem(value, index) {
      if (value.professional_id) {
        this.showAddCustom = false
        this.selected = value
      }
      this.form.fields = { ...value }
      this.form.editingIndex = index
    },
    removeItem(index) {
      this.form.value.splice(index, 1)
      this.saveForm(this.form)
    },
    addMedicalReport() {
      this.showAddCustom = true
      this.selected = {}
      if (this.form.editingIndex !== null) {
        this.form.value.splice(this.form.editingIndex, 1)
      }
      this.form.value.push(this.form.fields)
      this.clearFields()
      this.form.editingIndex = null
      this.saveForm(this.form);
    },
    async confirmDelete(confirm) {
      this.$bvModal.hide('delete-medical-report-modal')
      if (confirm) await this.deleteMedicalReport()
    },
    async deleteMedicalReport() {
      const isLoading = this.$loading.show()
      try {
        await this.api.deleteMedicalReport(this.selected.id)
        await this.getMedicalReports()
        this.selected = {}
        this.clearFields()
        this.$toast.success('Modelo de laudo excluído com sucesso!')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    searchMedicalReports(query) {
      if (query) {
        this.selected = {}
        this.temporaryReport = query
        this.showAddCustom = true
      }
      this.laudosOptions = []
      this.laudosMedicos.map((laudo) => {
      if(query.length < 3 || laudo.name.toLowerCase().includes(query.toLowerCase()))
        this.laudosOptions.push(laudo.name)
      })
    },
    async selectMedicalReports(query) {
      await this.laudosMedicos.find((laudoMedico) => {
        if (laudoMedico.name === query) {
          this.form.fields.descricao = laudoMedico.template
          this.form.fields.professional_id = laudoMedico.professional_id
          if (laudoMedico.professional_id) {
            this.showAddCustom = false
            this.selected = laudoMedico
          } else {
            this.showAddCustom = true
            this.selected = {}
          }
        }
      })
    },
    async getMedicalReports () {
      this.laudosMedicos = []
      const clinic = JSON.parse(localStorage.getItem('clinic'))
      const type = ['attendance', 'exam']
      try {
        const response = await this.api.getMedicalReports(clinic.id, type)
        response.data.map(laudoMedico => {
          this.laudosMedicos.push(laudoMedico)
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    setMedicalReport(medicalReport) {
      this.form.fields.laudo = medicalReport.name
      this.form.fields.descricao = medicalReport.template
      this.form.fields.professional_id = medicalReport.professional_id
      this.showAddCustom = false
      this.selected = medicalReport
    },
    hideForm(form) {
      form.showing = false;
      this.saveForm(form, form.showing);
    },
    saveForm(form, showing) {
      this.onChange()
      this.$emit('saveForm', form, showing)
    },
    inputTextArea(value) {
      this.form.fields.descricao = value
    },
    clearFields() {
      this.form.fields = {
        laudo: null,
        descricao: null,
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  textarea {
    min-height: 200px;
    max-height: 1000px;
  }

  .search {
    width: 5% !important;
    div {
      cursor: pointer;
    }
  }

  .single_label {
    max-width: 85%;
  }

  .new-medical-report {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    top: 0;
    right: 10px;
    z-index: 10;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 1%;
      fill: var(--blue-500);
      cursor: pointer;

      &.trash {
        fill: none;
        stroke: var(--states-red-soft);
      }

      &.edit {
        fill: none;
        stroke: var(--blue-500);
      }
    }
  }
</style>
