<template>
<div id="attendance-form">
   <div class="form-component" :class="{'closed': !this.form.opened, 'active': this.form.active}" v-if="this.form.showing || (this.form.id === 'cid')">
    <div class="form-title-container d-flex justify-content-between align-items-center">
      <div class="title-container" >
        <p class="form-title" v-if="typeof form.editing === 'undefined' || (typeof form.editing !== 'undefined' && form.editing === false)" :class="{'editable': typeof form.editing !== 'undefined'}" @click="(form.name === 'Exame personalizado') ? editForm(form) : null ">{{ form.name }}</p>
        <input autocomplete="off" type="text" class="form-title-input" v-else v-model="form.name" @blur="editForm(form)" :style="{ width: form.name.length + 'ch' }">
      </div>
      <div class="d-flex flex-row">
        <p class="remove-form" @click="hideForm(form)" v-if="this.form.id !== 'cid'">remover</p>
        <ChevronUp class="chevron" @click="form.opened = !form.opened" />
      </div>
    </div>

    <div class="fields-area">
      <v-form-chief-compliant
        v-if="this.form.name === 'Queixa principal'"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
      />

      <v-current-illness
        v-else-if="this.form.name === 'História da doença atual'"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
      />

      <v-form-ophtalmological-background
        v-else-if="this.form.name === 'Antecedentes oftalmológicos'"
        :initialForm="form"
        :readonly="readonly"
        @saveForm="saveForm"
        :antecedentesOftalmologicos="antecedentesOftalmologicos"
      />

      <v-form-personal-background
        v-else-if="this.form.name === 'Antecedentes pessoais'"
        :initialForm="form"
        :readonly="readonly"
        @saveForm="saveForm"
        :antecedentesPessoais="antecedentesPessoais"
      />

      <v-form-family-background
        v-else-if="this.form.name === 'Antecedentes familiares'"
        :initialForm="form"
        :readonly="readonly"
        @saveForm="saveForm"
        :antecedentesOftalmologicos="antecedentesOftalmologicos"
      />

      <v-form-previous-eye-surgeries
        v-else-if="this.form.name === 'Cirurgias oftalmológicas anteriores'"
        :initialForm="form"
        :readonly="readonly"
        @saveForm="saveForm"
        :cirurgiasOftalmologicas="cirurgias"
      />

      <v-form-life-habits
        v-else-if="this.form.name === 'Hábitos de vida'"
        :initialForm="form"
        @saveForm="saveForm"
        :readonly="readonly"
      />

      <v-form-medicines-use
        v-else-if="this.form.name === 'Medicamentos oftalmológicos em uso'"
        :initialForm="form"
        @saveForm="saveForm"
        :readonly="readonly"
      />

      <v-form-medicines-use
        v-else-if="this.form.name === 'Outros medicamentos em uso'"
        :initialForm="form"
        @saveForm="saveForm"
        :readonly="readonly"
      />

      <v-form-allergies
        v-else-if="this.form.name === 'Alergias'"
        :initialForm="form"
        :readonly="readonly"
        @saveForm="saveForm"
      />

      <v-form-additional-anamnese-information
        v-else-if="this.form.name === 'Informações adicionais anamnese'"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
      />

      <v-form-ectoscopy
        v-else-if="this.form.name === 'Ectoscopia'"
        :initialForm="form"
        :readonly="readonly"
        :filesToUpload="filesToUpload"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :onFileChange="onFileChange"
        :removeUploadedFile="removeUploadedFile"
      />

      <v-form-avsc
        v-else-if="this.form.name === 'AVSC - Acuidade visual sem correção'"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
      />

      <v-form-avcc
        v-else-if="this.form.name === 'AVCC - Acuidade visual com correção'"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
      />

      <MultipleEyesContainer
        v-else-if="this.form.name === 'Refração dinâmica' "
          :initialForm="this.form"
          :readonly="readonly"
          :setFormActive="setFormActive"
          :onBlurForm="onBlurForm"
          :preConsultation="preConsultation"
          @saveForm="saveForm"
      />

      <MultipleEyesContainer
        v-else-if="this.form.name === 'Refração estática' "
          :initialForm="this.form"
          :readonly="readonly"
          :setFormActive="setFormActive"
          :onBlurForm="onBlurForm"
          :preConsultation="preConsultation"
          @saveForm="saveForm"
      />

      <v-form-avsc
        v-else-if="this.form.name === 'Acuidade Visual para longe após Refração' "
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
      />

      <v-form-avsc
        v-else-if="this.form.name === 'Acuidade Visual para perto após Refração' "
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
      />

      <v-form-biomicroscopy
        v-else-if="this.form.name === 'Biomicroscopia'"
        :initialForm="form"
        :readonly="readonly"
        :onBlurForm="onBlurForm"
        :setFormActive="setFormActive"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
        @saveForm="saveForm"
      />

      <v-form-intraocular-pressure
        v-else-if="this.form.name === 'Pressão intraocular'"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :filesToUpload="filesToUpload"
        :onFileChange="onFileChange"
      />

      <v-form-eye-container
        v-else-if="this.form.name === 'Gonioscopia'"
        :type="this.form.name"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
      />

      <v-form-eye-container
        v-else-if="this.form.name === 'Esquiascopia'"
        :type="this.form.name"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
      />

      <v-form-retina-mapping
        v-else-if="this.form.id === 'mapeamento-de-retina'"
        :initialForm="form"
        :readonly="readonly"
        :onBlurForm="onBlurForm"
        :setFormActive="setFormActive"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
        :attendanceReview="attendanceReview"
        @saveForm="saveForm"
      />

      <v-form-eye-container
        v-else-if="this.form.name === 'Motilidade ocular extrínseca'"
        :type="this.form.name"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
      />

      <v-form-cover-test
        v-if="this.form.name === 'Cover Test'"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
      />

      <v-form-reflexes
        v-else-if="this.form.name === 'Reflexos'"
        :initialForm="form"
        :readonly="readonly"
        :onBlurForm="onBlurForm"
        :setFormActive="setFormActive"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
      />

      <v-form-additional-information
        v-else-if="this.form.name === 'Informações adicionais'"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
      />

      <v-form-eye-container
        v-else-if="this.form.name === 'Biometria'"
        :type="this.form.name"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
      />

      <v-form-eye-container
        v-else-if="this.form.name === 'Retinografia'"
        :type="this.form.name"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
      />

      <v-form-eye-container
        v-else-if="this.form.name === 'Paquimetria'"
        :type="this.form.name"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
      />

      <v-form-visual-field
        v-else-if="this.form.name === 'Campo visual'"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
      />

      <v-form-eye-container
        v-else-if="this.form.name === 'OCT (Tomografia de Coerência Óptica) de Mácula'"
        type="OCTM"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
      />

      <v-form-eye-container
        v-else-if="this.form.name === 'OCT (Tomografia de Coerência Óptica) de Nervo Óptico'"
        type="OCTN"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
      />

      <v-form-eye-container
        v-else-if="this.form.name === 'OCT (Tomografia de Coerência Óptica) de Segmento Anterior'"
        type="OCTS"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
      />

      <v-form-eye-container
        v-else-if="this.form.name === 'OCT-A (Angiotomografia de Coerência Óptica)'"
        type="OCTA"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
      />

      <v-form-eye-container
        v-else-if="this.form.name === 'Topografia'"
        :type="this.form.name"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
      />

      <v-form-eye-container
        v-else-if="this.form.name === 'Tomografia de Córnea (Pentacam)'"
        type="tomografia de cornea"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
      />

      <v-form-eye-container
        v-else-if="this.form.name === 'Tomografia de Córnea (Galilei)'"
        type="tomografia de cornea"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
      />

      <v-form-eye-container
        v-else-if="this.form.name === 'Microscopia Especular de Córnea'"
        type="Microscopia especular de cornea"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
      />

      <v-form-eye-container
        v-else-if="this.form.name === 'Indocianinografia (ICG)'"
        type="Indocianinografia"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
      />

      <v-form-eye-container
        v-else-if="this.form.name === 'Angiofluoresceinografia (AFG)'"
        type="Angiofluoresceinografia"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
      />

      <v-form-eye-container
        v-else-if="this.form.name === 'USG Ocular'"
        :type="this.form.name"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
      />

      <v-form-eye-container
        v-else-if="this.form.name === 'Eletrorretinograma (ERG) de Campo Total'"
        type="Eletrorretinograma de campo total"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
      />

      <v-form-eye-container
        v-else-if="this.form.name === 'ERG Multifocal'"
        :type="this.form.name"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
      />

      <v-form-eye-container
        v-else-if="this.form.name === 'Potencial Visual Evocado (PVE)'"
        type="Potencial Visual Evocado"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
      />

      <v-form-eye-container
        v-else-if="this.form.name === 'PVE varredura'"
        :type="this.form.name"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
      />
      
      <v-form-eye-container
        v-else-if="this.form.name === 'Potencial de Acuidade Macular (PAM)'"
        :type="this.form.name"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :onFileChange="onFileChange"
        :filesToUpload="filesToUpload"
      />

      <v-form-surgery-data
        v-else-if="this.form.id === 'dados-da-cirurgia'"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :cirurgiasOftalmologicas="cirurgias"
        @saveForm="saveForm"
      />

      <v-form-anesthesia
        v-else-if="this.form.id === 'anestesia'"
        :initialForm="form"
        :attendanceReview="attendanceReview"
        :readonly="readonly"
        :setFormActive="setFormActive"
        @saveForm="saveForm"
      />

      <v-form-products-used
        v-else-if="this.form.id === 'produtos-utilizados'"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        @saveForm="saveForm"
      />

      <v-form-patology-to-be-treated
        v-else-if="this.form.id == 'patologia-a-ser-tratada'"
        :initialForm="form"
        :readonly="readonly"
        :antecedentesOftalmologicos="antecedentesOftalmologicos"
        :setFormActive="setFormActive"
        @saveForm="saveForm"
      />

      <v-form-surgical-note
        v-else-if="this.form.name === 'Anotação cirúrgica'"
        :initialForm="form"
        :readonly="readonly"
        @saveForm="saveForm"
        :setFormActive="setFormActive"
      />

      <v-form-intercurrencies
        v-else-if="this.form.id === 'intercorrencias'"
        :initialForm="form"
        :readonly="readonly"
        @saveForm="saveForm"
        :setFormActive="setFormActive"
      />

      <v-form-medical-report
        v-else-if="this.form.id === 'laudo-medico'"
        :form="form"
        :attendanceReview="attendanceReview"
        :readonly="readonly"
        :setFormActive="setFormActive"
        @saveForm="saveForm"
        :onBlurForm="onBlurForm"
      />

      <v-form-custom-exam
        v-else-if="this.form.id.indexOf('exame-personalizado-') > -1"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :filesToUpload="filesToUpload"
        :onFileChange="onFileChange"
      />

      <v-form-diagnostic-hypothesis
        v-else-if="this.form.name === 'Hipótese diagnóstica'"
        :initialForm="form"
        :readonly="readonly"
        @saveForm="saveForm"
      />

      <v-form-cid
        v-else-if="this.form.name === 'CID'"
        :initialForm="form"
        :attendanceReview="attendanceReview"
        :readonly="readonly"
        :isLoading="isLoading"
        :setFormActive="setFormActive"
        @saveForm="saveForm"
      />

      <v-form-exam-request
        v-else-if="this.form.name === 'Solicitação de exames'"
        :initialForm="form"
        :attendanceReview="attendanceReview"
        :readonly="readonly"
        @saveForm="saveForm"
        :examesOftalmologicos="examesOftalmologicos"
      />

      <v-form-apa
        v-else-if="this.form.name === 'APA (Avaliação Pré Anestésica)'"
        :initialForm="form"
        :attendanceReview="attendanceReview"
        :readonly="readonly"
        @saveForm="saveForm"
      />

      <v-surgery-request
        v-else-if="this.form.name === 'Solicitação de cirurgia'"
        :initialForm="form"
        :attendanceReview="attendanceReview"
        :readonly="readonly"
        @saveForm="saveForm"
        :cirurgiasOftalmologicas="cirurgias"
      />

      <v-form-forwarding
        v-else-if="this.form.name === 'Encaminhamento'"
        :initialForm="form"
        :attendanceReview="attendanceReview"
        :readonly="readonly"
        @saveForm="saveForm"
        :encaminhamento="encaminhamento"
      />

      <v-form-medical-prescription
        v-else-if="this.form.name === 'Prescrição Medicamentosa'"
        :initialForm="form"
        :readonly="readonly"
        @saveForm="saveForm"
        :attendanceReview="attendanceReview"
        :medicines="medicines"
        :medicinesAntimicrobianos="medicinesAntimicrobianos"
        :formsMedicalPrescription="formsMedicalPrescription"
      />

      <v-form-glasses
        v-else-if="this.form.id === 'oculos' "
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :attendanceReview="attendanceReview"
        :preConsultation="preConsultation"
      />

      <v-form-contact-lens
        v-else-if="this.form.id === 'lentes-de-contato' "
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
        :attendanceReview="attendanceReview"
      />

      <v-form-post-operative-orientation
        v-else-if="this.form.id === 'orientacao-pos-operatoria'"
        :initialForm="form"
        :attendanceReview="attendanceReview"
        :readonly="readonly"
        :setFormActive="setFormActive"
        @saveForm="saveForm"
        :onBlurForm="onBlurForm"
        :goTo="goTo"
      />

      <v-form-more-information
        v-else-if="this.form.name === 'Mais Informações'"
        :initialForm="form"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
      />

      <v-form-return
        v-else-if="this.form.name === 'Retorno'"
        :initialForm="form"
        :attendanceReview="attendanceReview"
        :readonly="readonly"
        :setFormActive="setFormActive"
        :onBlurForm="onBlurForm"
      />

      <v-form-medical-certificates
        v-else-if="this.form.name === 'Atestados Médicos'"
        :initialForm="form"
        :readonly="readonly"
        :cidOptions="cidOptions"
        :touched="touched"
        :setFormActive="setFormActive"
        :attendanceReview="attendanceReview"
        :onBlurForm='onBlurForm'
      />
    </div>
  </div>
</div>
</template>

<script>
import ChevronUp from '@/assets/icons/chevron-up.svg'
import MedicalPrescription from '@/components/Appointment/MedicalPrescription.vue'
import Cid from '@/components/Appointment/Cid.vue'
import CurrentIllness from '@/components/Appointment/CurrentIllness.vue'
import OphthalmologicalBackground from '@/components/Appointment/OphthalmologicalBackground.vue'
import PersonalBackground from '@/components/Appointment/PersonalBackground.vue'
import FamilyBackground from '@/components/Appointment/FamilyBackground.vue'
import PreviousEyeSurgeries from '@/components/Appointment/PreviousEyeSurgeries.vue'
import LifeHabits from '@/components/Appointment/LifeHabits.vue'
import MedicinesInUse from '@/components/Appointment/MedicinesInUse.vue'
import Allergies from '@/components/Appointment/Allergies.vue'
import AdditionalAnamneseInformation from '@/components/Appointment/AdditionalAnamneseInformation.vue'
import Ectoscopy from '@/components/Appointment/Ectoscopy.vue'
import Avsc from '@/components/Appointment/Avsc.vue'
import Avcc from '@/components/Appointment/Avcc.vue'
import Biomicroscopy from '@/components/Appointment/Biomicroscopy.vue'
import IntraocularPressure from '@/components/Appointment/IntraocularPressure.vue'
import EyeContainer from '@/components/Appointment/EyeContainer.vue'
import RetinaMapping from '@/components/Appointment/RetinaMapping.vue'
import Reflexes from '@/components/Appointment/Reflexes.vue'
import CoverTest from '@/components/Appointment/CoverTest.vue'
import AdditionalInformation from '@/components/Appointment/AdditionalInformation.vue'
import VisualField from '@/components/Appointment/VisualField.vue'
import DiagnosticHypothesis from '@/components/Appointment/DiagnosticHypothesis.vue'
import ExamRequest from '@/components/Appointment/ExamRequest.vue'
import Apa from '@/components/Appointment/Apa.vue'
import SurgeryRequest from '@/components/Appointment/SurgeryRequest.vue'
import Forwarding from '@/components/Appointment/Forwarding.vue'
import MedicalCertificates from '@/components/Appointment/MedicalCertificates.vue'
import MedicalReport from '@/components/Appointment/MedicalReport.vue'
import CustomExam from '@/components/Appointment/CustomExam.vue'
import ContactLens from '@/components/Appointment/ContactLens.vue'
import MultipleEyesContainer from '@/components/Appointment/MultipleEyesContainer.vue'
import SurgeryData from '@/components/Appointment/SurgeryData.vue'
import Anesthesia from '@/components/Appointment/Anesthesia.vue'
import ProductsUsed from '@/components/Appointment/ProductsUsed.vue'
import Intercurrencies from '@/components/Appointment/Intercurrencies.vue'
import PostOperativeGuideline from '@/components/Appointment/PostOperativeGuideline.vue'
import SurgicalNote from '@/components/Appointment/SurgicalNote.vue'
import PathologyToBeTreated from '@/components/Appointment/PathologyToBeTreated.vue'
import ChiefCompliant from '@/components/Appointment/ChiefCompliant.vue'
import MoreInformation from '@/components/Appointment/MoreInformation.vue'
import Return from '@/components/Appointment/Return.vue'
import Glasses from '@/components/Appointment/Glasses.vue'

export default {
  name: 'FormComponent',
  props: {
    form: Object,
    readonly: Boolean,
    setFormActive: Function,
    onBlurForm: Function,
    editForm: Function,
    cids: Array,
    showSuccessMessage: Function,
    preConsultation: Object,
    attendanceReview: Object,
    medicines: Object,
    medicinesAntimicrobianos: Object,
    formsMedicalPrescription: Array,
    cidOptions: Array,
    saveForms: Function,
    goTo: Function,
  },
  components: {
    ChevronUp,
    'v-form-medical-prescription': MedicalPrescription,
    'v-form-cid': Cid,
    'v-current-illness': CurrentIllness,
    'v-form-ophtalmological-background': OphthalmologicalBackground,
    'v-form-personal-background': PersonalBackground,
    'v-form-family-background': FamilyBackground,
    'v-form-previous-eye-surgeries': PreviousEyeSurgeries,
    'v-form-life-habits': LifeHabits,
    'v-form-medicines-use': MedicinesInUse,
    'v-form-allergies': Allergies,
    'v-form-additional-anamnese-information': AdditionalAnamneseInformation,
    'v-form-ectoscopy': Ectoscopy,
    'v-form-avsc': Avsc,
    'v-form-avcc': Avcc,
    'v-form-biomicroscopy': Biomicroscopy,
    'v-form-intraocular-pressure': IntraocularPressure,
    'v-form-eye-container': EyeContainer,
    'v-form-retina-mapping': RetinaMapping,
    'v-form-reflexes': Reflexes,
    'v-form-cover-test': CoverTest,
    'v-form-additional-information': AdditionalInformation,
    'v-form-visual-field': VisualField,
    'v-form-diagnostic-hypothesis': DiagnosticHypothesis,
    'v-form-exam-request': ExamRequest,
    'v-form-apa': Apa,
    'v-surgery-request': SurgeryRequest,
    'v-form-forwarding': Forwarding,
    'v-form-medical-certificates': MedicalCertificates,
    'v-form-medical-report': MedicalReport,
    'v-form-custom-exam': CustomExam,
    'v-form-surgery-data': SurgeryData,
    'v-form-anesthesia': Anesthesia,
    'v-form-products-used': ProductsUsed,
    'v-form-intercurrencies': Intercurrencies,
    MultipleEyesContainer,
    'v-form-contact-lens': ContactLens,
    'v-form-post-operative-orientation': PostOperativeGuideline,
    'v-form-surgical-note': SurgicalNote,
    'v-form-patology-to-be-treated': PathologyToBeTreated,
    'v-form-chief-compliant': ChiefCompliant,
    'v-form-more-information': MoreInformation,
    'v-form-return': Return,
    'v-form-glasses': Glasses,
  },
  async mounted () {
    if((this.form.name === 'Antecedentes oftalmológicos' || this.form.name === 'Antecedentes familiares' || this.form.id === 'patologia-a-ser-tratada') && this.antecedentesOftalmologicos.length === 0){
      try {
        const responseAntecedentesOftalmologicos = await this.api.getAntecedentes()
         responseAntecedentesOftalmologicos.data.forEach(antecedente => {
          this.antecedentesOftalmologicos.push(antecedente.value)
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    }

    if(this.form.name === 'Encaminhamento' && this.encaminhamento.length === 0){
      try {
        const responseEncaminhamento = await this.api.getEncaminhamento()
         responseEncaminhamento.data.forEach(encaminhamentos => {
          this.encaminhamento.push(encaminhamentos.value)
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    }

    if(this.form.name === 'Solicitação de exames' && this.encaminhamento.length === 0){
      try {
        const responseExames = await this.api.getExamesOftalmologicos()
        responseExames.data.forEach(exames => {
          this.examesOftalmologicos.push(exames.value)
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    }

    if(this.form.name === 'Antecedentes pessoais' && this.encaminhamento.length === 0){
      try {
        const responseAntecedentes = await this.api.getAntecedentesPessoais()
        responseAntecedentes.data.forEach(antecedentes => {
          this.antecedentesPessoais.push(antecedentes.value)
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    }

    if((this.form.name === 'Cirurgias oftalmológicas anteriores' || this.form.name === 'Solicitação de cirurgia' || this.form.name === 'Dados da cirurgia') && this.encaminhamento.length === 0){
      this.cirurgias = this.cirurgiasOftalmologicas
    }
  },
  data () {
    return {
      touched: false,
      isLoading: false,
      filesToUpload: [],
      antecedentesOftalmologicos: [],
      antecedentesPessoais: [],
      cirurgias: [],
      encaminhamento: [],
      examesOftalmologicos: [],
    }
  },
  inject: ['cirurgiasOftalmologicas'],
  methods: {
    async hideForm(form) {
      this.$emit('restoreAttendanceReview', form)
      form.showing = false
      form.active = false
      form.opened = false
      this.saveForm(form, false)
      if (!this.readonly) {
        this.saveForms(form)
      }
    },
    async onFileChange(event, fileName) {
      const clinic = JSON.parse(localStorage.getItem('clinic'))
      var files = event.target.files || event.dataTransfer.files;

      if (!files.length)
        return;

      for(var f of files){
        f.group = fileName

        const dataForm = new FormData();
        dataForm.append(`file`, f);
        dataForm.append(`fileName`, fileName)

        try {
          const response = await this.api.uploadDocs(clinic.id, this.$route.params.id, 'exam', dataForm)
          f.sendStatus = true
          f.storedFileName = response.data
          this.filesToUpload.push(f)
          this.showSuccessMessage('Arquivo enviado com sucesso');
        } catch (error) {
          this.$toast.error('Houve um erro ao enviar o arquivo')
        }
      }
    },
    async removeUploadedFile(fileIndex, fileName){
      const attendanceFileId = this.filesToUpload[fileIndex].storedFileName.id;
      try {
        await this.pai.deleteDoc(attendanceFileId)
        this.filesToUpload.splice(fileIndex, 1)
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    selectedProtocols(value) {
      this.$emit('selected-protocols', value)
    },
    saveForm(form, showing) {
      this.$emit('saveForm', form, showing)
    },
  }
}
</script>

<style lang="scss">
#attendance-form {
  .form-component {
    padding: 0px 16px 24px 16px;
    margin: 8px 0;
    border-radius: 8px;
    transition: all 0.5s;

    input:disabled {
      background-color: #e9ecef;
    }

    .exam-request {
      display: flex !important;
      flex-direction: column !important;

      .multiselect {
        border: 1px solid #C6CEEB;
        border-radius: 8px;
      }

      .laterality-container {
        display: flex;
        flex-direction: column;

        p {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
          color: #0C1D59;
        }

        .laterality-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 36px;

          .laterality-input {
            display: flex;
            flex-direction: row;
            gap: 24px;

            .laterality-option {
              display: flex;
              flex-direction: row;
              gap: 12px;
              align-items: center;
              font-family: 'Nunito Sans';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 150%;
              color: var(--type-active);

              input {
                height: 24px;
                width: 24px;
              }
            }
          }

          .btn {
            width: auto !important;
            padding: 14px 16px;
          }
        }
      }
    }

    .blue-underline {
      color: var(--blue-500);
      text-decoration: underline;
      margin: 10px 10px 10px 0;
      font-weight: 700;
    }

    &.active {
      background-color: var(--neutral-100);
    }

    .form-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: var(--dark-blue);
      margin-bottom: 20px;

      &.editable {
        cursor: pointer;
      }
    }

    .show-protocol-button {
      margin-left: 20px;
    }

    .protocol-button {
      color: var(--blue-500);
    }

    .form-title-input {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: var(--dark-blue);
      margin-bottom: 24px;
      border: 0;
      padding: 8px 16px;
      border-radius: 8px;
    }

    .remove-form {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      color: var(--states-error);
      cursor: pointer;
      margin-right: 14px;
    }

    .chevron {
      width: 24px;
      height: 24px;
      stroke: var(--neutral-600);
      cursor: pointer;
      transition: all 0.5s;
    }

    .title-tags {
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: var(--type-active);
      margin-bottom: 8px;
    }

    .tags {
      flex: 1;
      margin-bottom: 16px;

      .tag {
        display: inline-block;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: var(--type-active);
        padding: 8px 16px;
        background-color: var(--neutral-200);
        border-radius: 100px;
        margin: 0 8px 8px 0;
        cursor: pointer;

        &.selected {
          background-color: var(--light-orange-200);
        }
      }

      input {
        min-width: 257px;
        border: 1px solid var(--neutral-300);
        border-radius: 100px;
        padding: 8px 16px;

        &:focus {
          outline: none;
        }
      }

      .input-time {
        min-width: 110px;
      }
    }

    .medicine-status {
      margin-top: 8px;
      margin-right: 10px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: var(--type-placeholder);
      float: right;
      position: relative;

      &.enabled {
        &:before {
          background-color: var(--states-success);
        }
      }

      &.disabled {
        &:before {
          background-color: var(--light-orange-500);
        }
      }

      &:before {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        position: absolute;
        top: 4px;
        left: -16px;
        content: ' ';
      }
    }

    .togle-button {
      float: right;
      position: relative;
      margin-right: 5px;
    }

    .container-radio {
      font-weight: 400;
      color: var(--type-active);
    }

    .tag-predominante {
      display: inline-block;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: var(--type-active);
      padding: 8px 16px;
      background-color: var(--light-orange-200);
      border-radius: 100px;
    }

    .custom-input {
      position: relative;

      button {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 14px;
      }

      .close-svg {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        fill: var(--neutral-500);
      }
    }

    .padding-right {
      padding-right: 30px;
    }

    .fields-area {
      .tag-eye {
        width: 56px;
        height: 32px;
        font-weight: 600;
        font-size: 16px;

        svg {
          width: 16px;
          height: 16px;
        }
      }

      .custom-input-group {
        display: flex;
        flex-direction: column;
        border: 1px solid var(--neutral-300);
        border-radius: 8px;
        margin: 24px 0 16px 0;

        .custom-input {
          flex: 1;
          height: 38px;
          border: 8px;
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid var(--neutral-300);
          justify-content: center;

          .eye-area {
            width: 60px;
            background-color: var(--neutral-100);
            border-right: 1px solid var(--neutral-300);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px 0 0 0;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;

            svg {
              width: 16px;
              height: 16px;
              fill: var(--type-active);
              margin-right: 4px;
            }
          }

          &.no-bb {
            border-bottom: 0;

            .eye-area {
              border-radius: 0 0 0 8px;
            }
          }

          .text-area {
            flex: 1;
            line-height: 55px;
            margin-left: 16px;
            font-weight: 400;
            font-size: 16px;
            color: var(--type-active);

            .form-control {
              border: 0 !important;
              border-radius: 0 !important;

              &.with-bbr {
                border-radius: 0 0 8px 0 !important;
              }

              &.with-btr {
                border-radius: 0 8px 0 0 !important;
              }
            }

            &.type-2 {
              position: relative;
              margin-left: 0;
              text-align: center;

              span {
                width: 100%;
                position: absolute;
                top: -19px;
                left: 0;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
                color: var(--type-active);
              }
            }

            &.with-br {
              border-right: 1px solid var(--neutral-300) !important;
            }

            &.with-brtr {
              border-radius: 0 8px 0 0 !important;
            }

            &.with-brbr {
              border-radius: 0 0 8px 0 !important;
            }
          }
        }
      }

      .autoFillBtn {
        padding-left: 8px;
      }

      .autoFillBtnLabel {
        margin: 0px 0px 10px 10px;
        line-height: 30px;
        font-weight: 600;
        color: var(--dark-blue);
      }

      .input-eye {
        position: relative;
        display: flex;
        flex-direction: row;

        .tag-eye {
          width: 60px;
          height: 38px;
          background-color: var(--neutral-100);
          border: 1px solid var(--neutral-300);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px 0 0 8px;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          position: absolute;
          left: 0;
          top: 0;

          svg {
            width: 16px;
            height: 16px;
            fill: var(--type-active);
            margin-right: 4px;
          }
        }

        .form-control {
          flex: 1;
          padding-left: 75px;
        }
      }

      .h-56 {
        height: 56px;
      }

      .h-48 {
        height: 38px;
      }
      .add-button {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .mt-27 {
        margin-top: 27px;
      }

      .mt-8 {
        margin-top: 8px;
      }

      .mt-29 {
        margin-top: 29px;
      }

      .mr-29 {
        margin-right: 29px;
      }

      .mb-4 {
        margin-bottom: 4px;
      }
    }

    .form-title-container{
      margin-bottom: 24px;

     .title-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        > p {
          margin-bottom: 0 !important;
        }

        .refraction {
          color: var(--blue-500);
          text-decoration: underline;
          margin: 0 0 0px 20px;
          font-weight: 700;
        }

        .disabled {
          color: var(--neutral-200);
        }
      }
    }

    &.closed {
      padding: 0 16px;
      margin-bottom: 24px;

      .chevron {
        transform: rotate(180deg);
      }

      .form-title {
        margin-bottom: 0;
      }

      .fields-area {
        max-height: 0;
        overflow: hidden;
      }
    }
  }
  .multiselect {
    .custom-item {
      color: var(--blue-500);
      display: flex;
      align-items: center;
      font-weight: 600;

      .icon {
        width: 24px;
        height: 24px;
        fill: var(--blue-500);
        margin-right: 12px;
      }
    }
    .multiselect__single {
      @media(min-height: 1600){
        font-size: 1.6vh;
      }
      @media(max-height: 1600){
        font-size: 1vw;
      }
    }
  }

  .add-file{
    margin-top: 10px;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    flex-direction: row;

    .file-input{
      cursor: pointer;
      margin: 0px;
      color: #7F91D2;
    }

    .file-list{
      margin: 0px 10px 0px 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow: auto;

      p{
        font-family: Nunito Sans;
        font-weight: 600px;
        font-size: 15px;
        color: var(--type-active);
      }
      ul{
        padding-left: 10px;
        margin: 0px;
        list-style: none;
        align-items: center;
      }
      li {
        display: inline-flex;
        padding: 3px 10px 3px 10px;
        background: #F4F5FB;
        border-radius: 50px;
        color: var(--type-active);
        font-size: 13px;
        align-items: center;

        .attachment_file_name {
          max-width: 80px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-top: 4px;
        }
      }
      .icon {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
      .icon-close {
        margin-left: 8px;
      }
    }
  }
}
</style>
