<template>
  <b-row>
    <b-col>
      <validation-observer
        :ref="this.initialForm.id + '-form'"
        v-slot="{ invalid }"
        v-if="!readonly"
      >
        <b-row>
          <b-col cols="10">
            <div class="form-group">
              <validation-provider
                name="cid10"
                :rules="{ required: true }"
                v-slot="{ errors, touched }"
              >
                <multiselect
                  v-model="form.fields.cid"
                  id="cid"
                  :options="cid10"
                  :option-height="40"
                  :showLabels="false"
                  :searchable="true"
                  :loading="isLoading"
                  :internal-search="false"
                  @search-change="searchCid10"
                  @select="selectCid10"
                  @focus.native="setFormActive(form.id)"
                  placeholder="Selecionar"
                  class="with-border"
                  :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <v-chevron-down />
                    </div>
                  </template>
                  <template slot="noOptions"
                    >Digite para pesquisar um CID10
                  </template>
                  <template slot="noResult">Nenhum CID10 encontrado</template>
                </multiselect>
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="2">
            <b-button
              variant="primary"
              block
              class="h-38"
              :disabled="invalid"
              @click="saveItem"
            >
              Adicionar
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
      <b-row>
        <b-col>
          <table class="table-eyecare">
            <thead>
              <tr>
                <th>CID adicionados</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, index) of form.value" :key="index">
                <td>
                  {{ value.cid }}
                  <span class="tag-predominante" v-if="value.predominante">
                    predominante
                  </span>
                </td>
                <td class="text-right">
                  <div class="more-with-menu">
                    <v-more-vertical class="more-icon" />
                    <div class="menu">
                      <div
                        class="
                          w-100
                          d-flex
                          align-items-center
                          justify-content-between
                          mb-4
                        "
                      >
                        <span class="mr-29">Predominante</span>
                        <toggle-button
                          v-model="value.predominante"
                          :sync="true"
                          :height="32"
                          :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
                          :disabled="readonly"
                          @change="checkPredominant(index)"
                        />
                      </div>
                      <b-button
                        variant="link remove mb-0 p-0"
                        @click="removeItem(index)"
                        :disabled="readonly"
                      >
                        Remover
                      </b-button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import MoreVertical from '@/assets/icons/more-vertical.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'

export default {
  name: 'Cid',
  props: {
    initialForm: Object,
    attendanceReview: Object,
    readonly: Boolean,
    isLoading: Boolean,
    cidOptions: Array,
    setFormActive: Function
  },
  data() {
    return {
      cid10: [],
      form: {
        multiple: true,
        fields: {},
        value: [],
        editingIndex: null,
        ...this.initialForm
      }
    }
  },
  components: {
    'v-more-vertical': MoreVertical,
    'v-chevron-down': ChevronDown
  },
  methods: {
    async searchCid10(query) {
      if (query.length > 2) {
        this.cid10 = []
        try {
          const response = await this.api.searchCid10({ term: query })
          this.cid10 = response.data.map(cid10 => {
            return `${cid10.code} - ${cid10.description}`
          })
        } catch (error) {
          this.$toast.error(error.message)
        }
      }
    },
    selectCid10() {
      this.cid10 = []
    },
    checkPredominant(index) {
      this.form.value = this.form.value.map((v, i) => {
        if (i === index) {
          v.predominante = true
        } else {
          v.predominante = false
        }

        return v
      })

      this.$emit('saveForm', this.form)
      this.onChange()
    },
    saveItem() {
      if (this.form.editingIndex !== null) {
        this.form.value.splice(this.editingIndex, 1)
      }

      if (this.form.value.length === 0) {
        this.form.fields.predominante = true
      }

      this.form.value.push(this.form.fields)

      this.form.editingIndex = null
      this.$emit('saveForm', this.form)
      this.form.fields = {
        cid: null,
        predominante: false
      }
      this.onChange()
    },
    removeItem(index) {
      if(this.form.value.length === 1){
        this.$toast.warning("Adicione outro CID para remover.");
        return
      }
      this.form.value.splice(index, 1)
      this.$emit('saveForm', this.form)
      this.onChange()
    },
    getCidList() {
      const cids = ['Não informar']
      const cidForm = this.form.value

      cidForm.map(cid => {
        cids.push(cid.cid)
      })
      return cids
    },
    onChange() {
      const cidOptions = this.getCidList()

      if (!cidOptions.includes(this.form.fields.cid)) {
        this.form.fields.cid = null
      }
    }
  },
  watch: {
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.value = newValue.value
        }
      },
      deep: true
    }
  }
}
</script>
