<template>
  <b-row>
    <b-col class="exam-request">
      <validation-observer
        :ref="this.initialForm.id + '-form'"
        v-slot="{ invalid }"
        v-if="!readonly"
      >
        <div class="form-group">
          <validation-provider
            name="patologia"
            rules="required"
            v-slot="{ touched, errors }"
          >
            <multiselect
              v-if="!form.fields.custom"
              id="antecedente-oftalmo"
              v-model="form.fields.patologia"
              :options="antecedentesOftalmologicos"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :allowEmpty="false"
              :showNoResults="true"
              placeholder="Pesquisar patologia"
              class="search-mode with-border"
              :class="{ error: touched && errors.length > 0 && 1 === 2 }"
            >
              <template slot="caret">
                <div class="search">
                  <Search />
                </div>
              </template>
              <template slot="noResult" slot-scope="props">
                <li @click="setCustom(true, props.search)">
                  <div class="multiselect__option custom-item">
                    <Plus class="icon" />
                    Adicionar patologia "{{ props.search }}"
                  </div>
                </li>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
            </multiselect>
            <div class="custom-input" v-else>
              <b-button variant="link" @click="setCustom(false)">
                Selecionar um item da lista
              </b-button>
              <input
                autocomplete="off"
                v-model="form.fields.patologia"
                :readonly="readonly"
                type="text"
                class="form-control"
                placeholder=""
                :class="{ error: touched && errors.length > 0 && 1 === 2 }"
              />
            </div>
          </validation-provider>
        </div>

        <div class="laterality-container">
          <p>Lateralidade</p>
          <div class="laterality-content">
            <div class="laterality-input">
              <div class="laterality-option">
                <input
                  @focus="setFormActive(form.id)"
                  type="radio"
                  name="surgery-data-laterality"
                  value="Olho esquerdo"
                  v-model="form.fields.lateralidadePatologia"
                />Olho esquerdo
              </div>
              <div class="laterality-option">
                <input
                  @focus="setFormActive(form.id)"
                  type="radio"
                  name="surgery-data-laterality"
                  value="Olho direito"
                  v-model="form.fields.lateralidadePatologia"
                />Olho direito
              </div>
              <div class="laterality-option">
                <input
                  @focus="setFormActive(form.id)"
                  type="radio"
                  name="surgery-data-laterality"
                  value="Ambos os olhos"
                  v-model="form.fields.lateralidadePatologia"
                />Ambos os olhos
              </div>
            </div>

            <b-button
              variant="primary"
              block
              class="h-38 add-button"
              :disabled="invalid"
              @click="addItem"
              >Adicionar
            </b-button>
          </div>
        </div>
      </validation-observer>

      <b-row>
        <b-col>
          <table class="table-eyecare with-laterality">
            <thead>
              <tr>
                <th>Patologia</th>
                <th>Lateralidade</th>
                <th width="40%">Comentário</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, index) of form.value" :key="index">
                <td>{{ value.patologia }}</td>
                <td>{{ value.lateralidadePatologia }}</td>
                <td>
                  <div v-if="value.editing !== true">
                    <b-button
                      variant="link"
                      v-if="value.comentario === null"
                      class="p-0"
                      @click="addComment(index)"
                    >
                      Adicionar comentário
                    </b-button>
                    {{ value.comentario }}
                  </div>

                  <div class="text-right" v-else>
                    <textarea
                      v-model="value.comentario"
                      rows="3"
                      class="form-control"
                    ></textarea>
                    <b-button
                      variant="primary"
                      class="mt-8"
                      @click="saveItem(index)"
                    >
                      Salvar comentário
                    </b-button>
                  </div>
                </td>

                <td class="text-right">
                  <div class="more-with-menu">
                    <MoreVertical class="more-icon" />
                    <div class="menu">
                      <b-button
                        variant="link"
                        @click="editItem(value, index)"
                        :disabled="readonly"
                      >
                        Editar
                      </b-button>
                      <b-button
                        variant="link remove mb-0"
                        @click="removeItem(index)"
                        :disabled="readonly"
                      >
                        Remover
                      </b-button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import Plus from '@/assets/icons/plus.svg'
import MoreVertical from '@/assets/icons/more-vertical.svg'
import Search from '@/assets/icons/search.svg'

export default {
  name: 'PatologyToBeTreated',
  components: {
    Plus,
    MoreVertical,
    Search
  },
  props: {
    initialForm: Object,
    readonly: Boolean,
    antecedentesOftalmologicos: Array,
    setFormActive: Function
  },
  data() {
    return {
      form: {
        fields: {},
        value: [],
        editingIndex: null,
        ...this.initialForm
      }
    }
  },
  methods: {
    setCustom(value, searchTerm) {
      this.form.fields.custom = value
      this.form.fields.patologia = value ? searchTerm : null
    },
    addItem() {
      if (this.form.editingIndex !== null) {
        this.form.value.splice(this.form.editingIndex, 1)
      }

      this.form.value.push(this.form.fields)

      this.form.fields = {
        custom: false,
        patologia: null,
        lateralidadePatologia: 'Ambos os olhos',
        comentario: null
      }
      this.form.editingIndex = null
      this.$emit('saveForm', this.form)
    },
    editItem(value, index) {
      this.form.fields = { ...value }
      this.form.editingIndex = index
    },
    addComment(index) {
      this.form.value = this.form.value.map((opt, i) => {
        if (i === index) {
          opt.editing = true
        }
        return opt
      })
    },
    saveItem(index) {
      this.form.value = this.form.value.map((opt, i) => {
        if (i === index) {
          delete opt.editing
        }
        return opt
      })
      this.$emit('saveForm', this.form)
    },
    removeItem(index) {
      this.form.value.splice(index, 1)
      this.$emit('saveForm', this.form)
    }
  },
  watch: {
    initialForm: {
      handler(newValue) {
        if (newValue.id === this.form.id) {
          this.form.value = newValue.value
        }
      },
      deep: true
    }
  }
}
</script>
